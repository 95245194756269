import {TInstrumentManagerActions, TInstrumentManagerState} from "../models";
import {EInstrumentManagerActions} from "../enums";

const initialAppState: TInstrumentManagerState = {
    instrumentManagerIsOpen: false
}

export const instrumentManagerReducer = (state = initialAppState, action: TInstrumentManagerActions): TInstrumentManagerState => {
    switch (action.type) {
        case EInstrumentManagerActions.OPEN_CLOSE_INSTRUMENT_MANAGER:
            return {
                ...state,
                instrumentManagerIsOpen: !state.instrumentManagerIsOpen
            }
        default:
            return state;
    }
}
