// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-table-claster-candle {
  width: 200px;
  height: 200px;
}
.block-table-claster-candle .claster {
  height: 24px;
}
.block-table-claster-candle .counter {
  font-weight: 500;
  fill: white;
  font-size: 14px;
  transform: none;
}
.block-table-claster-candle .counter.ask {
  fill: #F10D0C;
}
.block-table-claster-candle .counter.ask.colorize {
  fill: white;
}
.block-table-claster-candle .counter.bid {
  text-anchor: end;
  fill: #39AB1D;
}
.block-table-claster-candle .counter.bid.colorize {
  fill: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/ClusterCharts/TableBlockCluster/TableBlockCluster.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AAAR;AAEQ;EACI,aAAA;AAAZ;AAEY;EACI,WAAA;AAAhB;AAIQ;EACI,gBAAA;EACA,aAAA;AAFZ;AAIY;EACI,WAAA;AAFhB","sourcesContent":[".block-table-claster-candle {\n    width: 200px;\n    height: 200px;\n    \n    .claster {\n        height: 24px;\n    }\n\n    .counter {\n        font-weight: 500;\n        fill:white;\n        font-size: 14px;\n        transform: none;\n\n        &.ask {\n            fill:#F10D0C;\n\n            &.colorize {\n                fill: white;\n            }\n        }\n\n        &.bid {\n            text-anchor: end;\n            fill: #39AB1D;\n\n            &.colorize {\n                fill: white;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
