import React, {useState} from "react";

import './Tile.scss'
import cn from "classnames";

enum Orientation {
    VERTICAL,
    HORIZONTAL
}

interface OwnProps {
    root?: boolean,
}

export const Tile: React.FC<OwnProps> = ({root}) => {
    const [tiles, setTiles] = useState<number[]>([])
    const [ orientation, setOrientation] = useState<Orientation|null>(null)

    const onAddTile = (orientation: Orientation, isStart?: boolean) => {
        setOrientation(orientation)
        setTiles([0,1])
    }

    return (
        <div className="tile">
            {orientation !== Orientation.VERTICAL && root && (
                <div className="add-left" onClick={() => onAddTile(Orientation.HORIZONTAL, true)}><span>+</span></div>
            )}
            <div className="tile-center-container">
                {orientation !== Orientation.HORIZONTAL && root && (
                    <div className="add-left" onClick={() => onAddTile(Orientation.VERTICAL, true)}><span>+</span></div>
                )}
                {!tiles.length && (<>
                    <span className="separate-vertical" onClick={() => onAddTile(Orientation.VERTICAL, true)} />
                    <span className="separate-horizontal" onClick={() => onAddTile(Orientation.HORIZONTAL, true)} />
                    </>
                )}
                <div className={"tile-center " + cn({vertical: orientation === Orientation.VERTICAL})}>
                    {tiles.map(tile => <Tile key={tile}/>)}
                    <div className="fantom-horizontal">
                        <div className="fantom-item"></div>
                        <div className="fantom-item"></div>
                    </div>
                </div>
                {orientation !== Orientation.HORIZONTAL && root && (
                    <div className="add-left"><span>+</span></div>
                )}
            </div>
            {orientation !== Orientation.VERTICAL && root && (
                <div className="add-right"><span>+</span></div>
            )}
        </div>
    )
}