import React, {memo} from 'react';
import "./SelectTarget.scss";
import {ISelectItem} from "../models";
import cn from "classnames";
import DropdownIcon from "../../../../Common/Components/Icons/DropdownIcon.svg";

/**
 * Селектор с выпадающим меню.
 * @prop value Выбранная опция.
 * @prop isOpen Флаг раскрытого выпадающего меню.
 * @prop [onClick] Обработчик нажатия на опцию.
 */
interface IButtonProp {
    value: ISelectItem;
    isOpen: boolean;
    onClick?: () => void;
    formatter?: (value: ISelectItem) => string;
}

export const SelectTarget: React.FC<IButtonProp> = memo((props: IButtonProp) => {
    const {value, isOpen, formatter, onClick} = props;

    const iconClassnames = cn({
        icon: true,
        open: isOpen
    })

    const getTitle = (value: ISelectItem): string | JSX.Element => {
        return formatter == null ? value.value : formatter(value);
    }

    return (
        <div className="selectTargetContainer" onClick={onClick}>
            <span>
                {getTitle(value)}
            </span>
            <div className={iconClassnames}>
                <img src={DropdownIcon} alt="Открыть/закрыть выпадающее меню"/>
            </div>
        </div>
    );
})

SelectTarget.displayName = 'SelectTarget';
