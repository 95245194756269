/* Количество строк с ценами. */
export const NUMBER_OF_PRICES_ROWS = 14;

/* Ширина столбца классической свечи. */
export const CANDLE_CLASSIC_COLUMN_WIDTH = 21;

/* Ширина столбца классической свечи. */
export const CLUSTER_COLUMN_WIDTH = 219;

/* Максимальная ширина бара ордеров в кластерном графике. */
export const CLUSTER_COLUMN_PRICE_BAR_WIDTH = 121;
