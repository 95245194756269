// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tradingMarkets #settings-button {
  position: absolute;
  right: 147px;
  top: 42px;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  padding: 5px;
}
#tradingMarkets .row-container {
  display: flex;
  align-items: center;
  width: 100%;
}
#tradingMarkets .row-container .description {
  font-size: 12px;
  color: white;
  margin-right: 5px;
}
#tradingMarkets .row-container .description.title {
  font-size: 14px;
  font-weight: 500;
}
#tradingMarkets .chart-to-start {
  width: fit-content;
  margin-top: 5px;
  cursor: pointer;
}
#tradingMarkets #settings-menu .MuiPaper-root {
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: end;
  background-color: #001c36;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Demo/SettingsMenu/SettingsMenu.scss"],"names":[],"mappings":"AAII;EACI,kBAAA;EACA,YAAA;EACA,SAAA;EACA,0CAAA;EACA,eAAA;EACA,0CAAA;EACA,kBAAA;EACA,YAAA;AAHR;AAMI;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AAJR;AAMQ;EACI,eAAA;EACA,YAAA;EACA,iBAAA;AAJZ;AAMY;EACI,eAAA;EACA,gBAAA;AAJhB;AASI;EACI,kBAAA;EACA,eAAA;EACA,eAAA;AAPR;AAWQ;EACI,aAAA;EACA,UAAA;EACA,sBAAA;EACA,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,0CAAA;EACA,kBAAA;EACA,YAAA;AATZ","sourcesContent":["@import \"../../../Common/Styles/colors.scss\";\n@import \"../../../Common/Styles/metrics\";\n\n#tradingMarkets {\n    #settings-button {\n        position: absolute;\n        right: 147px;\n        top: 42px;\n        background-color: rgba(255, 255, 255, 0.1);\n        cursor: pointer;\n        border: 1px solid rgba(255, 255, 255, 0.1);\n        border-radius: 2px;\n        padding: 5px;\n    }\n\n    .row-container {\n        display: flex;\n        align-items: center;\n        width: 100%;\n\n        .description {\n            font-size: 12px;\n            color: white;\n            margin-right: 5px;\n\n            &.title {\n                font-size: 14px;\n                font-weight: 500;\n            }\n        }\n    }\n\n    .chart-to-start {\n        width: fit-content;\n        margin-top: 5px;\n        cursor: pointer;\n    }\n\n    #settings-menu {\n        .MuiPaper-root {\n            display: flex;\n            z-index: 1;\n            flex-direction: column;\n            align-items: end;\n            background-color: #001c36;\n            cursor: pointer;\n            border: 1px solid rgb(255, 255, 255, 0.3);\n            border-radius: 2px;\n            width: 200px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
