import React, { useEffect, useState } from 'react'
import { LineChartData } from '../../data';
import "./SplitLine.scss";


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;
const LINE_CHART_WIDTH = 400;

interface IProps {

}

export const SplitLine: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);

    let lineChartPath = "M";
    let lineChartUpGradientPath = "";
    let lineChartBelowGradientPath = "";
    const borderLinePositionY = 70;

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;
        const isLastElement = i === LineChartData.length - 1;

        lineChartPath += openX + "," + openY + " ";

        if (openY < borderLinePositionY) {
            lineChartBelowGradientPath += openX + "," + openY + " ";
            lineChartUpGradientPath += openX + "," + borderLinePositionY + " ";
        } else {
            lineChartUpGradientPath += openX + "," + openY + " ";
            lineChartBelowGradientPath += openX + "," + borderLinePositionY + " ";
        }

        if (isLastElement) {
            lineChartBelowGradientPath += openX + "," + borderLinePositionY + " ";
            lineChartBelowGradientPath += 0 + "," + borderLinePositionY + " ";

            lineChartUpGradientPath += openX + "," + borderLinePositionY + " ";
            lineChartUpGradientPath += 0 + "," + borderLinePositionY + " ";
        }
    });

    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="line-chart">
                <defs>
                    <linearGradient id="verticalGradient3" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="rgba(67,191,113,0)" />
                        <stop offset="100%" stopColor="rgba(67,191,113,0.525)" />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id="verticalGradient4" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="rgba(238, 7, 7, 0.525)" />
                        <stop offset="100%" stopColor="rgba(238, 7, 7, 0)" />
                    </linearGradient>
                </defs>
                <line x1="0" y1={borderLinePositionY} x2={LINE_CHART_WIDTH} y2={borderLinePositionY} stroke="rgba(255, 255, 255, 0.25)" strokeWidth={1} />
                <path d={lineChartPath} fill="none" stroke="#49B6FF" strokeWidth="2" />

                {lineChartBelowGradientPath && <polygon fill="url(#verticalGradient4)" points={lineChartBelowGradientPath}></polygon>}
                {lineChartUpGradientPath && <polygon fill="url(#verticalGradient3)" points={lineChartUpGradientPath}></polygon>}
                {LineChartData.map((item, index) => {
                    if (canvasHeight === 0) {
                        return;
                    }


                    const openY = calculateY(item.open) - 3;
                    const openX = index * CHART_OFFSET - 3;
                    const closeY = calculateY(item.close)


                    return (
                        <>
                            <rect className="point" x={openX} y={openY} />

                        </>
                    )
                })}
            </svg>
        </>
    )
}

SplitLine.displayName = 'BlockLine'
