import React, { memo } from 'react';
import cn from "classnames";

import "./ConnectionPriceCell.scss";

interface OwnProps {
    userConnectionName: string;
    accountBalance: string;
}

export const ConnectionPriceCell: React.FC<OwnProps> = memo(({accountBalance, userConnectionName}) => (
    <div className="instrumentPriceContainer">
        <span className="price-name">{userConnectionName}</span>
        <span className="price">{accountBalance} $</span>
    </div>
))

