import React from 'react';
import "./NotificationSticker.scss";

/**
 * Стикер уведомления.
 */
export const NotificationSticker: React.FC = () => {

    return (
        <div className="notificationStickerContainer" />
    );
}

NotificationSticker.displayName = 'NotificationSticker';
