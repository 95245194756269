import React, { useState } from "react";
import './styles.scss';
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../Hooks/useTypedSelector";
import { RootState } from "../../Store/models";
import { showModalPopup } from "../../Store/action-creators";

export const ModalPopup: React.FC = () => {
    const dispatch = useDispatch();

    const popupData = useTypedSelector((state: RootState) => state.app.modalPopupData);

    const handlePopupSubmit = (): void => {
        dispatch(showModalPopup(null));
    }

    return (
        <>
            {popupData != null &&
                < div id="appModalPopup" >
                    <div className="dialog-popup">
                        <div className="title">{popupData.title}</div>
                        <div className="message">{popupData.message}</div>
                        <div className="buttons-container">
                            <button className='apply' type='button' onClick={handlePopupSubmit}>OK</button>
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

