import React, { useState } from "react";
// @ts-ignore
import * as faker from 'faker';

import { Switcher } from "../../Components/Switcher";
import { AskBidGroup } from "../../Components/Buttons/AskBidGroup";
import { TotalBlock } from "../../Components/CompositionBlock/TotalBlock";
import { NumberSelect } from "../../Components/Inputs/NumberSelect";
import { ColorPicker } from "../../Components/Inputs/ColorPicker";
import { TradingPanel } from "../../Components/Panels/TradingPanel";

import './StoryBook.scss'
import { Dropdown } from "primereact/dropdown";
import { Slider } from "primereact/slider";
import {Canvas} from "./Canvas/Canvas";

export const chartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

export const StoryBook: React.FC = () => {
    const options = ["NASDAQ: futures", "Binance: stocks"]

    const [sliderValue, setSliderValue] = useState(20)
    const [selectedAccount, setSelectedAccount] = useState(options[0])

    return <div className="story-book">
        <Canvas />

        <h2>Buttons</h2>
        <div className="story-book-block">
            <button className="blue-button">Blue Button</button>

            <button className="golden-button">Golden Button</button>

            <button className="blue-filled-button">Blue Filled Button</button>

            <Switcher options={["Left", "Center", "Right"]} />

            <Switcher options={["Top", "Middle", "Bottom"]} vertical defaultOption="Middle" />

            <Switcher options={["Off", "Entry price", "Following price"]} wrap className="price-switcher" />

            <AskBidGroup buy price={29123.56} currency={"USDT"} />

            <AskBidGroup sell price={10765.56} currency={"USDT"} />

        </div>
        <Slider min={0} max={100} className="demo-slider" value={sliderValue} step={10} onChange={(e) => setSliderValue(e.value as number)} orientation="horizontal" />

        <h2>Inputs</h2>
       
        <div className="story-book-block">
            <Dropdown
                id="account-select"
                value={selectedAccount}
                onChange={(e) => setSelectedAccount(e.value)}
                options={["NASDAQ: futures", "Binance: stocks"]}
                editable
                showOnFocus
                placeholder="Select Account"
            />
            <NumberSelect
                options={[
                    { value: 'Binance_stocks', className: 'binance-stocks', label: 'Binance: stocks' },
                    { value: 'other_stocks', className: 'Other-stocks', label: 'Other stocks' }
                ]}
            />
            <NumberSelect
                options={[
                    { value: '0.123456789', label: '0.123456789' },
                ]}
                numberButtons
            />
        </div>
        <h2>Blocks</h2>
        <div className="story-book-block">
            <TotalBlock price={1723.65} currency="USDT" />

            <div className="light-block">
                <button className="blue-filled-button">Cancell all orders</button>
                <button className="blue-filled-button">Close all</button>
            </div>
            <div className="light-block">
                <TotalBlock price={1723.65} currency="USDT" transparent vertical label="Avg. price" />
                <TotalBlock price="+0.24689" currency="BTC" transparent vertical label="Total volume" up />
                <TotalBlock price="+0.24689" currency="BTC" transparent vertical label="Total volume" up />
                <TotalBlock currency="24 / 41" price="1.76" transparent vertical label="RR ratio" up />
            </div>

            <div className="dark-block">
                <AskBidGroup sell price={29123.56} currency={"USDT"} />
                <div className="content-block">
                    <button className="blue-button">
                        Auto SL
                    </button>
                    <button className="blue-button">
                        Auto TP
                    </button>
                </div>
                <TotalBlock price={1723.65} currency="USDT" />
            </div>
        </div>
        <h2>Color picker</h2>
        <div className="story-book-block">
            <ColorPicker />
        </div>
        <h2>Panels</h2>
        <div className="story-book-block">
            <TradingPanel />
        </div>
    </div>
}