import { ChartType, FrameCount } from "../../CandlesClient/models/Candle";
import { CHART_TYPES_ID } from "../../Components/consts";
import { CLUSTER_SCALE, CLUSTER_SINGLE_SEGMENT_SCALE, DEFAULT_SCALE, LINE_CHART_SCALE, TimeFrame } from "../../Modules/Candles/constants";
import { ChartStyle, Dictionary } from "../models"

export const getSelectedChartStyle = (scaleIndex: number, selectedChartStyles: Dictionary<ChartStyle>): ChartStyle => {
    if (selectedChartStyles == null) {
        return ChartStyle.japanesePoor;
    }

    if (scaleIndex <= LINE_CHART_SCALE) {
        return selectedChartStyles[ChartType.lineChart] || ChartStyle.lineSimple;
    } else if (scaleIndex >= CLUSTER_SCALE) {
        return selectedChartStyles[ChartType.clusterChart] || ChartStyle.clusterBlock;
    } else {
        return selectedChartStyles[ChartType.candleChart] || ChartStyle.japanesePoor;
    }
}

export const getScaleIndexByChartType = (type: ChartType): number => {
    switch (type) {
        case ChartType.lineChart:
            return LINE_CHART_SCALE;
        case ChartType.clusterChart:
            return CLUSTER_SCALE;
    }

    return DEFAULT_SCALE;
}

export const getChartTypeByScaleIndex = (scaleIndex: number): ChartType => {
    if (scaleIndex <= LINE_CHART_SCALE) {
        return ChartType.lineChart;
    }

    if (scaleIndex >= CLUSTER_SCALE) {
        return ChartType.clusterChart;
    }

    return ChartType.candleChart;
}

export const getChartTypeByChartStyle = (style: ChartStyle): ChartType => {
    switch (style) {
        case ChartStyle.japaneseBlackLight:
        case ChartStyle.japaneseBright:
        case ChartStyle.japaneseBrightShadow:
        case ChartStyle.japanesePoor:
        case ChartStyle.japanesePoorTransparent:
        case ChartStyle.japaneseShadow:
        case ChartStyle.japaneseSmooth:
        case ChartStyle.japaneseSquare:
        case ChartStyle.japaneseStunner:
        case ChartStyle.japaneseStunnerTransparent:

        case ChartStyle.snakeWhite:
        case ChartStyle.snakeColorize:
        case ChartStyle.snakeCircle:
            return ChartType.candleChart;
        case ChartStyle.clusterTable:
        case ChartStyle.clusterBlock:
            return ChartType.clusterChart;
        case ChartStyle.lineBlock:
        case ChartStyle.lineGradientDown:
        case ChartStyle.lineGradientUp:
        case ChartStyle.lineReversal:
        case ChartStyle.lineSimple:
        case ChartStyle.lineSplit:
        case ChartStyle.lineVolatility:
            return ChartType.lineChart;
    }
}

export const isSnakeChartVisible = (style: ChartStyle): boolean => {
    return style === ChartStyle.snakeWhite
        || style === ChartStyle.snakeColorize
        || style === ChartStyle.snakeCircle;
}

export const isJapaneeseChartVisible = (style: ChartStyle): boolean => {
    return style === ChartStyle.japaneseBlackLight
        || style === ChartStyle.japaneseBright
        || style === ChartStyle.japaneseBrightShadow
        || style === ChartStyle.japanesePoor
        || style === ChartStyle.japanesePoorTransparent
        || style === ChartStyle.japaneseShadow
        || style === ChartStyle.japaneseSmooth
        || style === ChartStyle.japaneseSquare
        || style === ChartStyle.japaneseStunner
        || style === ChartStyle.japaneseStunnerTransparent;
}

export const isLineChartVisible = (style: ChartStyle): boolean => {
    return style === ChartStyle.lineBlock
        || style === ChartStyle.lineGradientDown
        || style === ChartStyle.lineGradientUp
        || style === ChartStyle.lineReversal
        || style === ChartStyle.lineSimple
        || style === ChartStyle.lineSplit
        || style === ChartStyle.lineVolatility;
}

export const isClusterChartVisible = (style: ChartStyle): boolean => {
    return style === ChartStyle.clusterTable
        || style === ChartStyle.clusterBlock;
}

export const getTimeFrameBySelectedChart = (chartTypeId: CHART_TYPES_ID): FrameCount => {
    switch (chartTypeId) {
        case CHART_TYPES_ID.S1:
            return {
                scaleIndex: 1,
                timeCount: 1,
                timeFrame: TimeFrame.seconds
            }
        case CHART_TYPES_ID.S5:
            return {
                scaleIndex: 1,
                timeCount: 5,
                timeFrame: TimeFrame.seconds
            }
        case CHART_TYPES_ID.S15:
            return {
                scaleIndex: 1,
                timeCount: 15,
                timeFrame: TimeFrame.seconds
            }
        case CHART_TYPES_ID.S30:
            return {
                scaleIndex: 1,
                timeCount: 30,
                timeFrame: TimeFrame.seconds
            }

        case CHART_TYPES_ID.M1:
            return {
                scaleIndex: 1,
                timeCount: 1,
                timeFrame: TimeFrame.minutes
            }
        case CHART_TYPES_ID.M5:
            return {
                scaleIndex: 1,
                timeCount: 5,
                timeFrame: TimeFrame.minutes
            }
        case CHART_TYPES_ID.M15:
            return {
                scaleIndex: 1,
                timeCount: 15,
                timeFrame: TimeFrame.minutes
            }
        case CHART_TYPES_ID.M30:
            return {
                scaleIndex: 1,
                timeCount: 30,
                timeFrame: TimeFrame.minutes
            }

        case CHART_TYPES_ID.H1:
            return {
                scaleIndex: 1,
                timeCount: 1,
                timeFrame: TimeFrame.hours
            }
        case CHART_TYPES_ID.H4:
            return {
                scaleIndex: 1,
                timeCount: 4,
                timeFrame: TimeFrame.hours
            }
        case CHART_TYPES_ID.H8:
            return {
                scaleIndex: 1,
                timeCount: 8,
                timeFrame: TimeFrame.hours
            }
        case CHART_TYPES_ID.H12:
            return {
                scaleIndex: 1,
                timeCount: 12,
                timeFrame: TimeFrame.hours
            }

        case CHART_TYPES_ID.D1:
            return {
                scaleIndex: 1,
                timeCount: 1,
                timeFrame: TimeFrame.days
            }
        case CHART_TYPES_ID.D3:
            return {
                scaleIndex: 1,
                timeCount: 3,
                timeFrame: TimeFrame.days
            }
        case CHART_TYPES_ID.D7:
            return {
                scaleIndex: 1,
                timeCount: 7,
                timeFrame: TimeFrame.days
            }
        case CHART_TYPES_ID.D14:
            return {
                scaleIndex: 1,
                timeCount: 14,
                timeFrame: TimeFrame.days
            }
        case CHART_TYPES_ID.MN1:
            return {
                scaleIndex: CLUSTER_SINGLE_SEGMENT_SCALE,
                timeCount: 1,
                timeFrame: TimeFrame.minutes
            }
    }
}