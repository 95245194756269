import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';

import {Avatar} from "../../Common/Components/Avatar/Avatar";
import {ChooseButton} from "../../Common/Components/ChooseButton/ChooseButton";
import { useTypedSelector } from '../../Hooks/useTypedSelector';
import { RootState } from '../../Store/models';
import {changeStockSymbol, openCloseInstrumentManager} from "../../Store/action-creators";
import {ChartMenu} from "../ChartMenu/ChartMenu";
import useComponentVisible from "../../Hooks/useComponentVisible";
import ChartSettings from "../../Common/Components/Icons/ButtonIcons/ChartSettings.svg";
import Candles from "../../Common/Components/Icons/ButtonIcons/Candles.svg";
import TechnicalAnalysis from "../../Common/Components/Icons/ButtonIcons/TechnicalAnalysis.svg";


import { t } from 'i18next';
import { CandleMenu } from '../CandleMenu/CandleMenu';
import { TechnicalAnalysisMenu } from '../TechnicalAnalysisMenu/TechnicalAnalysisMenu';
import { InstrumentsManager } from '../InstrumentsManager/InstrumentsManager';
import './HeaderMenu.scss'
import {ChartSeparator} from "./ChartSeparator";
import {TradingConnections} from "./TradingConnections";


export const HeaderMenu: React.FC = () => {

    const [currentStockSymbol, setCurrentStockSymbol] = useState('');

    const {componentRef, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);
    const instruments = useComponentVisible(false);
    const tradingConnections = useComponentVisible(false);
    const candleSettings = useComponentVisible(false);
    const chartSettings = useComponentVisible(false);
    const technicalAnalysis = useComponentVisible(false);
    const chartSeparator = useComponentVisible(false);

    const {terminalStockSymbol} = useTypedSelector((state: RootState) => state.financialOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        if (terminalStockSymbol) {
            setCurrentStockSymbol(terminalStockSymbol)
        }
    }, [terminalStockSymbol])

    useEffect(() => {
        dispatch(changeStockSymbol(currentStockSymbol));
    }, [currentStockSymbol, dispatch])

    const handleInstrumentsClick = () => {
        instruments.setIsComponentVisible(prev => !prev);
    }

    const handleChartSettingsClick = () => {
        chartSettings.setIsComponentVisible(prev => !prev);
    }

    const handleClickChartMenu = () => {
        setIsComponentVisible(prev => !prev);
    }

    const handleClickCandlesMenu = () => {
        candleSettings.setIsComponentVisible(prev => !prev)
    }

    const handleClickTechnicalAnalysisMenu = () => {
        technicalAnalysis.setIsComponentVisible(prev => !prev)
    }

    const handleClickChartSeparatorMenu = () => {
        chartSeparator.setIsComponentVisible(prev => !prev)
    }

    const handleClickTradingConnectionsMenu = () => {
        tradingConnections.setIsComponentVisible(prev => !prev)
    }

    const handleCloseInstrumentManager = () => {
        instruments.setIsComponentVisible(prev => !prev);
        //dispatch(openCloseInstrumentManager(false))
    }

    const onTradingConnectionsClose = () => {
        console.log('onTradingConnectionsClose')
        tradingConnections.setIsComponentVisible(false)
    }

    return (
        <div className="header-menu-container">
            <div className="header-menu" id="testId">
                <ChooseButton
                    buttonText={terminalStockSymbol}
                    label={''}
                    onClick={handleInstrumentsClick}
                />
                <ChooseButton
                    buttonText={'M15'}
                    label={''}
                    onClick={handleClickChartMenu}
                />
                 <ChooseButton
                    label={t('Components:Components.NavigationMenu.ChartSettings') as string}
                    icon={<img src={ChartSettings} alt="ChartSettings" />}
                    onClick={handleChartSettingsClick}
                />
                 <ChooseButton
                    label={t('Components:Components.NavigationMenu.Candles') as string}
                    icon={<img src={Candles} alt="Candles" />}
                    onClick={handleClickCandlesMenu}
                />
                 <ChooseButton
                    label={t('Components:Components.NavigationMenu.TechnicalAnalysis') as string}
                    icon={<img src={TechnicalAnalysis} alt="TechnicalAnalysis" />}
                    onClick={handleClickTechnicalAnalysisMenu}
                />
                <ChooseButton
                    label={t('Components:Components.NavigationMenu.TechnicalAnalysis') as string}
                    icon={<img src={TechnicalAnalysis} alt="TechnicalAnalysis" />}
                    onClick={handleClickTechnicalAnalysisMenu}
                />
                <ChooseButton
                    label={t('Components:Components.NavigationMenu.AddChart') as string}
                    icon={<img src={TechnicalAnalysis} alt="TechnicalAnalysis" />}
                    onClick={handleClickChartSeparatorMenu}
                />
                <Avatar hasNotification={true} />
            </div>
            {isComponentVisible && <div ref={componentRef}>
                 <ChartMenu />
            </div>}
            {instruments.isComponentVisible && <div ref={instruments.componentRef}>
                <InstrumentsManager onClick={handleCloseInstrumentManager} />
            </div>}
            {candleSettings.isComponentVisible && <div ref={candleSettings.componentRef}>
                <CandleMenu/>
            </div>}
            {technicalAnalysis.isComponentVisible && <div ref={technicalAnalysis.componentRef}>
                <TechnicalAnalysisMenu/>
            </div>}

            {chartSeparator.isComponentVisible && <div ref={chartSeparator.componentRef}>
                <ChartSeparator />
            </div>}
        </div>

    );
}
