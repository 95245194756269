import React, { useEffect, useState } from 'react'
import { ClasterCandleData, LineChartData } from '../../data';
import "./ClassicHistogram.scss";


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

interface IProps {

}

export const ClassicHistogram: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);
    const [highestClaster, setHighestClaster] = useState(0);

    let lineChartPath = "M";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;

        lineChartPath += openX + "," + openY + " ";
    });


    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);
        // @ts-ignore
        const highestAsk = ClasterCandleData.hasMax("ask").ask;
        // @ts-ignore
        const highestBid = ClasterCandleData.hasMax("bid").bid;
        setHighestClaster(highestAsk > highestBid ? highestAsk : highestBid);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="snake-claster-candle">
                <pattern id="redGradient" patternUnits="userSpaceOnUse" width="100" height="25">
                    <image href="red-horizontal-gradient.svg" x="0" y="0" width="100" height="25" />
                </pattern>
                <pattern id="greenGradient" patternUnits="userSpaceOnUse" width="100" height="25">
                    <image href="green-horizontal-gradient.svg" x="0" y="0" width="100" height="25" />
                </pattern>
                
                {ClasterCandleData.map((item, index) => {
                    if (canvasHeight === 0) {
                        return;
                    }

                    const y = index * 25;
                    const askWidth = 100 / highestClaster * item.ask;
                    const bidWidth = 100 / highestClaster * item.bid;
                    const askX = 100 - askWidth;
                    const isGoingUp = item.ask < item.bid;

                    return (
                        <>
                            <rect className="claster red-claster" fill="url(#redGradient)" x={askX} y={y} width={askWidth} />
                            <rect className="claster green-claster" fill="url(#greenGradient)" x="100" y={y} width={bidWidth} />
                            <text className="counter ask" x="75" y={y + 17}>{item.ask}</text>
                            <text className="counter bid" x="130" y={y + 17}>{item.bid}</text>
                            <rect x="90" y={y} className={`indicator ${isGoingUp ? 'green' : 'red'}`} />
                        </>
                    )
                })}
            </svg>
        </>
    )
}

ClassicHistogram.displayName = 'SimpleLine'
