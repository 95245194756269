import React, {MouseEventHandler} from 'react';
import "./SelectDropdown.scss";
import {ISelectItem} from "../models";
import cn from "classnames";

/**
 * Селектор с выпадающим меню.
 * @prop value Выбранная опция.
 * @prop isOpen Флаг раскрытого выпадающего меню.
 * @prop [onSelect] Обработчик нажатия на опцию.
 */
interface IButtonProp {
    value: ISelectItem;
    items: ISelectItem[];
    isOpen: boolean;
    onSelect: (value: ISelectItem) => void;
    formatter?: (value: ISelectItem) => string;
}

export const SelectDropdown: React.FC<IButtonProp> = (props: IButtonProp) => {
    const {value, items, isOpen, formatter, onSelect} = props;

    const itemClassnames = (valueId: number|string) => {
        return cn({
            item: true,
            selectedItem: valueId === value.id
        })
    }

    const handleClick = (item: ISelectItem) : MouseEventHandler => {
        return (e) => {
            e.stopPropagation();
            onSelect(item)
        }
    }

    const renderItems = () => {
        return items.map(item => (
            <span className={itemClassnames(item.id)} key={item.id} >
                <span onClick={handleClick(item)}>
                    {getTitle(item)}
                </span>
            </span>
        ))
    }

    const iconClassnames = cn({
        selectDropdownContainer: true,
        ['selectDropdownContainer__active']: isOpen
    })

    const getTitle = (value: ISelectItem): string | JSX.Element => {
        return formatter == null ? value.value : formatter(value);
    }


    return (
        <div className={iconClassnames}>
            <div className="dropdown">
                {renderItems()}
            </div>
        </div>
    );
}

SelectDropdown.displayName = 'SelectDropdown';
