// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snake-claster-candle {
  width: 300px;
  height: 300px;
  padding: 40px;
}
.snake-claster-candle .claster {
  height: 24px;
  rx: 4;
  cx: 4;
}
.snake-claster-candle .connector {
  width: 20px;
  height: 25px;
  fill: #2C3446;
}
.snake-claster-candle .connector:last-child {
  height: 20px;
}
.snake-claster-candle .indicator {
  width: 24px;
  height: 24px;
  rx: 2;
  cx: 2;
}
.snake-claster-candle .indicator.red {
  fill: #FF0000;
  filter: drop-shadow(0px 0px 11px #FF0000);
}
.snake-claster-candle .indicator.green {
  fill: #39AB1D;
  filter: drop-shadow(0px 0px 11px #39AB1D);
}
.snake-claster-candle .counter {
  font-weight: 500;
  fill: white;
  font-size: 14px;
  transform: none;
}
.snake-claster-candle .counter.ask {
  text-anchor: end;
  fill: #F10D0C;
}
.snake-claster-candle .counter.bid {
  fill: #39AB1D;
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/ClusterCharts/ClassicHistogram/ClassicHistogram.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,aAAA;AACJ;AACI;EACI,YAAA;EACA,KAAA;EACA,KAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,aAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,WAAA;EACA,YAAA;EACA,KAAA;EACA,KAAA;AAFR;AAKQ;EACI,aAAA;EAEA,yCAAA;AAHZ;AAMQ;EACI,aAAA;EAEA,yCAAA;AAJZ;AAQI;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AANR;AAQQ;EACI,gBAAA;EACA,aAAA;AANZ;AASQ;EACI,aAAA;AAPZ","sourcesContent":[".snake-claster-candle {\n    width: 300px;\n    height: 300px; \n    padding: 40px;\n    \n    .claster {\n        height: 24px;\n        rx: 4;\n        cx: 4;\n    }\n    \n    .connector {\n        width: 20px;\n        height: 25px;\n        fill: #2C3446;\n\n        &:last-child {\n            height:20px;\n        }\n    }\n\n    .indicator {\n        width: 24px;\n        height: 24px;\n        rx: 2;\n        cx: 2;\n        \n\n        &.red {\n            fill: #FF0000;\n            -webkit-filter: drop-shadow(0px 0px 11px #FF0000);\n            filter: drop-shadow( 0px 0px 11px #FF0000);\n        }\n\n        &.green {\n            fill: #39AB1D;\n            -webkit-filter: drop-shadow(0px 0px 11px #39AB1D);\n            filter: drop-shadow( 0px 0px 11px #39AB1D);\n        }\n    }\n\n    .counter {\n        font-weight: 500;\n        fill:white;\n        font-size: 14px;\n        transform: none;\n\n        &.ask {\n            text-anchor: end;\n            fill:#F10D0C;\n        }\n\n        &.bid {\n            fill: #39AB1D;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
