import React, {memo} from 'react';

import ExitIconImg from './ExitIcon.svg'
/**
 * Иконка закрытия.
 * @prop onClose Обработчик нажатия на иконку закрытия.
 */
interface IProps {
    onClose: () => void;
}

export const ExitIcon: React.FC<IProps> = memo(({onClose}: IProps) => (
    <div onClick={onClose} className="exit-icon" style={{cursor: 'pointer',width: '24px', height: '24px', backgroundColor: 'rgba(255, 255, 255, 0.15)', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px'}}>
        <img src={ExitIconImg} alt="Выйти"/>
    </div>
))
