import React, {memo} from 'react';
import cn from "classnames";
import {ETagSize} from "./enums";

import "./Tag.scss";

/**
 * Tag.
 * @prop label Лейбл тега.
 * @prop size Размер тега.
 * @prop checked Флаг выделенного тега.
 * @prop onClick обработчик нажатия на тег.
 * @prop disable Флаг неактивности тега.
 */
interface IProps {
    label: string;
    size: ETagSize;
    checked?: boolean;
    onClick?: () => void;
    disable?: boolean;
}

export const Tag: React.FC<IProps> = memo((props: IProps) => {
    const {label, size, checked, disable = false, onClick} = props;

    const classNames = cn({
        chipContainer: true,
        checked: checked,
        sizeSM: size === ETagSize.SM,
        sizeMD: size === ETagSize.MD,
        disable
    })

    return (
        <div className={classNames} onClick={onClick}>{label}</div>
    );
})

Tag.displayName = 'Tag';
