import React from 'react';
import {useTranslation} from "react-i18next";

import MagnifierIcon from "../../../Common/Components/Icons/MagnifierIcon.svg";

import "./SearchInput.scss";

interface IProps {
    text: string;
    onChange: (value: string) => void;
}

export const SearchInput: React.FC<IProps> = ({text, onChange}) => {

    const { t } = useTranslation();

    return (
        <div className="searchInputContainer">
            <input
                type={'text'}
                className="searchInput"
                value={text}
                onChange={(e) => onChange(e.target.value)}
                placeholder={t('Common:Common.Components.SearchInput.placeholder') as string}
            />
            <div className="searchInputIcon">
                <img src={MagnifierIcon} alt="Поиск"/>
            </div>
        </div>
    );
}
