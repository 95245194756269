import {NUMBER_OF_PRICES_ROWS} from "./consts";

export const calcMaxHighPrice = (prices: number[]) => Math.max(...prices);

export const calcMinLowPrice = (prices: number[]) => Math.min(...prices);

export const calcHighLowPriceDifference = (highPrice: number, lowPrice: number) => highPrice - lowPrice

export const calcCandlesTopPosition = (pixelRate: number, maxHighPrice: number, candleHigh: number) => {
    return (maxHighPrice - candleHigh) * pixelRate
}

export const calcPricePixelRate = (highLowPriceDifference: number, currentHeight: number) => {
    return (currentHeight / NUMBER_OF_PRICES_ROWS * 7) / highLowPriceDifference
}

export const calcFullInstrumentNameLowerCase = (name: string, fullName: string) => {
    return `${name} ${fullName}`.toLowerCase()
}

export const randomIntFromInterval = (min: number, max: number) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}
