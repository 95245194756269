import React, { memo } from 'react';

import "./ChooseButton.scss";

interface OwnProps {
    buttonText?: string;
    onClick: () => void;
    label?: string;
    icon?: JSX.Element | JSX.Element[];
    cssClass?: string;
    children?:
    boolean | React.ReactChild
    | React.ReactChild[];
}

export const ChooseButton: React.FC<OwnProps> = memo(({ buttonText, label, cssClass, icon, onClick, children }) => (
    <div className="choose-button-container">
        <div className={`choose-button ${cssClass} ${label ? 'block' : ''}`} onClick={onClick}>
            {buttonText && <div className="button">{buttonText}</div>}
            {icon && icon}
            {label && <span className="label">{label}</span>}
        </div>
        {children}
    </div>
))


