import React, { useEffect, useState } from 'react'
import { ClasterCandleData, LineChartData } from '../../data';
import "./RoundHistogram.scss";

//@ts-ignore
Array.prototype.hasMin = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] < curr[attrib] ? prev : curr;
    })) || null;
}

//@ts-ignore
Array.prototype.hasMax = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] > curr[attrib] ? prev : curr;
    })) || null;
}


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

interface IProps {

}

export const RoundHistogram: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);
    const [highestClaster, setHighestClaster] = useState(0);

    let lineChartPath = "M";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;

        lineChartPath += openX + "," + openY + " ";
    });


    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);
        // @ts-ignore
        const highestAsk = ClasterCandleData.hasMax("ask").ask;
        // @ts-ignore
        const highestBid = ClasterCandleData.hasMax("bid").bid;
        setHighestClaster(highestAsk > highestBid ? highestAsk : highestBid);

        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="claster-candle">
                <defs>

                    <linearGradient id="ClasterCandleGradientRed">
                        <stop stopColor="rgba(171, 70, 70, 0.5) " offset="1%" />
                        <stop stopColor="rgba(171, 70, 70, 0) " offset="99%" />

                    </linearGradient>
                    <linearGradient id="ClasterCandleGradientGreen">
                        <stop stopColor="rgba(47, 119, 29, 0)" offset="1%" />
                        <stop stopColor=" rgba(47, 119, 29, 0.5)" offset="99%" />
                    </linearGradient>
                </defs>

                {ClasterCandleData.map((item, index) => {
                    if (canvasHeight === 0) {
                        return;
                    }


                    const y = index * 25;
                    const askWidth = 100 / highestClaster * item.ask;
                    const bidWidth = 100 / highestClaster * item.bid;
                    const askX = 100 - askWidth;
                    const isGoingUp = item.ask < item.bid;
                    const indicatorColor = isGoingUp ? "#2F771D" : "#D23434";

                    return (
                        <>
                            <rect className="claster red-claster-bg" fill="url(#ClasterCandleGradientRed)" x="0" y={y} width="100" />
                            <rect className="claster green-claster-bg" fill="url(#ClasterCandleGradientGreen)" x="100" y={y} width="100" />

                            <rect className="claster red-claster" fill="#D23434" x={askX} y={y} width={askWidth} />
                            <rect className="claster green-claster" fill="#2F771D" x="100" y={y} width={bidWidth} />

                            {item.inside && <line x1="90" y1={y} x2="90" y2={y + 25} strokeWidth="4" stroke="#49B6FF" />}
                            {item.inside && <line x1="110" y1={y} x2="110" y2={y + 25} strokeWidth="4" stroke="#49B6FF" />}
                            <text className="counter" x="10" y={y + 15}>{item.ask}</text>
                            <text className="counter right" x="190" y={y + 15}>{item.bid}</text>
                            <rect x="90" y={y} className="connector" />
                            <rect x="95" y={y + 5} className="indicator" fill={indicatorColor} />
                        </>
                    )
                })}
            </svg>
        </>
    )
}

RoundHistogram.displayName = 'SimpleLine'
