import React from "react";

import {AccountBlock} from "./AccountBlock";
import {AskBidGroup} from "../../Buttons/AskBidGroup";
import {TotalBlock} from "../../CompositionBlock/TotalBlock";
import {Switcher} from "../../Switcher";
import {AutoStopTake} from "./AutoStopTake";

import './TradingPanel.scss'
import {NumberSelect} from "../../Inputs/NumberSelect";

interface OwnProps {

}

export const TradingPanel: React.FC<OwnProps> = ({}) => {

    return (
        <div className="trading-panel">
            <AccountBlock />
            <div className="dark-block">
                <AskBidGroup price={29123.56} currency={"USDT"} buy  />
                <div className="content-block">
                    <button className="blue-button">
                        Auto SL
                    </button>
                    <button className="blue-button">
                        Auto TP
                    </button>
                </div>
                <TotalBlock price={1723.65} currency="USDT" />
            </div>
            <div className="light-block">
                <TotalBlock price={1723.65} currency="USDT" transparent vertical label="Avg. price" />
                <TotalBlock price="+0.24689" currency="BTC" transparent vertical label="Total volume" up />
                <TotalBlock price="+0.24689" currency="BTC" transparent vertical label="Total volume" up />
                <TotalBlock currency="24 / 41" price="1.76" transparent vertical label="RR ratio" yellow />
            </div>
            <div className="dark-block">
                <AskBidGroup sell price={29121.73} />
                <div className="content-block">
                    <button className="blue-button">
                        Auto SL
                    </button>
                    <button className="blue-button">
                        Auto TP
                    </button>
                </div>
                <TotalBlock price={1723.65} currency="USDT" />
            </div>
            <div className="flex-row">
                <div className="light-block">
                    <button className="blue-filled-button">Cancell all orders</button>
                    <button className="blue-filled-button">Close all</button>
                </div>
                <div className="light-block">
                    <Switcher options={["GTC", "DAY"]} defaultOption="GTC" />
                    <button className="blue-button">OCO</button>
                </div>
            </div>
            <div className="flex-row">
                <div className="light-block">
                    <AutoStopTake percentRisk={15} statsValue={0.1234567} />
                </div>
                <div className="light-block">
                    <AutoStopTake percentRisk={25} statsValue={0.1234567} take />
                </div>
            </div>
            <div className="light-block">
                <Switcher options={["Stop Limit", "Bottom"]} vertical defaultOption="Stop Limit" />
                <NumberSelect
                    options={[
                        {value: '2222', label:'2222'},
                    ]}
                    numberButtons
                    label="Trailing"
                    unitLabel="ticks"
                />
                <Switcher options={["Off", "Entry price", "Following price"]} wrap className="price-switcher" defaultOption="Off" />
            </div>
            <div className="flex-row">
                <div className="light-block">
                    <NumberSelect
                        options={[
                            {value: '6666', label:'6666'},
                            {value: '2222', label:'2222'},
                        ]}
                        numberButtons
                        label="Slippage"
                        unitLabel="ticks"
                    />
                </div>
                <div className="light-block flex-column">
                    <label className="control-label">Trade with confirmation</label>
                    <Switcher options={["On", "Off"]} defaultOption="On" />
                </div>
                <div className="light-block flex-column">
                    <label className="control-label">Trade on chart</label>
                    <Switcher options={["On", "Off"]} defaultOption="On" />
                </div>
            </div>

        </div>
    )
}