// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.claster-candle {
  width: 300px;
  height: 300px;
  padding: 40px;
}
.claster-candle .claster {
  height: 20px;
  rx: 10;
  cx: 10;
}
.claster-candle .connector {
  width: 20px;
  height: 25px;
  fill: #2C3446;
}
.claster-candle .connector:last-child {
  height: 20px;
}
.claster-candle .indicator {
  width: 10px;
  height: 10px;
  rx: 5;
  cx: 5;
  stroke: black;
}
.claster-candle .counter {
  font-weight: 500;
  fill: white;
  font-size: 14px;
  transform: none;
}
.claster-candle .counter.right {
  text-anchor: end;
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/ClusterCharts/RoundHistogram/RoundHistogram.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,aAAA;AACJ;AACI;EACI,YAAA;EACA,MAAA;EACA,MAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,aAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,WAAA;EACA,YAAA;EACA,KAAA;EACA,KAAA;EACA,aAAA;AAFR;AAKI;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AAHR;AAKQ;EACI,gBAAA;AAHZ","sourcesContent":[".claster-candle {\n    width: 300px;\n    height: 300px; \n    padding: 40px;\n    \n    .claster {\n        height: 20px;\n        rx: 10;\n        cx: 10;\n    }\n    \n    .connector {\n        width: 20px;\n        height: 25px;\n        fill: #2C3446;\n\n        &:last-child {\n            height:20px;\n        }\n    }\n\n    .indicator {\n        width: 10px;\n        height: 10px;\n        rx: 5;\n        cx: 5;\n        stroke: black;\n    }\n\n    .counter {\n        font-weight: 500;\n        fill:white;\n        font-size: 14px;\n        transform: none;\n\n        &.right {\n            text-anchor: end;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
