import React, { useEffect, useState } from 'react'
import { ClasterCandleData, LineChartData } from '../../data';
import "./TableCluster.scss";

//@ts-ignore
Array.prototype.hasMin = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] < curr[attrib] ? prev : curr;
    })) || null;
}

//@ts-ignore
Array.prototype.hasMax = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] > curr[attrib] ? prev : curr;
    })) || null;
}

export interface Claster {
    ask: number;
    bid: number;
    inside: boolean;
}


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

const getClasterBorderPath = (data: Claster[], startX: number, endX: number, elementHeight: number): string => {
    let path = "M"
    let startPoitionY: number | null = null;
    let endPositionY: number | null = null;

    data.forEach((element, index) => {
        if (element.inside && startPoitionY == null) {
            startPoitionY = index * elementHeight;
        }

        if (!element.inside && startPoitionY != null) {
            endPositionY = index * elementHeight;
        }
    })

    path +=
        + startX + "," + startPoitionY + " "
        + endX + "," + startPoitionY + " "
        + endX + "," + endPositionY + " "
        + startX + "," + endPositionY + " ";

    return path;
}

interface IProps {

}

export const TableCluster: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);
    const [highestClaster, setHighestClaster] = useState(0);

    let lineChartPath = "M";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;

        lineChartPath += openX + "," + openY + " ";
    });


    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);
        // @ts-ignore
        const highestAsk = ClasterCandleData.hasMax("ask").ask;
        // @ts-ignore
        const highestBid = ClasterCandleData.hasMax("bid").bid;
        setHighestClaster(highestAsk > highestBid ? highestAsk : highestBid);

        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>

            <svg className="table-claster-candle">

                <pattern id="redClasterGradient" patternUnits="userSpaceOnUse" width="50" height="25">
                    <image href="red-claster-gradient.svg" x="0" y="0" width="50" height="25" />
                </pattern>
                <pattern id="greenClasterGradient" patternUnits="userSpaceOnUse" width="50" height="25">
                    <image href="green-claster-gradient.svg" x="0" y="0" width="50" height="25" />
                </pattern>
                <path d={getClasterBorderPath(ClasterCandleData, 50, 40, 25)} fill="none" stroke="#39AB1D" strokeWidth="3" />
                <path d={getClasterBorderPath(ClasterCandleData, 150, 160, 25)} fill="none" stroke="#39AB1D" strokeWidth="3" />
                {ClasterCandleData.map((item, index) => {
                    if (canvasHeight === 0) {
                        return;
                    }

                    const xPadding = 50;
                    const y = index * 25;
                    const isGoingUp = item.ask < item.bid;
                    const redBg = isGoingUp ? "transparent" : "url(#redClasterGradient)";
                    const greenBg = isGoingUp ? "url(#greenClasterGradient)" : "transparent";

                    return (
                        <>
                            <rect className="claster" fill={redBg} x={xPadding} y={y} width={50} />
                            <rect className="claster" fill={greenBg} x={xPadding + 50} y={y} width={50} />
                            <text className="counter ask" x={xPadding + 10} y={y + 17}>{item.ask}</text>
                            <text className="counter bid" x={xPadding + 85} y={y + 17}>{item.bid}</text>
                        </>
                    )
                })}
            </svg>
        </>
    )
}

TableCluster.displayName = 'SimpleLine'
