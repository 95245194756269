import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonSize } from "../../../Common/Components/ToggleButton/ToggleButton";

import '../styles.scss';
import { useNavigate } from "react-router-dom";
import { UserProfile } from "../../../services/userProfile";
import cn from "classnames";
import { ExitIcon } from "../../../Common/Components/Icons/ExitIcon/ExitIcon";
import checkIcon from '../icons/ph-check.svg'

export enum inputType {
    password = 'password',
    email = 'email',
    text = 'text'
}

export const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)

    const [email, setEmail] = useState('');
    const [emailValid, setEmaiValid] = useState<boolean | null>(null);

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const handleEmailValidation = () => {
        setEmaiValid(emailPattern.test(email));
    }

    const handleEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        handleEmailValidation();

        if (!emailValid) {
            return;
        }

        setIsLoading(true);

        UserProfile.rasswordReset(email)
            .then((response) => {
                setIsLoading(false)

                alert(response.data.message);
            })
            .catch((error: any) => {
                setIsLoading(false)

                alert(error.message);

                console.log(error)
            });
    };

    return (
        <div id="authPage">
            <div className="popup">
                <ExitIcon onClose={() => { navigate('/') }} />
                <div className="form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-fields">
                            <div className="page-title">Reset Password ?</div>
                            <div className="field-container">
                                <span className="form-label description">{t('UserManagement:signUp.enter-e-mail')}</span>
                                <input
                                    type="email"
                                    disabled={isLoading}
                                    className={"email-input "
                                        + (emailValid === false ? 'error' : '')
                                        + (emailValid ? 'valid' : '')}
                                    onBlur={handleEmailValidation}
                                    onChange={handleEmailChange} />
                                {emailValid &&
                                    <img className="input-icon" src={checkIcon} />
                                }
                            </div>
                            <button disabled={isLoading} className={cn({ "button-loader": isLoading, "sign-up": true })}>
                                {!isLoading && "Continue"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    )
}

