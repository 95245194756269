// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectContainer {
  width: max-content;
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 6px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  transition: all ease-in-out 0.1s;
}

.open {
  background: rgba(0, 0, 0, 0.25);
}

.SM {
  width: 50px;
}

.LG {
  width: 91px;
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/Select/Select.scss"],"names":[],"mappings":"AAGA;EACI,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oCAAA;EACA,gCAAA;AAFJ;;AAKA;EACI,+BAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ","sourcesContent":["@import '../../../Common/Styles/colors.scss';\n@import '../../../Common/Styles/metrics.scss';\n\n.selectContainer {\n    width: max-content;\n    position: relative;\n    display: flex;\n    align-items: center;\n    padding: 3px 6px;\n    border-radius: 4px;\n    background: rgba(255, 255, 255, 0.1);\n    transition: all ease-in-out 0.1s;\n}\n\n.open {\n    background: rgba(0, 0, 0, 0.25);\n}\n\n.SM {\n    width: 50px;\n}\n\n.LG {\n    width: 91px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
