import React from "react";
import "./LineChart.scss";
import { LineChartData } from "../../../CandlesClient/models/Candle";

export interface LineChartProps {
    lineChartData: LineChartData;
}

export const LineChart = (props: LineChartProps) => {
    const { lineChartData } = props;

    return (
        <>
            <defs>
                <linearGradient id="verticalGradientDown" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(73, 182, 255, 0.525)" />
                    <stop offset="100%" stopColor=" rgba(73, 182, 255, 0)" />
                </linearGradient>
                <linearGradient id="verticalGradientUp" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(73, 182, 255, 0)" />
                    <stop offset="100%" stopColor=" rgba(73, 182, 255, 0.525)" />
                </linearGradient>
                <linearGradient id="volatilityLineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(73, 182, 255, 0)" />
                    <stop offset="100%" stopColor=" rgba(73, 182, 255, 0.525)" />
                </linearGradient>
                <linearGradient id="splitLineGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(67,191,113,0)" />
                    <stop offset="100%" stopColor="rgba(67,191,113,0.525)" />
                </linearGradient>
            </defs>

            {lineChartData.linePath && <path d={lineChartData.linePath} className="line" />}
            {lineChartData.upLinePath && <path d={lineChartData.upLinePath} className="line up" />}
            {lineChartData.downLinePath && <path d={lineChartData.downLinePath} className="line down" />}
            {lineChartData.gradientPath && <polygon className='line-gradient' points={lineChartData.gradientPath}></polygon>}
            {lineChartData.points?.map((point, index) => {
                return (
                    <rect className="point" key={index} x={point.x} y={point.y} />
                )
            })}
        </>
    )
}