import React from "react";
import cn from "classnames";

import './TotalBlock.scss'

interface OwnProps {
    price: number|string
    currency: string,
    label?: string,
    vertical?: boolean,
    transparent?: boolean,
    up?: boolean,
    yellow?: boolean
}
export const TotalBlock: React.FC<OwnProps> = ({
                                                   price,
                                                   currency,
                                                   label,
                                                   transparent,
                                                   vertical,
                                                   up,
                                                   yellow}) => {
    return (
        <div className={"total-block " + cn({transparent, vertical, up, yellow })}>
            <span className="top-label">{label || 'TOTAL'}</span>
            {vertical ? (
                <>
                    <span className="price">{price}</span>
                    <span className="currency">{currency}</span>
                </>
            ) : (
                <span className="price">{price}{' '}
                    <span className="currency">{currency}</span>
                </span>
                )}
        </div>
    )
}