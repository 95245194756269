// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonWithIconContainer {
  display: flex;
  align-items: center;
  width: max-content;
  height: 24px;
  padding: 0 7px;
  background-color: rgba(73, 182, 255, 0.1);
  border-radius: 4px;
}
.buttonWithIconContainer:hover {
  background-color: rgba(73, 182, 255, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/Common/Components/ButtonWithIcon/ButtonWithIcon.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,yCAAA;EACA,kBAAA;AAFJ;AAGI;EACI,yCAAA;AADR","sourcesContent":["@import '../../../Common/Styles/colors.scss';\n@import '../../../Common/Styles/metrics.scss';\n\n.buttonWithIconContainer {\n    display: flex;\n    align-items: center;\n    width: max-content;\n    height: 24px;\n    padding: 0 7px;\n    background-color: rgba(73, 182, 255, 0.1);\n    border-radius: 4px;\n    &:hover {\n        background-color: rgba(73, 182, 255, 0.3);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
