import React, { useEffect, useState } from 'react'
import { SnakeChartData } from '../../data';
import "./ColorizeSnake.scss";

const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

interface IProps {

}

export const ColorizeSnake: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(SnakeChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(SnakeChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="snake-chart">
                    <defs>
                        <linearGradient id="GreenOpenConnectorGradient">
                            <stop offset="1%" stopColor="#bafd02" />
                            <stop offset="25%" stopColor="#5C8800" />
                        </linearGradient>
                    </defs>
                    <defs>
                        <linearGradient id="GreenCloseConnectorGradient">
                            <stop offset="80%" stopColor="#5C8800" />
                            <stop offset="100%" stopColor="#bafd02" />
                        </linearGradient>
                    </defs>
                    <defs>
                        <linearGradient id="RedOpenConnectorGradient">
                            <stop offset="1%" stopColor="#fcbe06" />
                            <stop offset="25%" stopColor="#B01C1C" />
                        </linearGradient>
                    </defs>
                    <defs>
                        <linearGradient id="RedCloseConnectorGradient">
                            <stop offset="80%" stopColor="#B01C1C" />
                            <stop offset="100%" stopColor="#fcbe06" />
                        </linearGradient>
                    </defs>

                    {SnakeChartData.map((item, index) => {
                        if (canvasHeight === 0) {
                            return;
                        }

                        const lowY = calculateY(item.low)
                        const highY = calculateY(item.high)
                        const openY = calculateY(item.open)
                        const closeY = calculateY(item.close)
                        const isGoingUp = openY < closeY
                        const bgColor = isGoingUp ? 'green' : 'red';

                        return (
                            <>
                                <rect className={`element connector open ${isGoingUp ? 'green' : 'red'}`} x={CANVAS_PADDING + index * CHART_OFFSET - 10} y={openY - 1} />
                                <rect className={`element candle ${bgColor}`} x={CANVAS_PADDING + index * CHART_OFFSET} y={lowY} height={highY - lowY} /> :
                                <rect className={`element connector close ${isGoingUp ? 'green' : 'red'}`} x={CANVAS_PADDING + index * CHART_OFFSET} y={closeY - 1} />

                            </>
                        )
                    })}
                </svg>
        </>
    )
}

ColorizeSnake.displayName = 'WhiteSnake'
