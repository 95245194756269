// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#appModalPopup {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
#appModalPopup .dialog-popup {
  border-radius: 12px;
  background: linear-gradient(254deg, rgba(0, 0, 0, 0.8) 0.81%, rgba(0, 0, 0, 0.56) 100%);
  box-shadow: 0px 0px 24px 0px #49b6ff;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 10px 20px;
}
#appModalPopup .dialog-popup .title {
  color: white;
  font-size: 22px;
  font-weight: 600;
}
#appModalPopup .dialog-popup .message {
  margin-top: 20px;
  font-size: 16px;
  color: white;
}
#appModalPopup .dialog-popup .buttons-container {
  display: flex;
  justify-content: end;
}
#appModalPopup .dialog-popup .buttons-container .apply {
  margin-top: 13px;
  background: #FDCB48;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  padding: 5px 12px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ModalPopup/styles.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACJ;AACI;EACI,mBAAA;EACA,uFAAA;EACA,oCAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;AACR;AACQ;EACI,YAAA;EACA,eAAA;EACA,gBAAA;AACZ;AAEQ;EACI,gBAAA;EACA,eAAA;EACA,YAAA;AAAZ;AAGQ;EACI,aAAA;EACA,oBAAA;AADZ;AAGY;EACI,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADhB","sourcesContent":["#appModalPopup {\n    position: fixed;\n    inset: 0;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 100;\n\n    .dialog-popup {\n        border-radius: 12px;\n        background: linear-gradient(254deg, rgba(0, 0, 0, 0.8) 0.81%, rgba(0, 0, 0, 0.56) 100%);\n        box-shadow: 0px 0px 24px 0px #49b6ff;\n        display: flex;\n        flex-direction: column;\n        width: 500px;\n        padding: 10px 20px;\n\n        .title {\n            color: white;\n            font-size: 22px;\n            font-weight: 600;\n        }\n\n        .message {\n            margin-top: 20px;\n            font-size: 16px;\n            color: white;\n        }\n\n        .buttons-container {\n            display: flex;\n            justify-content: end;\n\n            .apply {\n                margin-top: 13px;\n                background: #FDCB48;\n                border-radius: 4px;\n                cursor: pointer;\n                font-weight: 600;\n                padding: 5px 12px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
