import { Moment } from "moment";
import { DATE_FORMAT, SqlTimeFrame, SqlTimeSymbol, Strftime, TimeFrame, WINDOW_COUNT } from "../constants";
import { DateRange } from "../../../CandlesClient/models/Candle";

export const getStartDate = (candlesOnViewPort: number, endDate: Moment, timeFrame: TimeFrame, timeCount: number = 1): Moment => {
    const count = candlesOnViewPort * WINDOW_COUNT;
    const startDate = endDate.clone().subtract(count * timeCount, timeFrame);

    return startDate;
}

export const getShiftedCandlesDateRange = (direction: boolean, candlesCount: number, startDate: Moment, endDate: Moment, timeFrame: TimeFrame, timeCount: number): DateRange => {
    const count = candlesCount* timeCount;
    const sqlDateFrom = direction
        ? endDate.clone()
        : startDate.clone().subtract(count, timeFrame);
    const sqlDateTo = direction
        ? endDate.clone().add(count, timeFrame)
        : startDate.clone();

    return {
        sqlDateFrom,
        sqlDateTo,
        dateFrom: getChangedDate(direction, candlesCount, startDate, timeFrame),
        dateTo: getChangedDate(direction, candlesCount, endDate, timeFrame)

    }
}

export const getScaleCandlesDateRange = (candlesCount: number, candlesCountToChange: number, startDate: Moment, endDate: Moment, timeFrame: TimeFrame): DateRange => {
    let dif = (candlesCount - candlesCountToChange) / 2;
    const direction = candlesCountToChange > candlesCount;

    if (dif < 0) {
        dif *= -1;
    }

    console.log(dif, candlesCount, candlesCountToChange);

    const dateFrom = getChangedDate(!direction, dif, startDate, timeFrame);
    const dateTo = getChangedDate(direction, dif, endDate, timeFrame);

    return {
        dateFrom,
        dateTo,
        sqlDateFrom: dateFrom.clone(),
        sqlDateTo: dateTo.clone()
    }
}

export const getChangedDate = (direction: boolean, candlesCount: number, oldDate: Moment, timeFrame: TimeFrame): Moment => {
    return direction ? oldDate.clone().add(candlesCount, timeFrame) : oldDate.clone().subtract(candlesCount, timeFrame);
}

export const getSqlTimeFrame = (timeFrame: TimeFrame): SqlTimeFrame => {
    switch (timeFrame) {
        case TimeFrame.seconds:
            return SqlTimeFrame.seconds;
        case TimeFrame.minutes:
            return SqlTimeFrame.minutes;
        case TimeFrame.hours:
            return SqlTimeFrame.hours;
        case TimeFrame.days:
            return SqlTimeFrame.days;
        default:
            return SqlTimeFrame.minutes;
    }
}

export const getSqlTimeSymbol = (timeFrame: TimeFrame): SqlTimeSymbol => {
    switch (timeFrame) {
        case TimeFrame.seconds:
            return SqlTimeSymbol.seconds;
        case TimeFrame.minutes:
            return SqlTimeSymbol.minutes;
        case TimeFrame.hours:
            return SqlTimeSymbol.hours;
        case TimeFrame.days:
            return SqlTimeSymbol.days;
        default:
            return SqlTimeSymbol.minutes;
    }
}

export const getStrftime = (timeFrame: TimeFrame): Strftime => {
    switch (timeFrame) {
        case TimeFrame.seconds:
            return Strftime.seconds;
        case TimeFrame.minutes:
            return Strftime.minutes;
        case TimeFrame.hours:
            return Strftime.hours;
        case TimeFrame.days:
            return Strftime.days;
        default:
            return Strftime.minutes;
    }
}