import React, {useEffect, useRef, useState} from "react";

const CANVAS_HEIGHT = window.outerHeight/4;
const CANVAS_WIDTH = window.outerWidth;

const randomIntFromInterval = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

const AMOUNT = 10000

const REFRESH_RATE = 20



export const Canvas: React.FC = (props: any) => {
    const [data, setData] = useState<Array<{direction: boolean, candleHeight: number, startPosition: number}> >([])

    useEffect(() => {
        const tempData: Array<{direction: boolean, candleHeight: number, startPosition: number}> = []
        let startPosition = CANVAS_HEIGHT / 2
        for(let index = 0; index < AMOUNT - 1; index ++) {

            let candleHeight = randomIntFromInterval(0, 100)

            let direction = Boolean(randomIntFromInterval(0, 1))
            direction = startPosition - candleHeight < 0 ? false : (
                startPosition + candleHeight > CANVAS_HEIGHT ? true : direction
            )

            tempData.push({direction, candleHeight, startPosition})

            startPosition = direction ? startPosition - candleHeight : startPosition + candleHeight
        }

        setData(tempData)

    }, []);
    const canvasRef = useRef(null)

    const draw = (ctx: CanvasRenderingContext2D, frameCount: number, data: Array<{direction: boolean, candleHeight: number, startPosition: number}> ) => {

        if(frameCount >= AMOUNT || !data.length) {
            return
        }

        if(!(frameCount % (props.rate || REFRESH_RATE))) {

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

            const grad = ctx.createLinearGradient(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

            grad.addColorStop(0, "#020024");
            grad.addColorStop(0.35, "#090979");
            grad.addColorStop(1, "#00d4ff");

            ctx.fillStyle = grad;
            ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

            const STEP = props.size || 1;

            const drawCandle = (start: number, height: number, position: number, isUp: boolean) => {
                const grad = ctx.createLinearGradient(position * STEP, isUp ? start - height : start, (position + 1) * STEP, isUp ? start : start + height);

                if (isUp) {
                    grad.addColorStop(0, "lightgreen");
                    grad.addColorStop(1, "darkgreen");
                } else {
                    grad.addColorStop(0, "pink");
                    grad.addColorStop(1, "red");
                }

                ctx.beginPath();
                ctx.fillStyle = grad;
                ctx.fillRect(position * STEP, isUp ? start - height : start, STEP, height);
            }

            const indexShift = Math.floor(frameCount / (props.rate || REFRESH_RATE)) * 10


            for (let index = indexShift + 1; index < AMOUNT - 1; index++) {

                const {candleHeight, direction, startPosition: sp} = data[index]

                drawCandle(sp, candleHeight, index - indexShift, direction)
            }
        }

        ctx.fillStyle = 'red'
        ctx.beginPath()
        ctx.clearRect(0, 0, 50, 50)
        ctx.arc(25, 25, 20*Math.sin(frameCount*0.05)**2, 0, 2*Math.PI)
        ctx.fill();
    }


    useEffect(() => {

        const canvas = canvasRef.current
        const context = canvas && (canvas as HTMLCanvasElement).getContext('2d')
        let frameCount = 0
        let animationFrameId: number;

        //Our draw came here
        const render = () => {
            frameCount++
            const newPosition = context && draw(context, frameCount, data)
            animationFrameId = window.requestAnimationFrame(render)
        }

        render()

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [draw])

    return <canvas ref={canvasRef} {...props} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
}