import React from 'react';
import { useDispatch } from 'react-redux';
import { PrimeReactProvider } from 'primereact/api';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';

import { createRoutes } from "../../Routes/Routes";
import { NavigationMenu } from "../NavigationMenu/NavigationMenu";
import { HeaderMenu } from "../HeaderMenu/HeaderMenu";
import { useTypedSelector } from '../../Hooks/useTypedSelector';
import { RootState } from '../../Store/models';
import { toggleElementVisibility } from '../../Store/action-creators';
import { ChartsMenu } from '../ChartsMenu/ChartsMenu';

import './App.scss';
import { ModalPopup } from '../../Pages/ModalPopup';

function App() {
    const dispatch = useDispatch();
    const candlesVisibility = useTypedSelector((state: RootState) => state.app.elementsVisibility.candlesVisibility);
    const chartsVisibility = useTypedSelector((state: RootState) => state.app.elementsVisibility.chartsVisibility);
    const rightMenuVisibility = useTypedSelector((state: RootState) => state.app.elementsVisibility.rightMenuVisibility);
    const hotKeyCodes = useTypedSelector((state: RootState) => state.app.hotKeyCodes);

    onkeydown = (event) => {
        dispatch(toggleElementVisibility(event.key));
    };

    return (
        <PrimeReactProvider>
            <div className="app">
                <ModalPopup />
                <NavigationMenu />
                <div className={`app-container 
            ${chartsVisibility ? '' : 'hide-charts'} 
            ${candlesVisibility ? '' : 'hide-candles'}
            ${rightMenuVisibility ? '' : 'hide-right-menu'}`}>
                    <div className="headerMenu">
                        <HeaderMenu />

                    </div>
                    <div className="content">
                        {createRoutes()}
                    </div>
                </div>
                <ChartsMenu />
            </div>
        </PrimeReactProvider>
    );
}

export default App;
