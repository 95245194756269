// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instrumentPriceContainer {
  display: flex;
  flex-direction: column;
}
.instrumentPriceContainer .price, .instrumentPriceContainer .price-name {
  font-family: "Red Hat Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  color: #E7F5FF;
  white-space: nowrap;
}
.instrumentPriceContainer .price {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/Components/HeaderMenu/TradingConnections/InstrumentPriceCell/ConnectionPriceCell.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;AADJ;AAGI;EACI,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AADR;AAII;EACI,gBAAA;AAFR","sourcesContent":["@import '../../../../Common/Styles/colors.scss';\n\n.instrumentPriceContainer {\n    display: flex;\n    flex-direction: column;\n\n    .price, .price-name {\n        font-family: 'Red Hat Text';\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 11px;\n        color: $color-blue-08;\n        white-space: nowrap;\n    }\n\n    .price {\n        font-weight: 700;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
