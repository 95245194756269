import React, { useEffect, useState } from 'react'
import { japaneseCandleData, LineChartData, SnakeChartData } from '../../data';
import "./SquareCandle.scss";


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;
const LINE_CHART_WIDTH = 400;

interface IProps {

}

export const SquareCandle: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    useEffect(() => {
        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
          <svg className="candle-chart">
                    <defs>
                        <linearGradient id="candleVerticalGradientGreen" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="rgba(47, 119, 29,0)" />
                            <stop offset="100%" stopColor="rgba(47, 119, 29,0.525)" />
                        </linearGradient>
                    </defs>
                    <defs>
                        <linearGradient id="candleVerticalGradientRed" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="rgba(171, 70, 70, 0.525)" />
                            <stop offset="100%" stopColor="rgba(171, 70, 70, 0)" />
                        </linearGradient>
                    </defs>
                    {japaneseCandleData.map((item, index) => {
                        if (canvasHeight === 0) {
                            return;
                        }

                        const lowY = calculateY(item.low)
                        const highY = calculateY(item.high)
                        const openY = calculateY(item.open)
                        const closeY = calculateY(item.close)
                        const isGoingUp = openY < closeY;
                        const bgColor = isGoingUp ? 'green' : 'red';
                        const candleHeight = isGoingUp ? closeY - openY : openY - closeY;
                        const candleY = isGoingUp ? openY + CANVAS_PADDING : closeY + CANVAS_PADDING;
                        const highConnectorHeight = isGoingUp ? highY - closeY : highY - openY;
                        const lowConnectorHeight = isGoingUp ? openY - lowY : closeY - lowY;
                        const highConnectorY = isGoingUp ? closeY + CANVAS_PADDING : openY + CANVAS_PADDING;
                        const lowConnectorY = lowY + CANVAS_PADDING;

                        return (
                            <>
                                <rect className={`element connector`}
                                    fill="url(#candleVerticalGradientRed)"
                                    x={CANVAS_PADDING + index * CHART_OFFSET - 6}
                                    y={lowConnectorY}
                                    height={lowConnectorHeight + 5} />
                                <rect className={`element connector`}
                                    fill="url(#candleVerticalGradientGreen)"
                                    x={CANVAS_PADDING + index * CHART_OFFSET - 6}
                                    y={highConnectorY - 5}
                                    height={highConnectorHeight + 5} />

                                <rect className={`element candle bordered ${bgColor}`}
                                    x={CANVAS_PADDING + index * CHART_OFFSET - 6}
                                    y={candleY}
                                    height={candleHeight} />
                            </>
                        )
                    })}
                </svg>
        </>
    )
}

SquareCandle.displayName = 'BrightShadownCandle'