import React, { useEffect, useState } from 'react'
import { LineChartData } from '../../data';
import "./VolatilityLineChart.scss";


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;
const LINE_CHART_WIDTH = 400;

interface IProps {

}

export const VolatilityLineChart: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);

    let lineChartHighPath = "";
    let lineChartLowPath = "";
    let highLowChartArea = "";

    const hihgChartPointsX: number[] = [];
    const hihgChartPointsY: number[] = [];

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const isLastElement = i === LineChartData.length - 1;

        lineChartHighPath += openX + "," + highY + " ";
        lineChartLowPath += openX + "," + lowY + " ";

        hihgChartPointsX.push(openX);
        hihgChartPointsY.push(highY);

        if (isLastElement) {
            highLowChartArea = lineChartLowPath;

            do {
                const x = hihgChartPointsX.pop();
                const y = hihgChartPointsY.pop();
                highLowChartArea += x + "," + y + " ";
            } while (hihgChartPointsX.length > 0);

            lineChartHighPath = "M" + lineChartHighPath;
            lineChartLowPath = "M" + lineChartLowPath;
        }
    });

    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="line-chart">
                <defs>
                    <linearGradient id="verticalGradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="rgba(73, 182, 255, 0)" />
                        <stop offset="100%" stopColor=" rgba(73, 182, 255, 0.525)" />
                    </linearGradient>
                </defs>
                <svg className="line-chart">
                    <path d={lineChartHighPath} fill="none" stroke="#39AB1D" strokeWidth="2" />
                    <path d={lineChartLowPath} fill="none" stroke="#FF0000" strokeWidth="2" />
                    {highLowChartArea && <polygon fill="rgba(73, 182, 255, 0.15)" points={highLowChartArea}></polygon>}
                </svg>
            </svg>
        </>
    )
}

VolatilityLineChart.displayName = 'BlockLine'
