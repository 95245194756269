import React from "react";
import "./SnakeChart.scss";
import { CandleSize, UCandle } from "../../../CandlesClient/models/Candle";

export interface SnakeCandleChartProps {
    candles: UCandle[];
    candleSize: CandleSize;
}

export const SnakeCandleChart = (props: SnakeCandleChartProps) => {
    const { candles, candleSize } = props;

    if (candleSize == null) {
        return <></>;
    }

    return (
        <>
            <defs>
                <linearGradient id="GreenOpenConnectorGradient">
                    <stop offset="1%" stopColor="#bafd02" />
                    <stop offset="25%" stopColor="#5C8800" />
                </linearGradient>
            </defs>
            <defs>
                <linearGradient id="GreenCloseConnectorGradient">
                    <stop offset="80%" stopColor="#5C8800" />
                    <stop offset="100%" stopColor="#bafd02" />
                </linearGradient>
            </defs>
            <defs>
                <linearGradient id="RedOpenConnectorGradient">
                    <stop offset="1%" stopColor="#fcbe06" />
                    <stop offset="25%" stopColor="#B01C1C" />
                </linearGradient>
            </defs>
            <defs>
                <linearGradient id="RedCloseConnectorGradient">
                    <stop offset="80%" stopColor="#B01C1C" />
                    <stop offset="100%" stopColor="#fcbe06" />
                </linearGradient>
            </defs>
            {candles?.map((candle, index) => {
                const candleIndex = index; // ToDo: generate unique id for each candle and use it here

                return (
                    <React.Fragment key={candleIndex + 'snakeCandle'}>
                        <rect className={`connector open ${candle.direction}`}
                            width={candleSize.connectorWidth}
                            x={candle.openConnectorX}
                            y={candle.openY} />
                        <rect className={`candle ${candle.direction}`}
                            x={candle.snakeX}
                            y={candle.snakeY}
                            width={candleSize.snakeWidth}
                            height={candle.snakeHeight} />
                        <rect className={`connector close ${candle.direction}`}
                            width={candleSize.connectorWidth}
                            x={candle.closeConnectorX}
                            y={candle.closeY} />
                    </React.Fragment>
                )
            })}
        </>
    )
}