// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-chart {
  width: 300px;
  height: 240px;
  transform: scale(1, -1);
}
.line-chart .area {
  opacity: 0.5;
}
.line-chart .point {
  width: 6px;
  height: 6px;
  fill: white;
}
.line-chart .point.circle {
  rx: 3;
}
.line-chart .point.open {
  fill: rgb(253, 231, 37);
}
.line-chart .point.close {
  fill: rgb(73, 182, 255);
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/LineCharts/GradientUpLine/GradientUpLine.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EACI,YAAA;AAER;AAAI;EACI,UAAA;EACA,WAAA;EACA,WAAA;AAER;AADQ;EACI,KAAA;AAGZ;AADQ;EACI,uBAAA;AAGZ;AADS;EACG,uBAAA;AAGZ","sourcesContent":[".line-chart { \n    width: 300px; \n    height: 240px; \n    transform: scale(1, -1); \n    .area { \n        opacity: 0.5;\n    } \n    .point { \n        width: 6px; \n        height: 6px; \n        fill: white; \n        &.circle { \n            rx: 3; \n        } \n        &.open { \n            fill:rgb(253, 231, 37); \n        }\n         &.close { \n            fill:rgb(73, 182, 255); \n        } \n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
