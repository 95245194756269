import React from "react";
import { CandleSize, UCandle } from "../../../CandlesClient/models/Candle";
import "./JapaneeseCandleChart.scss";

export interface JapaneeseCandleChartProps {
    candles: UCandle[];
    candleSize: CandleSize
}

export const JapaneeseCandleChart = (props: JapaneeseCandleChartProps) => {
    const { candles, candleSize } = props;

    if (candleSize == null) {
        return <></>;
    }

    return (
        <>
            <defs>
                <linearGradient id="candleGradientUp" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(47, 119, 29,0.525)" />
                    <stop offset="100%" stopColor="rgba(47, 119, 29,0)" />
                </linearGradient>
                <linearGradient id="candleGradientDown" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(171, 70, 70, 0)" />
                    <stop offset="100%" stopColor="rgba(171, 70, 70, 0.525)" />
                </linearGradient>
                <linearGradient id="emptyCandleGradientUp" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(47, 119, 29, 0.5)" />
                    <stop offset="100%" stopColor="rgba(56, 78, 50, 0.5)" />
                </linearGradient>
                <linearGradient id="emptyCandleGradientDown" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="rgba(255, 0, 0, 0.5)" />
                    <stop offset="100%" stopColor="rgba(171, 70, 70, 0.5)" />
                </linearGradient>
            </defs>
            {candles?.map((candle, index) => {
                    const candleIndex = index;

                    return (
                        <React.Fragment key={candleIndex + 'candle'}>
                            <rect key={candleIndex + 'tailBottom'}
                                className='tail bottom'
                                style={{ width: `${candleSize.tailWidth}px` }}
                                x={candle.bodyyX}
                                y={candle.bottomTailY}
                                height={candle.bottomTailHeight} />
                            <rect key={candleIndex + 'tailTop'}
                                className='tail top'
                                style={{ width: `${candleSize.tailWidth}px` }}
                                x={candle.bodyyX}
                                y={candle.topTailY}
                                height={candle.topTailHeight} />
                            <rect key={candleIndex + 'body'}
                                className={`candle ${candle.direction}`}
                                style={{ width: `${candleSize.candleWidth}px` }}
                                x={candle.candleX}
                                y={candle.candleY}
                                height={candle.candleHeight} />
                        </React.Fragment>
                    )
            })}
        </>
    )
}