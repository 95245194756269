// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snake-chart {
  width: 220px;
  height: 240px;
  transform: scale(1, -1);
}
.snake-chart .element {
  fill: white;
}
.snake-chart .element.green {
  fill: #5C8800;
}
.snake-chart .element.red {
  fill: #B01C1C;
}
.snake-chart .connector {
  width: 16px;
  height: 6px;
}
.snake-chart .connector.open.green {
  fill: url(#GreenOpenConnectorGradient);
}
.snake-chart .connector.close.green {
  fill: url(#GreenCloseConnectorGradient);
}
.snake-chart .connector.open.red {
  fill: url(#RedOpenConnectorGradient);
}
.snake-chart .connector.close.red {
  fill: url(#RedCloseConnectorGradient);
}
.snake-chart .candle {
  width: 6px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/SnakeCharts/WhiteSnake/WhiteSnake.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,uBAAA;AACJ;AACI;EACI,WAAA;AACR;AACQ;EACI,aAAA;AACZ;AAEQ;EACI,aAAA;AAAZ;AAII;EACI,WAAA;EACA,WAAA;AAFR;AAIQ;EACI,sCAAA;AAFZ;AAKQ;EACI,uCAAA;AAHZ;AAKQ;EACI,oCAAA;AAHZ;AAMQ;EACI,qCAAA;AAJZ;AAQI;EACI,UAAA;AANR","sourcesContent":[".snake-chart {\n    width: 220px;\n    height: 240px;\n    transform: scale(1, -1);\n\n    .element {\n        fill: white;\n\n        &.green {\n            fill: #5C8800\n        }\n\n        &.red {\n            fill: #B01C1C\n        }\n    } \n\n    .connector {\n        width: 16px;\n        height: 6px;\n\n        &.open.green {\n            fill:url(#GreenOpenConnectorGradient);\n        }\n\n        &.close.green {\n            fill:url(#GreenCloseConnectorGradient);\n        }\n        &.open.red {\n            fill:url(#RedOpenConnectorGradient);\n        }\n\n        &.close.red {\n            fill:url(#RedCloseConnectorGradient);\n        }\n    }\n\n    .candle {\n        width: 6px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
