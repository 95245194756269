import React, { useEffect, useState } from 'react'
import { SnakeChartData } from '../../data';
import "./CircleSnake.scss";

const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

interface IProps {

}

export const CircleSnake: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);
    let additionalSpace = 0;
    const circleConnectorUp = "M0 0 C 0 8, 16 8, 16 0";
    const circleConnectorDown = "M0 16 C 0 8, 16 8, 16 16";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(SnakeChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(SnakeChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="snake-chart">
                {SnakeChartData.map((item, index) => {
                    if (canvasHeight === 0) {
                        return;
                    }

                    const lowY = calculateY(item.low)
                    const highY = calculateY(item.high)
                    const openY = calculateY(item.open)
                    const closeY = calculateY(item.close)
                    const isGoingUp = openY < closeY;
                    const openNotExtremum = openY !== highY && openY !== lowY;
                    const closeNotExtremum = closeY !== highY && closeY !== lowY;
                    const startConnectorGap = additionalSpace;
                    const startCandleGap = additionalSpace;
                    const middleCandleGap = openNotExtremum ? additionalSpace + CANDLE_OFFSET : additionalSpace;

                    const closeCandleGap = openNotExtremum ? additionalSpace + CANDLE_OFFSET * 2 : middleCandleGap + CANDLE_OFFSET;
                    const closeConnectorGap = closeNotExtremum ? closeCandleGap : middleCandleGap;
                    const closeConnectotY = isGoingUp ? highY + CANVAS_PADDING : lowY + CANVAS_PADDING - 15;
                    const closeConnectorPath = isGoingUp ? circleConnectorUp : circleConnectorDown;
                    const closeCandleHeight = isGoingUp ? highY - closeY : closeY - lowY;
                    const closeCandleY = isGoingUp ? closeY + CANVAS_PADDING : lowY + CANVAS_PADDING;
                    const bgColor = isGoingUp ? 'green' : 'red';


                    if (openNotExtremum) {
                        additionalSpace += SPACE_BEETFWEEN_CANDLES + CANDLE_WIDTH;
                    }

                    if (closeNotExtremum) {
                        additionalSpace += SPACE_BEETFWEEN_CANDLES + CANDLE_WIDTH;
                    }

                    return (
                        <>
                            <rect className={`element connector open ${bgColor}`}
                                x={startConnectorGap + CANVAS_PADDING + index * CHART_OFFSET - 10}
                                y={openY - 1 + CANVAS_PADDING} />
                            {openNotExtremum && <>
                                <rect className={`element candle ${bgColor}`}
                                    x={startCandleGap + CANVAS_PADDING + index * CHART_OFFSET}
                                    y={openY + CANVAS_PADDING}
                                    height={highY - openY} />
                                <svg x={startCandleGap + CANVAS_PADDING + index * CHART_OFFSET}
                                    y={highY + CANVAS_PADDING}><path d={circleConnectorUp} className={`element open ${bgColor}`} /></svg>
                            </>
                            }
                            <rect className={`element candle ${bgColor}`}
                                x={middleCandleGap + CANVAS_PADDING + index * CHART_OFFSET}
                                y={lowY + CANVAS_PADDING}
                                height={highY - lowY} />
                            {closeNotExtremum && <>
                                <rect className={`element candle ${bgColor}`}
                                    x={closeCandleGap + CANVAS_PADDING + index * CHART_OFFSET}
                                    y={closeCandleY}
                                    height={closeCandleHeight} />
                                <svg x={middleCandleGap + CANVAS_PADDING + index * CHART_OFFSET}
                                    y={closeConnectotY}><path d={closeConnectorPath} className={`element open ${bgColor}`} /></svg>
                            </>}

                            <rect className={`element connector close ${bgColor}`}
                                x={closeConnectorGap + CANVAS_PADDING + index * CHART_OFFSET}
                                y={closeY - 1 + CANVAS_PADDING} />

                        </>
                    )
                })}
            </svg>
        </>
    )
}

CircleSnake.displayName = 'WhiteSnake'
