import React from 'react';
import "./ToggleButton.scss";
import cn from "classnames";

interface IButtonItemProps {
    id: string;
    label: string;
    path?: string;
}

/**
 * Компонент Кнопка.
 * @prop value Активная секция.
 * @prop items Массив объектов секций кнопки.
 * @prop [onClick] Обработчик нажатия на секцию.
 */

export enum ToggleButtonSize {
    Large = 'large',
    Medium = 'medium',
    Small = 'Small'

}
interface IButtonProp {
    value: IButtonItemProps;
    items: IButtonItemProps[];
    onClick: (id: IButtonItemProps) => void;
    size?: ToggleButtonSize
}

export const ToggleButton: React.FC<IButtonProp> = (props: IButtonProp) => {
    const {value, items, onClick, size} = props;

    const renderButtonSection = () => {
        return items.map(item => {

            const sectionClassNames = cn({
                option: true,
                active: value.label === item.label,
            })

            return (
                <div className={sectionClassNames} onClick={() => onClick(item)}>
                    <span className="label">{item.label}</span>
                </div>
            )
        });
    }

    return (
        <div className={"toggle-button-container " + size}>
            {renderButtonSection()}
        </div>
    )
}

ToggleButton.displayName = 'ToggleButton';
