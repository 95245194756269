import React from 'react'
import { CandleSize, ClusterViewType, UCandle } from '../../../CandlesClient/models/Candle';
import "./ClusterChart.scss";
import { getClusterViewType } from '../../Candles/helper';

export interface ClusterChartProps {
    candles: UCandle[];
    scaleIndex: number,
    candleSize: CandleSize
}

export const ClusterChart: React.FC<ClusterChartProps> = (props: ClusterChartProps) => {
    const { candles, scaleIndex, candleSize } = props;
    const viewType = getClusterViewType(scaleIndex);

    return (
        <>
            {/* <filter x="-0.1" y="0.3" width="1.2" height="0.6" id="redCluster">
                <feFlood flood-color="#ff0000"></feFlood>
                <feComposite in="SourceGraphic" operator="xor"></feComposite>
            </filter>
            <filter x="-0.1" y="0.3" width="1.2" height="0.6" id="greenCluster">
                <feFlood flood-color="#39ab1d"></feFlood>
                <feComposite in="SourceGraphic" operator="xor"></feComposite>
            </filter>
            <filter x="-0.1" y="0.3" width="1.2" height="0.6" id="outsideCluster">
                <feFlood flood-color="#123248"></feFlood>
                <feComposite in="SourceGraphic" operator="xor"></feComposite>
            </filter> */}
            {candles?.map((candle, index) => {
                const candleIndex = index; // ToDo: generate unique id for each candle and use it here
                return (
                    <svg className='cluster' key={candleIndex + 'candle'}>
                        {candle.clusterElements?.map((element, elementIndex) => {
                            if (element.value == null) {
                                return;
                            }
                            
                            const bgFilter = "";
                            // element.insideBody ? candle.isGoingUp ? "url(#greenCluster)" : "url(#redCluster)" : "url(#outsideCluster)";
                            const bgColor = element.insideBody ? candle.isGoingUp ? "#2665B2" : "#B22424" : "#123248";

                            return (
                                <g className='cluster-container' key={candleIndex + '_' + elementIndex + 'container'}>
                                    <rect className="indicator"
                                        filter={bgFilter}
                                        fill={bgColor}
                                        height={candleSize.clusterHeight}
                                        x={element.counterPositionX - 6}
                                        y={element.counterPositionY} />
                                    {viewType == ClusterViewType.single &&
                                        <rect className='segment'
                                            style={{ opacity: element.opacityCoef }}
                                            fill={element.Segment?.color}
                                            height={candleSize.clusterHeight}
                                            key={candleIndex + '_' + elementIndex + 'singleSegment'}
                                            x={element.counterPositionX + 17}
                                            y={element.counterPositionY}
                                            width={element.Segment?.width} />
                                    }

                                    {viewType == ClusterViewType.multi &&
                                        <>
                                            {
                                                element.AskSegments?.map((askElement, askIndex) => {
                                                    return (
                                                        <rect className='ask segment'
                                                            fill={askElement.color}
                                                            height={candleSize.clusterHeight}
                                                            key={candleIndex + '_' + askIndex + 'askSegment'}
                                                            x={askElement.positionX}
                                                            y={element.counterPositionY}
                                                            width={askElement.width} />
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                    <text className="counter"
                                        filter={bgFilter}
                                        x={element.counterPositionX}
                                        y={element.counterPositionY + 8}>
                                        {element.value}
                                    </text>
                                </g>
                            )
                        })}
                    </svg>
                )
            })}
        </>
    )
}