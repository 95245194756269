// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-chart {
  width: 300px;
  height: 300px;
  padding: 40px;
}
.profile-chart .claster {
  height: 22px;
}
.profile-chart .counter {
  font-weight: 500;
  fill: white;
  font-size: 14px;
  transform: none;
  fill: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/ProfileCharts/Solid/SolidProfileChart.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,aAAA;EACA,aAAA;AAAJ;AAEI;EACI,YAAA;AAAR;AAGI;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;AADR","sourcesContent":["\n.profile-chart {\n    width: 300px;\n    height: 300px; \n    padding: 40px;\n    \n    .claster {\n        height: 22px;\n    }\n\n    .counter {\n        font-weight: 500;\n        fill:white;\n        font-size: 14px;\n        transform: none;\n        fill: white;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
