import React from "react";

import {Tile} from "./Tile";

import './ChartSeparator.scss'

export const ChartSeparator: React.FC = () => {

    return (
        <div id="chart-separator">
            <Tile root />
        </div>
    )
}