import { Extremums, UCandle, ZoomExtremums } from "../../../CandlesClient/models/Candle";
import { SettingsValue } from "../../Demo/settingsData";
import { CANDLE_OFFSET, CANVAS_HEIGHT } from "../constants";
import { calculateY, getScrolledCanlesCount } from "../helper";

export const getZoomExtremums = (lowestPoint: number, highestPoint: number, viewportHeight: number, scrollTop: number,
    direction: boolean, latestPrice: number, lowestPrice: number, highestPrice: number, selectedPriceScaleSetting: SettingsValue) => {

    switch (selectedPriceScaleSetting) {
        case SettingsValue.PriceDefault:
            return getDefaultExtremums(direction, lowestPoint, highestPoint, scrollTop);
        case SettingsValue.LastPrice:
            return getExtremumsPerLastPrice(direction, lowestPoint, highestPoint, latestPrice, viewportHeight);

        case SettingsValue.MidlePrice:
            return getCenteredExtremums(direction, lowestPrice, highestPrice, lowestPoint, highestPoint, viewportHeight);

    }

    return getDefaultExtremums(direction, lowestPoint, highestPoint, scrollTop);
}

const getDefaultExtremums = (direction: boolean, lowestPoint: number, highestPoint: number, scrollTop: number): ZoomExtremums => {
    const dif = highestPoint - lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas

    return {
        newLowestPoint: direction ? lowestPoint + valueToChange : lowestPoint - valueToChange,
        newHighestPoint: direction ? highestPoint - valueToChange : highestPoint + valueToChange,
        newScrollTop: scrollTop
    }
}

const getExtremumsPerLastPrice = (direction: boolean, lowestPoint: number, highestPoint: number, latestPrice: number, viewportHeight: number): ZoomExtremums => {
    const dif = highestPoint - lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas
    const topValue = latestPrice + dif / 2;
    const bottomValue = latestPrice - dif / 2;

    return {
        newLowestPoint: direction ? bottomValue + valueToChange : bottomValue - valueToChange,
        newHighestPoint: direction ? topValue - valueToChange : topValue + valueToChange,
        newScrollTop: CANVAS_HEIGHT / 2 - viewportHeight / 2
    }
}

const getCenteredExtremums = (direction: boolean, lowestPrice: number, highestPrice: number, lowestPoint: number, highestPoint: number, viewportHeight: number): ZoomExtremums => {
    const dif = highestPoint - lowestPoint;
    const valueToChange = dif / 25; // 4 % size of canvas
    const midlePrice = highestPrice - ((highestPrice - lowestPrice) / 2);
    const topValue = midlePrice + dif / 2;
    const bottomValue = midlePrice - dif / 2;

    return {
        newLowestPoint: direction ? bottomValue + valueToChange : bottomValue - valueToChange,
        newHighestPoint: direction ? topValue - valueToChange : topValue + valueToChange,
        newScrollTop: CANVAS_HEIGHT / 2 - viewportHeight / 2
    }
}

export const getFloatExtremums = (candles: UCandle[], viewportHeight: number): Extremums => {
    if(candles == null || candles.length === 0) {
        return {
            lowestPrice: 0,
            highestPrice: 0,
            lowestPoint: 0,
            highestPoint: 0,
            scrollTop: 0
        }
    }
    //@ts-ignore
    let lowestPrice = candles.hasMin("low").low;
    //@ts-ignore
    let highestPrice = candles.hasMax("high").high;

    const dif = highestPrice - lowestPrice;
    const viewportCanvasPoint = viewportHeight / dif;
    const gap = (CANVAS_HEIGHT - viewportHeight) / 2 / viewportCanvasPoint;

    let highestPoint = highestPrice + gap;
    let lowestPoint = lowestPrice - gap;

    const pixelsPerpoint = viewportHeight / dif;
    const scrollTop = pixelsPerpoint * (highestPoint - highestPrice);

    return {
        lowestPrice,
        highestPrice,
        lowestPoint,
        highestPoint,
        scrollTop
    }
}

export const checkAvailableExtremums = (candleIn: UCandle, candleOut: UCandle, highest: number, lowest: number): boolean => {
    const dif = (highest - lowest) / 3;
    const high = highest - dif;
    const low = lowest + dif;

    return candleOut?.high >= high || candleOut?.low <= low || candleIn?.high >= high || candleIn?.low <= low;
}

export const getStaticExtremums = (candles: UCandle[], viewPortWidth: number, scaleIndex: number, scrollLeft: number, viewportHeight: number, spaceGap: number): Extremums => {
    const candlesCountOnViewPort = viewPortWidth / (CANDLE_OFFSET * scaleIndex);
    const scrolledCandlesCount = getScrolledCanlesCount(scrollLeft - spaceGap, scaleIndex);
    const startIndex = Math.round(scrolledCandlesCount);
    const endIndex = Math.round(startIndex + candlesCountOnViewPort);
    const candlesToCalculate = candles.slice(startIndex, endIndex);

    //@ts-ignore
    let lowestPrice = candlesToCalculate.hasMin("low").low;
    //@ts-ignore
    let highestPrice = candlesToCalculate.hasMax("high").high;

    const dif = highestPrice - lowestPrice;
    const viewportCanvasPoint = viewportHeight / dif;
    const gap = (CANVAS_HEIGHT - viewportHeight) / 2 / viewportCanvasPoint;

    let highestPoint = highestPrice + gap;
    let lowestPoint = lowestPrice - gap;

    console.log(lowestPrice, highestPrice, highestPoint, lowestPoint);

    const pixelsPerpoint = viewportHeight / dif;
    const scrollTop = pixelsPerpoint * (highestPoint - highestPrice);

    return {
        lowestPrice,
        highestPrice,
        lowestPoint,
        highestPoint,
        scrollTop
    }
}