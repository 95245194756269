import React from 'react';
import "./Switch.scss";
import { useTranslation } from "react-i18next";
import cn from "classnames";


/**
 * Компонент переключатель.
 * @prop isChecked Флаг включенного переключателя.
 * @prop onClick Обработчик нажатия.
 * @prop [isActive] Флаг активного переключателя.
 */
interface IProps {
    isChecked: boolean;
    titleOn?: string;
    titleOff?: string
    onClick?: () => void;
    isActive?: boolean;
}

export const Switch: React.FC<IProps> = (props: IProps) => {
    const { isChecked, titleOn, titleOff, onClick } = props;

    const { t } = useTranslation();
    const descriptionOn = titleOn || t('Common:Common.Components.Switch.labels.isChecked');
    const descriptionOff = titleOff || t('Common:Common.Components.Switch.labels.isUnChecked');

    const title = isChecked ? descriptionOn : descriptionOff;

    const switchClassNames = cn({
        switchContainer: true,
        checkedSwitch: isChecked,
    })

    return (
        <div className={switchClassNames} onClick={onClick}>
            <div className="switchDot"></div>
            <div className="switchTitle">{title}</div>
        </div>
    );
}

Switch.displayName = 'Switch';
