import React, { LegacyRef, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";

import { SearchInput } from '../../Common/Components/SearchInput/SearchInput';
import { ExitIcon } from "../../Common/Components/Icons/ExitIcon/ExitIcon";
import { graphTemplatesMock, instrumentsColumns, instrumentsListMock, instrumentsTagsMock, timeRangesMock } from "../../__mocks__/instruments";
import { InstrumentInfoCell } from "../../Components/InstrumentsManager/InstrumentInfoCell/InstrumentInfoCell";
import { InstrumentPriceCell } from "../../Components/InstrumentsManager/InstrumentPriceCell/InstrumentPriceCell";
import { Switch } from '../../Common/Components/Switch/Switch';
import { Tag } from "../../Common/Components/Tag/Tag";
import { ETagSize } from "../../Common/Components/Tag/enums";
import { IGraphTemplateType, IInstrumentTag, IInstrumentTagSubGroup } from "../../Models/models";
import { Button } from "../../Common/Components/Button/Button";
import { ButtonWithIcon } from "../../Common/Components/ButtonWithIcon/ButtonWithIcon";
import AnalyticsInManagerIcon from "../../Common/Components/Icons/ButtonIcons/AnalyticsInManagerIcon.svg";
import { Favorite } from "../../Common/Components/Favorite/Favorite";
import { calcFullInstrumentNameLowerCase } from "../../Modules/Trading/utils";
import { ToggleButton } from "../../Common/Components/ToggleButton/ToggleButton";
import { Select } from "../../Common/Components/Select/Select";
import { ESelectSize } from "../../Common/Components/Select/enums";

import "./InstrumentsManager.scss";
import { ISelectItem } from "../../Common/Components/Select/models";

const toggleButtons = [
    {
        id: '1',
        label: 'Search'
    },
    {
        id: '2',
        label: 'Favorite'
    }
]

const getTagsList = () => {
    const resultArray: any = [];
    instrumentsTagsMock.forEach(item => item.subSections.forEach(group => group.tags.forEach((tag, index) => {
        resultArray.push({
            id: `${index}${tag.label}`,
            label: tag.label,
            checked: tag.checked,
            groupTitle: item.title
        })
    })))

    return resultArray;
}

/**
 * Компонент переключатель.
 * @prop onClick Флаг отображения менеджера инструментов.
 */
interface IProps {
    onClick: () => void;
}

/* Менеджер финансовых инструментов.*/
export const InstrumentsManager: React.FC<IProps> = (props: IProps) => {
    const { onClick } = props;
    const { t } = useTranslation();

    const [tagsList, setTagsList] = useState<any[]>(getTagsList())
    const [filterByName, setFilterByName] = useState('');
    const [filterByTag, setFilterByTag] = useState<string[]>([])
    const [tabValue, setTabValue] = useState(toggleButtons[0])
    const [graphTemplate, setGraphTemplate] = useState<IGraphTemplateType>(graphTemplatesMock[0])
    const [timeRange, setTimeRange] = useState<IGraphTemplateType>(timeRangesMock[0])

    const handleSelectTemplate = useCallback((selectedValue: IGraphTemplateType | ISelectItem) => {
        setGraphTemplate(selectedValue as IGraphTemplateType)
    }, [])

    const handleSelectTimeRange = useCallback((selectedValue: IGraphTemplateType | ISelectItem) => {
        setTimeRange(selectedValue as IGraphTemplateType)
    }, [])

    const handleTabSelect = () => {
        return (selectedTab: { id: string, label: string }) => setTabValue(selectedTab)
    }
    const handleTagClick = (label: string) => {
        return () => setTagsList(tagsList.map(obj => {
            if (obj.label === label) {
                return { ...obj, checked: !obj.checked };
            } else {
                return obj;
            }
        }));
    }

    useEffect(() => {
        const checkedTags: string[] = [];
        tagsList.forEach(item => {
            if (item.checked) {
                checkedTags.push(item.label);
            }
        })
        setFilterByTag(checkedTags)
    }, [tagsList])

    const unSelectAllTagsInGroup = (groupTitle: string) => {
        return () => setTagsList(tagsList.map(obj => {
            if (obj.groupTitle === groupTitle) {
                return { ...obj, checked: false };
            } else {
                return obj;
            }
        }));
    }

    const renderTags = (tags: IInstrumentTag[]) => {
        return tags.map(tag => {
            const checkedTag = tagsList.find(item => item.label === tag.label)?.checked
            return (
                <div key={tag.label} className="tag">
                    <Tag label={tag.label} size={ETagSize.MD} checked={checkedTag} onClick={handleTagClick(tag.label)} />

                </div>
            )
        })
    }

    const renderTagsSubGroups = (tagSubGroups: IInstrumentTagSubGroup[]) => {
        return tagSubGroups.map(subGroup => {
            return (
                <div className="tagsSubGroup" key={subGroup.tags[0].label}>
                    {subGroup.subTitle && (
                        <div key={subGroup.subTitle} className="tagsSubGroupTitle">
                            {subGroup.subTitle}
                        </div>
                    )}
                    <div className="tags">
                        {renderTags(subGroup.tags)}
                    </div>
                </div>
            )
        })
    }

    const renderTagsGroups = () => {
        return instrumentsTagsMock.map(tagGroup => {
            const selectedGroupTags = tagsList.find(item => item.groupTitle === tagGroup.title && item.checked)

            return (
                <div className="tagsGroup" key={tagGroup.title}>
                    <div className="tagsGroupTitle">
                        <span>{tagGroup.title}</span>
                        <Tag onClick={unSelectAllTagsInGroup(tagGroup.title)} label={t('Components:Components.InstrumentsManager.tags.all')} size={ETagSize.MD} checked={!selectedGroupTags} />
                    </div>
                    {renderTagsSubGroups(tagGroup.subSections)}
                </div>
            )
        })
    }

    const renderInstrumentList = () => {
        return (
            <table className='instrument-grid'>
                <thead>
                    <tr>
                        {instrumentsColumns.map((head, id) => (
                            <th key={id}>{head.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {instrumentsListMock.map((item, id) => {
                        const isFilterByNameValidated = !filterByName.length
                            || calcFullInstrumentNameLowerCase(item.name, item.fullName).includes(filterByName.toLowerCase())
                        const isFilterByTagValidated = !filterByTag.length || filterByTag.includes(item.exchangeName);

                        if (isFilterByNameValidated) {
                            if (isFilterByTagValidated) {
                                return (
                                    <tr key={id}>
                                        <td>
                                            <Favorite checked={true} />
                                        </td>
                                        <td className='name-cell'>
                                            <div className='name-container'>
                                                <div className='title-container'>
                                                    <span className="name">{item.name}</span>
                                                    <Tag size={ETagSize.SM} label={item.exchangeName} disable />
                                                </div>
                                                <span className="fullName">{item.fullName}</span>
                                            </div>

                                        </td>
                                        <td>
                                            <InstrumentPriceCell price={item.volume} diff={item.volumeDiff} />
                                        </td>
                                        <td>
                                            <InstrumentPriceCell price={item.price} diff={item.diff} />
                                        </td>
                                        <td>
                                            <span className="expDate">{item.expDate}</span>
                                        </td>
                                        <td>
                                            <Select items={timeRangesMock} size={ESelectSize.SM} value={timeRange} onSelect={handleSelectTimeRange} />
                                        </td>
                                        <td>
                                            <Select items={graphTemplatesMock} size={ESelectSize.LG} value={graphTemplate} onSelect={handleSelectTemplate} />
                                        </td>
                                        <td>
                                            <Switch isChecked={true} onClick={() => console.log('___---_-_!!!_-_---___ click')} />
                                        </td>
                                        <td>
                                            <ButtonWithIcon icon={<img src={AnalyticsInManagerIcon} alt="Перейти в аналитику" />} />

                                        </td>
                                        <td>
                                            <Button label={'OPEN'} />

                                        </td>
                                    </tr>
                                )
                            }
                        }
                    })}
                </tbody>
            </table>
        )
    }

    const handleInputChange = useCallback((value: string) => {
        setFilterByName(value)
    }, [])

    return (
        <div className="instrumentsManagerContainer">
            <div className="header">
                <ToggleButton value={tabValue} items={toggleButtons} onClick={handleTabSelect()} />
                <SearchInput text={filterByName} onChange={handleInputChange} />
                <ExitIcon onClose={onClick} />
            </div>
            <div className="content">
                <div className="tagsArea">
                    {renderTagsGroups()}
                </div>
                <div className="list">
                    <div className="listContent">
                        {renderInstrumentList()}
                    </div>
                </div>
            </div>
        </div>
    );
}

InstrumentsManager.displayName = 'InstrumentPriceCell';
