// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#chart-separator {
  position: absolute;
  z-index: 11;
  right: 0;
  width: 700px;
  height: 300px;
  left: 450px;
  min-height: 65px;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  background-color: rgba(13, 24, 36, 0.7);
  border: 1px solid white;
  border-radius: 5px;
  align-items: stretch;
}
#chart-separator .tile {
  flex: 1 1;
  border-radius: 10px;
  background-color: #01283c;
}`, "",{"version":3,"sources":["webpack://./src/Components/HeaderMenu/ChartSeparator/ChartSeparator.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,2BAAA;EACA,aAAA;EACA,uCAAA;EACA,uBAAA;EACA,kBAAA;EACA,oBAAA;AAAF;AAEE;EACE,SAAA;EACA,mBAAA;EACA,yBAAA;AAAJ","sourcesContent":["\n#chart-separator {\n  position: absolute;\n  z-index: 11;\n  right: 0;\n  width: 700px;\n  height: 300px;\n  left: 450px;\n  min-height: 65px;\n  display: flex;\n  justify-content: flex-start;\n  padding: 15px;\n  background-color: rgba(13, 24, 36, 0.7);\n  border: 1px solid white;\n  border-radius: 5px;\n  align-items: stretch;\n\n  .tile {\n    flex: 1;\n    border-radius: 10px;\n    background-color: #01283c;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
