import React, {useState} from "react";
import { ColorPicker as PRColorPicker } from 'primereact/colorpicker';

import './ColorPicker.scss'
import {ChangeEventHandler} from "react/index";

export const ColorPicker: React.FC = () => {
    const [color, setColor] = useState<string>('FFFFFF')

    const onTextChange:ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value

        if(value.length !== 3 && value.length !== 6) {
            return
        }

        setColor(value)
    }
    return (
        <div className="color-picker-container">
            <PRColorPicker onChange={(e) => setColor(e.value as string)} inputClassName={'input-cp'} value={color} />
            <input type="text" className="color-value" maxLength={6} defaultValue={color} onChange={onTextChange} />
        </div>
    )
}