import React from "react";

import {NumberSelect} from "../../../Inputs/NumberSelect";

import './AutoStopTake.scss'

interface OwnProps {
    percentRisk: number
    statsValue: number
    take?: boolean
    stop?: boolean
}

export const AutoStopTake: React.FC<OwnProps> = ({stop, take, percentRisk, statsValue}) => {
    const isTake = take && !stop;
    const label = isTake ? "Take Profit" : "Stop Loss"

    return (
        <div className="auto-stop-take">
            <NumberSelect
                options={[
                    {value: '24', label:'24'},
                ]}
                numberButtons
                label={label}
                unitLabel="ticks"
            />
            <div className="stats-block">
                <div>
                    <label className="control-label">{statsValue}</label>
                    <label className="control-label unit-label">USDT</label>
                </div>

                <div>
                    <label className="control-label">{percentRisk}% <label className="control-label unit-label">risk</label></label>
                    <label className="control-label unit-label">of deposit</label>
                </div>
            </div>
        </div>
    )
}