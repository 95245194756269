import React from 'react';

import "./Button.scss";

interface OwnProps {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
}

export const Button: React.FC<OwnProps> = (({label, onClick}) =>
    <div className="buttonContainer" onClick={onClick}>
        <span className="label">{label}</span>
    </div>
)

