import axios from "axios";

export class UserProfile {
    static signUp = (email: string, password: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/register',
            { email, password }
        )

    static signIn = (email: string, password: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/login',
            { email, password }
        )

    static rasswordReset = (email: string) =>
        axios.post<{ message: string }>(process.env.REACT_APP_BE_HOST + '/user/email/reset',
            { email }
        )
}