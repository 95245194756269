export const LineChartData = [
    {
        open: 30,
        close: 35,
        high: 40,
        low: 25,
    },
    {
        open: 35,
        close: 20,
        high: 40,
        low: 15,
    },
    {
        open: 20,
        close: 25,
        high: 30,
        low: 11,
    },
    {
        open: 25,
        close: 22,
        high: 35,
        low: 12,
    },
    {
        open: 22,
        close: 45,
        high: 50,
        low: 15,
    },
    {
        open: 45,
        close: 30,
        high: 55,
        low: 35,
    },
    {
        open: 30,
        close: 65,
        high: 70,
        low: 25,
    },
    {
        open: 65,
        close: 55,
        high: 78,
        low: 40,
    },
    {
        open: 55,
        close: 80,
        high: 90,
        low: 50,
    },

    {
        open: 80,
        close: 90,
        high: 95,
        low: 70,
    },
];

export const SnakeChartData = [
    {
        open: 80,
        close: 50,
        high: 100,
        low: 40,
        time: '2022-04-24T19:01:00Z'
    },
    {
        open: 50,
        close: 20,
        high: 70,
        low: 10,
        time: '2022-04-24T19:01:00Z'
    },
    {
        open: 20,
        close: 30,
        high: 85,
        low: 20,
        time: '2022-04-24T19:01:00Z'
    },
    {
        open: 30,
        close: 60,
        high: 60,
        low: 30,
        time: '2022-04-24T19:01:00Z'
    },
    {
        open: 60,
        close: 50,
        high: 80,
        low: 50,
        time: '2022-04-24T19:01:00Z'
    }
]

export const ClasterCandleData = [
    { ask: 48, bid: 15, inside: false },
    { ask: 101, bid: 150, inside: true },
    { ask: 180, bid: 13, inside: true },
    { ask: 48, bid: 51, inside: true },
    { ask: 54, bid: 222, inside: true },
    { ask: 22, bid: 5, inside: true },
    { ask: 12, bid: 0, inside: true },
    { ask: 243, bid: 186, inside: false },
];

export const japaneseCandleData = [
    {
        open: 22,
        close: 45,
        high: 50,
        low: 15,
    },
    {
        open: 45,
        close: 30,
        high: 55,
        low: 25,
    },
    {
        open: 30,
        close: 65,
        high: 70,
        low: 25,
    },
];