import React, { memo, useEffect, useRef, useState} from 'react';

import FavoriteIcon from './Favorite.svg';
import FavoriteIconHovered from './Favorite_hovered.svg';
import FavoriteIconChecked from './Favorite_checked.svg';

/**
 * Иконка избранного.
 * @prop checked Флаг активной иконки.
 */
interface IFavoriteProps {
    checked: boolean;
}

export const Favorite: React.FC<IFavoriteProps> = memo((props: IFavoriteProps) => {
    const {checked} = props;

    const [isHovered, setIsHovered] = useState<boolean>(false)
    const ref = useRef<any>(null);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener("mouseover", handleMouseOver);
            node.addEventListener("mouseout", handleMouseOut);
            return () => {
                node.removeEventListener("mouseover", handleMouseOver);
                node.removeEventListener("mouseout", handleMouseOut);
            };
        }
    }, [ref.current]);

    const renderIcon = () => isHovered ? <img src={FavoriteIconHovered} alt=""/> : checked
        ?  <img src={FavoriteIconChecked} alt=""/> : <img src={FavoriteIcon} alt=""/>

    return (
        <div ref={ref}>
            {renderIcon()}
        </div>
    );
});

Favorite.displayName = 'Favorite';
