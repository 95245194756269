// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auto-stop-take {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.auto-stop-take select.number-select.with-buttons {
  min-width: 70px;
}
.auto-stop-take .stats-block {
  gap: 10px;
  display: flex;
  flex-direction: column;
}
.auto-stop-take .stats-block > div > .control-label {
  display: block;
}
.auto-stop-take .stats-block .control-label {
  margin-right: 0;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/Components/Panels/TradingPanel/AutoStopTake/AutoStopTake.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EAEE,SAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAGI;EACE,cAAA;AADN;AAGI;EACE,eAAA;EACA,iBAAA;AADN","sourcesContent":[".auto-stop-take {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n\n  select.number-select.with-buttons {\n    min-width: 70px;\n  }\n\n  .stats-block {\n\n    gap: 10px;\n    display: flex;\n    flex-direction: column;\n\n    & > div > .control-label {\n      display: block;\n    }\n    .control-label {\n      margin-right: 0;\n      text-align: right;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
