import React from 'react';
import cn from "classnames";
import {useLocation, useNavigate} from "react-router-dom";

import {SoonSticker} from "../SoonSticker/SoonSticker";

import "./NavButton.scss";



/**
 * @prop label
 */
interface IProps {
    label: string;
    pathToGo?: string;
    icon: JSX.Element|JSX.Element[];
    isDisabled?: boolean;
    onClick?: () => void;
}

export const NavButton: React.FC<IProps> = (props: IProps) => {

    const {label, pathToGo, onClick, icon, isDisabled = false} = props;

    const location = useLocation();
    const navigate = useNavigate();

    const goToRoute = () => {
        if(!isDisabled && pathToGo) {
            navigate(pathToGo);
        }

        onClick && onClick();
    }

    const renderSoonSticker = () => {
        return isDisabled && (
            <div className="sticker">
                <SoonSticker />
            </div>
        )
    }

    const classNames = cn({
        'navbutton-container': true,
        currentRoute: location.pathname === pathToGo,
        enableForHover: !isDisabled && location.pathname !== pathToGo
    })

    return (
        <div className={classNames} onClick={goToRoute || onClick}>
            <div className="icon">
                {icon}
                {renderSoonSticker()}
            </div>
            <span className="button-label">{label}</span>
        </div>
    );
}

NavButton.displayName = 'NavButton';
