import React from 'react'
import "./CandleMenu.scss";
import LineChartType from "../../Common/Components/Icons/ButtonIcons/LineChartType.svg";
import CandleChartType from "../../Common/Components/Icons/ButtonIcons/CandleChartType.svg";
import BoldCandleType from "../../Common/Components/Icons/ButtonIcons/BoldCandleType.svg";
import NormalCandleType from "../../Common/Components/Icons/ButtonIcons/NormalCandleType.svg";
import EmptyCandleType from "../../Common/Components/Icons/ButtonIcons/EmptyCandleType.svg";

import TableChart from "../../Common/Components/Icons/ButtonIcons/TableChart.svg";
import EmptyTableChart from "../../Common/Components/Icons/ButtonIcons/EmptyTableChart.svg";
import RightTableChart from "../../Common/Components/Icons/ButtonIcons/RightTableChart.svg";
import useComponentVisible from '../../Hooks/useComponentVisible';
import { SimpleLine } from '../Charts/LineCharts/SimpleLine/SimpleLine';
import { BlockLine } from '../Charts/LineCharts/BlockLine/BlockLine';
import { SplitLine } from '../Charts/LineCharts/SplitLine/SplitLine';
import { GradientDownLine } from '../Charts/LineCharts/GradientDownLine/GradientDownLine';
import { GradientUpLine } from '../Charts/LineCharts/GradientUpLine/GradientUpLine';
import { VolatilityLineChart } from '../Charts/LineCharts/VolatilityLineChart/VolatilityLineChart';
// import { ReversalLine } from '../Charts/LineCharts/ReversalLine/ReversalLine';
import { WhiteSnake } from '../Charts/SnakeCharts/WhiteSnake/WhiteSnake';
import { ColorizeSnake } from '../Charts/SnakeCharts/ColorizeSnake/ColorizeSnake';
import { CircleSnake } from '../Charts/SnakeCharts/CircleSnake/CircleSnake';
import { RoundHistogram } from '../Charts/ClusterCharts/RoundHistogram/RoundHistogram';
import { ClassicHistogram } from '../Charts/ClusterCharts/ClassicHistogram/ClassicHistogram';
import { TableCluster } from '../Charts/ClusterCharts/TableCluster/TableCluster';
import { TableBlockCluster } from '../Charts/ClusterCharts/TableBlockCluster/TableBlockCluster';
import { ClassicProfileChart } from '../Charts/ProfileCharts/Classic/ClassicProfileChart';
import { ModernProfileChart } from '../Charts/ProfileCharts/Modern/ModernProfileChart';
import { SolidProfileChart } from '../Charts/ProfileCharts/Solid/SolidProfileChart';
import { StunnerCandle } from '../Charts/JapaneseClassicCandles/StunnerCandle/StunnerCandle';
import { PoorCandle } from '../Charts/JapaneseClassicCandles/PoorCandle/PoorCandle';
import { ShadownCandle } from '../Charts/JapaneseClassicCandles/ShadownCandle/ShadownCandle';
import { StunnerTransparentCandle } from '../Charts/JapaneseClassicCandles/StunnerTransparentCandle/StunnerTransparentCandle';
import { PoorTransparentCandle } from '../Charts/JapaneseClassicCandles/PoorTransparentCandle/PoorTransparentCandle';
import { SmoothCandle } from '../Charts/JapaneseClassicCandles/SmoothCandle/SmoothCandle';
import { SquareCandle } from '../Charts/JapaneseClassicCandles/SquareCandle/SquareCandle';
import { BrightShadowCandle } from '../Charts/JapaneseClassicCandles/BrightShadowCandle/BrightShadowCandle';
import { BrightCandle } from '../Charts/JapaneseClassicCandles/BrightCandle/BrightCandle';
import { BackLightBrightCandle } from '../Charts/JapaneseClassicCandles/BackLightBrightCandle/BackLightBrightCandle';
import { selectChartTypeItem } from '../../Store/action-creators';
import { ChartStyle } from '../../Store/models';
import { useDispatch } from 'react-redux';

interface IProps {

}

export const CandleMenu: React.FC<IProps> = ({ }: IProps) => {
    const dispatch = useDispatch();
    const lineChartMenu = useComponentVisible(false);
    const snakeChartMenu = useComponentVisible(false);
    const clusterChartMenu = useComponentVisible(false);
    const blockСlusterChartMenu = useComponentVisible(false);
    const profileChartMenu = useComponentVisible(false);
    const poorCandleMenu = useComponentVisible(false);
    const transparentCandleMenu = useComponentVisible(false);
    const wideTailCandleMenu = useComponentVisible(false);

    const handleClickCandlesMenu = () => {
        lineChartMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClickSnakeMenu = () => {
        snakeChartMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClickClusterMenu = () => {
        clusterChartMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClickBlockClusterMenu = () => {
        blockСlusterChartMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClickProfileChartMenu = () => {
        profileChartMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClickPoorCandleMenu = () => {
        poorCandleMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClicktransparentCandleMenu = () => {
        transparentCandleMenu.setIsComponentVisible(prev => !prev)
    }

    const handleClickWideTailCandleMenu = () => {
        wideTailCandleMenu.setIsComponentVisible(prev => !prev)
    }

    const selectChartType = (style: ChartStyle) => {
        dispatch(selectChartTypeItem(style));
    }

    return (
        <>
            <div className="candle-menu-container">
                <div className='group'>
                    <img className='type' src={LineChartType} onClick={handleClickCandlesMenu} />
                    <img className='type' src={CandleChartType} onClick={handleClickSnakeMenu} />
                </div>
                <div className='group'>
                    <img className='type' src={BoldCandleType} onClick={handleClickWideTailCandleMenu}/>
                    <img className='type' src={NormalCandleType} onClick={handleClickPoorCandleMenu} />
                    <img className='type' src={EmptyCandleType} onClick={handleClicktransparentCandleMenu}/>
                </div>
                {/* <div className='group'>
                    <img className='type' src={TableChart} onClick={handleClickClusterMenu} />
                    <img className='type' src={EmptyTableChart} onClick={handleClickBlockClusterMenu}  />
                    <img className='type' src={RightTableChart} onClick={handleClickProfileChartMenu}/>
                </div> */}

            </div>

            {lineChartMenu.isComponentVisible && <div className='charts-container below-panel' ref={lineChartMenu.componentRef}>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineSimple)} > <SimpleLine /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineBlock)}> <BlockLine /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineSplit)}> <SplitLine /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineGradientDown)}> <GradientDownLine /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineGradientUp)}> <GradientUpLine /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineVolatility)}> <VolatilityLineChart /></div>
                {/* <div className='chart-container' onClick={() => selectChartType(ChartStyle.lineReversal)}> <ReversalLine /></div> */}
            </div>}


            {snakeChartMenu.isComponentVisible && <div className='charts-container below-panel' ref={snakeChartMenu.componentRef}>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.snakeWhite)}> <WhiteSnake /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.snakeColorize)}> <ColorizeSnake /></div>
                <div className='chart-container circle' onClick={() => selectChartType(ChartStyle.snakeCircle)}> <CircleSnake /></div>
            </div>}

            {clusterChartMenu.isComponentVisible && <div className='charts-container below-panel' ref={clusterChartMenu.componentRef}>
                <div className='chart-container'  onClick={() => selectChartType(ChartStyle.clusterBlock)}> <RoundHistogram /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.clusterBlock)}> <ClassicHistogram /></div>
            </div>}

            {blockСlusterChartMenu.isComponentVisible && <div className='charts-container below-panel' ref={blockСlusterChartMenu.componentRef}>
                <div className='chart-container'  onClick={() => selectChartType(ChartStyle.clusterBlock)}> <TableCluster /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.clusterBlock)}> <TableBlockCluster /></div>
            </div>}

            {profileChartMenu.isComponentVisible && <div className='charts-container below-panel' ref={profileChartMenu.componentRef}>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.clusterTable)}> <ClassicProfileChart/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.clusterTable)}> <ModernProfileChart/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.clusterTable)}> <SolidProfileChart/></div>
            </div>}

            {poorCandleMenu.isComponentVisible && <div className='charts-container below-panel' ref={poorCandleMenu.componentRef}>
                <div className='chart-container'  onClick={() => selectChartType(ChartStyle.japanesePoor)}> <PoorCandle /></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseStunner)}> <StunnerCandle /></div>
            </div>}

            {transparentCandleMenu.isComponentVisible && <div className='charts-container below-panel' ref={transparentCandleMenu.componentRef}>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japanesePoorTransparent)}> <PoorTransparentCandle/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseStunnerTransparent)}> <StunnerTransparentCandle/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseShadow)}> <ShadownCandle/></div>
            </div>}

            {wideTailCandleMenu.isComponentVisible && <div className='charts-container below-panel' ref={wideTailCandleMenu.componentRef}>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseSmooth)}> <SmoothCandle/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseSquare)}> <SquareCandle/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseBrightShadow)}> <BrightShadowCandle/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseBright)}> <BrightCandle/></div>
                <div className='chart-container' onClick={() => selectChartType(ChartStyle.japaneseBlackLight)}> <BackLightBrightCandle/></div>
            </div>}

        </>
    )
}

CandleMenu.displayName = 'ChartMenu'
