import React from "react";
import {OptionHTMLAttributes} from "react";

import {Switcher} from "../../Switcher";

import './NumberSelect.scss'
import cn from "classnames";

interface OwnProps {
    options: Array<OptionHTMLAttributes<HTMLOptionElement>>,
    numberButtons?: boolean,
    label?: string,
    unitLabel?: string,
    inline?: boolean
}

export const NumberSelect: React.FC<OwnProps> = ({
                                                     options,
                                                     numberButtons,
                                                     label,
                                                     unitLabel,
                                                     inline
}) => {
    return (
        <div className={"number-select-container " + cn({inline})}>
            {label && (
                <div className="label-container">
                    <label className="control-label">{label}</label>
                    {unitLabel && (<label className="control-label unit-label">{unitLabel}</label>)}

                </div>
            )}
            <div className="number-select-controls">
                <select className={"number-select " + (numberButtons && 'with-buttons')}>
                    {options.map(option => (<option {...option}/>))}
                </select>
                {numberButtons && <Switcher options={["+", "-"]} vertical />}
            </div>
        </div>
    )
}