// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech-analysis-menu-container {
  position: absolute;
  z-index: 11;
  right: 0;
  left: 110px;
  min-height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  background-color: rgba(13, 24, 36, 0.7);
}
.tech-analysis-menu-container .group {
  display: inline-flex;
  margin-right: 45px;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 10px 15px;
  border-radius: 4px;
}
.tech-analysis-menu-container .description {
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
}
.tech-analysis-menu-container .type {
  margin-right: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/TechnicalAnalysisMenu/TechnicalAnalysisMenu.scss","webpack://./src/Common/Styles/metrics.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,WCLoB;EDMpB,gBAAA;EACA,aAAA;EACA,2BAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,uCAAA;AADJ;AAGI;EACI,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,qCAAA;EACA,kBAAA;EACA,kBAAA;AADR;AAII;EACI,YAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAFR;AAKI;EACI,kBAAA;EACA,eAAA;AAHR","sourcesContent":["@import '../../Common/Styles/metrics.scss';\n\n.tech-analysis-menu-container {\n    position: absolute;\n    z-index: 11;\n    right: 0;\n    left: $navigation-menu-width;\n    min-height: 65px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-start;\n    padding-top: 15px;\n    padding-left: 15px;\n    padding-bottom: 15px;\n    background-color: rgba(13, 24, 36, 0.7);\n   \n    .group {\n        display: inline-flex;\n        margin-right: 45px;\n        align-items: center;\n        background-color: rgba(0, 0, 0, 0.25);\n        padding: 10px 15px;\n        border-radius: 4px;\n    }\n\n    .description {\n        color: white;\n        font-weight: 500;\n        font-size: 12px;\n        margin-right: 10px;\n    }\n\n    .type {\n        margin-right: 10px;\n        cursor: pointer;\n    }\n}","// common component metrics\n$navigation-menu-width: 110px;\n$header-menu-height: 40px;\n$cluster-bar-width: 37px;\n\n$btn-border-radius: 4px;\n$btn-border-radius-big: 10px;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
