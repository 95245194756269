import React, { memo } from 'react';
import "./InstrumentPriceCell.scss";
import cn from "classnames";

/**
 * Компонент переключатель.
 * @prop name Флаг включенного переключателя.
 * @prop fullName Обработчик нажатия.
 */
interface IProps {
    price: string;
    diff: string;
}

export const InstrumentPriceCell: React.FC<IProps> = memo((props: IProps) => {
    const {price, diff} = props;

    const diffClassNames = cn({
        diff: true,
        positiveDiff: diff[0] === '+',
        negativeDiff: diff[0] === '-'
    })

    return (
        <div className="instrumentPriceContainer">
            <span className="price">{price}</span>
            <span className={diffClassNames}>{diff}</span>
        </div>
    );
})

InstrumentPriceCell.displayName = 'InstrumentInfoCell';
