import { IGraphTemplateType, IInstrumentTagGroup, ITimeRangeType } from "../Models/models";

export const instrumentsColumns = [
    {
        id: 9,
        name: 'favorite',
        title: ''
    },
    {
        id: 1,
        title: 'Instrument'
    },
    {
        id: 2,
        title: 'Vol (d.)'
    },
    {
        id: 3,
        title: 'Price (w.)'
    },
    {
        id: 4,
        title: 'Exp.'
    },
    {
        id: 5,
        title: 'Chart'
    },
    {
        id: 6,
        title: 'Template'
    },
    {
        id: 7,
        title: 'Quant chart'
    },
    {
        id: 8,
        title: 'Analyt.'
    },
    {
        id: 10,
        name: 'open',
        title: ''
    }

];

export const instrumentsListMock = [
    {
        id: 1,
        name: 'EU',
        fullName: 'Euro-Ruble Futures (current contract)',
        exchangeName: 'MOEX',
        price: '1910.12',
        diff: '-10.6%',
        expDate: '10.11.21',
        volume: '499',
        volumeDiff: '+120%'
    },
    {
        id: 2,
        name: 'RUB',
        fullName: 'Ruble-USD Futures (current contract)',
        exchangeName: 'MOEX',
        price: '1010.12',
        diff: '+10.6%',
        expDate: '02.01.22',
        volume: '499',
        volumeDiff: '+120%'
    },
    {
        id: 3,
        name: 'USD',
        fullName: 'USD-RUB Futures (current contract)',
        exchangeName: 'NYSE',
        price: '1110.12',
        diff: '+0.6%',
        expDate: '10.09.23',
        volume: '499',
        volumeDiff: '+120%'
    },
    {
        id: 4,
        name: 'USD',
        fullName: 'USD-EUR Futures (current contract)',
        exchangeName: 'LSE',
        price: '11.12',
        diff: '-3.6%',
        expDate: '11.11.21',
        volume: '499',
        volumeDiff: '+120%'
    }
]

export const instrumentsTagsMock: IInstrumentTagGroup[] = [
    {
        title: 'Type instrument:',
        subSections: [
            {
                tags: [
                    {
                        label: 'Stock',
                        checked: false
                    },
                    {
                        label: 'Futures',
                        checked: false
                    },
                    {
                        label: 'Forex',
                        checked: false
                    },
                    {
                        label: 'Crypto',
                        checked: false
                    },
                    {
                        label: 'Index',
                        checked: false
                    },
                    {
                        label: 'Bond',
                        checked: false
                    },
                    {
                        label: 'Option',
                        checked: false
                    },

                ]
            }
        ]
    },
    {
        title: 'Sources:',
        subSections: [
            {
                subTitle: 'North America',
                tags: [
                    {
                        label: 'NASDAQ',
                        checked: false
                    },
                    {
                        label: 'NYSE',
                        checked: false
                    },
                    {
                        label: 'OTC',
                        checked: false
                    },
                    {
                        label: 'COMEX',
                        checked: false
                    },
                    {
                        label: 'NYMEX',
                        checked: false
                    },
                    {
                        label: 'DJ',
                        checked: false
                    },
                    {
                        label: 'Arca',
                        checked: false
                    },
                    {
                        label: 'ICEUS',
                        checked: false
                    },
                    {
                        label: 'FairX',
                        checked: false
                    },
                    {
                        label: 'SP',
                        checked: false
                    },
                    {
                        label: 'CBOE',
                        checked: false
                    },
                    {
                        label: 'CBOT',
                        checked: false
                    },
                    {
                        label: 'CME GLOBEX',
                        checked: false
                    },
                    {
                        label: 'CSE',
                        checked: false
                    },
                    {
                        label: 'NEO',
                        checked: false
                    },
                    {
                        label: 'TSX',
                        checked: false
                    },
                    {
                        label: 'TSXV',
                        checked: false
                    },
                ]
            },
            {
                subTitle: 'Europe',
                tags: [
                    {
                        label: 'LSE',
                        checked: false
                    },
                    {
                        label: 'LSIN',
                        checked: false
                    },
                    {
                        label: 'BET',
                        checked: false
                    },
                    {
                        label: 'GPW',
                        checked: false
                    },
                    {
                        label: 'EUREX',
                        checked: false
                    },
                    {
                        label: 'LUXSE',
                        checked: false
                    },
                    {
                        label: 'CBOE EU',
                        checked: false
                    },
                    {
                        label: 'MOEX',
                        checked: false
                    },
                    {
                        label: 'MIL',
                        checked: false
                    },
                    {
                        label: 'IL',
                        checked: false
                    },
                    {
                        label: 'ML',
                        checked: false
                    },
                    {
                        label: 'MI',
                        checked: false
                    },
                    {
                        label: 'MEL',
                        checked: false
                    },
                    {
                        label: 'EL',
                        checked: false
                    },
                    {
                        label: 'ME',
                        checked: false
                    },
                ]
            }
        ]
    }
]

export const graphTemplatesMock: IGraphTemplateType[] = [
    {
        id: '1',
        value: 'Template 1'
    },
    {
        id: '2',
        value: 'Template 2'
    },
    {
        id: '3',
        value: 'Template 3'
    },
    {
        id: '4',
        value: 'Template 4'
    },
    {
        id: '5',
        value: 'Template 5'
    },
    {
        id: '6',
        value: 'Template 36'
    },
    {
        id: '7',
        value: 'Template 226'
    },
    {
        id: '8',
        value: 'Template 16'
    },
    {
        id: '9',
        value: 'Template 26'
    },
    {
        id: '20',
        value: 'Template 123 123'
    },
    {
        id: '202',
        value: 'Template2 123 123'
    },
    {
        id: '203',
        value: 'Template412 123 123'
    },
    {
        id: '201',
        value: 'Template11 123 123'
    }
]

export const timeRangesMock: ITimeRangeType[] = [
    {
        id: '1',
        value: 'S1'
    },
    {
        id: '12',
        value: 'S5'
    },
    {
        id: '13',
        value: 'S15'
    },
    {
        id: '14',
        value: 'S30'
    },
    {
        id: '15',
        value: 'M1'
    },
    {
        id: '16',
        value: 'M5'
    },
    {
        id: '17',
        value: 'M15'
    },
    {
        id: '18',
        value: 'M30'
    },
    {
        id: '19',
        value: 'H1'
    },
    {
        id: '10',
        value: 'H3'
    },
    {
        id: '165',
        value: 'H6'
    },
    {
        id: '123',
        value: 'H12'
    },
    {
        id: '134',
        value: 'D1'
    },
    {
        id: '145',
        value: 'D3'
    },
    {
        id: '156',
        value: 'D30'
    },
    {
        id: '167',
        value: 'W1'
    },

]
