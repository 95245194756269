import React, {useState} from "react";
import cn from "classnames";

import './Switcher.scss'

interface OptionWithId {
    id?: number|string,
    name: string
}
interface OwnProps {
    options: Array<OptionWithId> | string[]
    className?: string
    defaultOption?: number|string,
    vertical?: boolean;
    wrap?: boolean;
}

export const Switcher: React.FC<OwnProps> = ({
                                                 options,
                                                 defaultOption,
                                                 vertical,
                                                 wrap,
                                                 className,
}) => {
    const [selected, setSelected] = useState<number|string|undefined>(defaultOption)

    return (
        <div className={cn({ wrap,[className as string]: Boolean(className)}) + " button-switcher " + (vertical ? "vertical" : "horizontal")}>
            {options.map( item => {
                const name = (item as OptionWithId)?.name || (item as string)
                const id = (item as OptionWithId)?.id || (item as string|number)

                return (
                    <button className={selected === id ? "golden-button": "blue-button"} onClick={() => {setSelected(id)}}>{name}</button>
                )
            })}
        </div>
    )
}