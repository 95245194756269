import React from 'react';
import {useTranslation} from "react-i18next";

import  "./Logo.scss";

export const Logo: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div className="logo-container">
            <span className='label'>{t('Common:Common.Components.Logo.label')}</span>
        </div>
    );
}

Logo.displayName = 'Logo';
