import React, { useEffect, useState } from 'react'
import { LineChartData } from '../../data';
import "./BlockLine.scss";


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

interface IProps {

}

export const BlockLine: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);

    let lineCornerChartPath = "M";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;

        lineCornerChartPath += openX + "," + openY + " ";

        if (i != LineChartData.length - 1) {
            lineCornerChartPath += openX + "," + closeY + " ";
            lineCornerChartPath += closeX + "," + closeY + " ";
        }
    });

    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="line-chart">
                    <path d={lineCornerChartPath} fill="none" stroke="#49B6FF" strokeWidth="2" />
                    {LineChartData.map((item, index) => {
                        if (canvasHeight === 0) {
                            return;
                        }


                        const openY = calculateY(item.open) - 3;
                        const openX = index * CHART_OFFSET - 3;
                        const closeY = calculateY(item.close)


                        return (
                            <>
                                <rect className="point" x={openX} y={openY} />

                            </>
                        )
                    })}
                </svg>
        </>
    )
}

BlockLine.displayName = 'BlockLine'
