
export enum SettingsValue {
    Zoom = 'Zoom',
    Scroll = 'Scroll',

    Cross = 'Cross',

    SizeDefault = 'SizeDefault',

    Dashed = 'Dashed',

    ColorDefault = 'ColorDefault',

    Medium = 'Medium',

    Visible = 'Visible',
    Hidden = 'Hidden',
    OnHover = 'OnHover',

    PriceDefault = 'PriceDefault',
    MidlePrice = 'MidlePrice',
    LastPrice = 'LastPrice'
}

export interface ChartSettings {
    id: number;
    l10nKey?: string;
    value?: SettingsValue;
    childs?: ChartSettings[];
    title?: string; 
    parentId?: number;
}

export const ChartSettingsData: ChartSettings[] = [
    {
        id: 0,
        l10nKey: 'Components:Components.CursorSettings.Wheel',
        childs: [
            {
                id: 1,
                value: SettingsValue.Zoom,
                l10nKey: 'Components:Components.CursorSettings.WheelSettings.Zoom',
                parentId: 0,
            },
            {
                id: 2,
                value: SettingsValue.Scroll,
                l10nKey: 'Components:Components.CursorSettings.WheelSettings.Scroll',
                parentId: 0,
            },
        ]
    },
   {
        id: 1,
        l10nKey: 'Components:Components.CursorSettings.Style',
        childs: [
            {
                id: 101,
                value: SettingsValue.Cross,
                l10nKey: 'Components:Components.CursorSettings.StyleSettings.Cross',
                parentId: 1,
            }
        ]
    },
    {
        id: 2,
        l10nKey: 'Components:Components.CursorSettings.Size',
        childs: [
            {
                id: 201,
                value: SettingsValue.SizeDefault,
                l10nKey: 'Components:Components.CursorSettings.SizeSettings.Default',
                parentId: 2
            }
        ]
    },
    {
        id: 3,
        l10nKey: 'Components:Components.CursorSettings.Lines',
        childs: [
            {
                id: 301,
                value: SettingsValue.Dashed,
                l10nKey: 'Components:Components.CursorSettings.LinesSettings.Dashed',
                parentId: 3,
            }
        ]
    },
    {
        id: 4,
        l10nKey: 'Components:Components.CursorSettings.LineWidth',
        childs: [
            {
                id: 401,
                value: SettingsValue.Medium,
                title: '2px',
                parentId: 4
            }
        ]
    },
    {
        id: 5,
        l10nKey: 'Components:Components.CursorSettings.Color',
        childs: [
            {
                id: 501,
                value: SettingsValue.ColorDefault,
                l10nKey: 'Components:Components.CursorSettings.ColorSettings.Default',
                parentId: 5
            }
        ]
    },
    {
        id: 6,
        l10nKey: 'Components:Components.CursorSettings.PriceScale',
        childs: [
            {
                id: 601,
                value: SettingsValue.PriceDefault,
                l10nKey: 'Components:Components.CursorSettings.PriceScaleSettings.Default',
                parentId: 6,
            },
            {
                id: 602,
                value: SettingsValue.MidlePrice,
                l10nKey: 'Components:Components.CursorSettings.PriceScaleSettings.MidlePrice',
                parentId: 6,
            },
            {
                id: 603,
                value: SettingsValue.LastPrice,
                l10nKey: 'Components:Components.CursorSettings.PriceScaleSettings.LastPrice',
                parentId: 6,
            }
        ]
    },
    {
        id: 7,
        l10nKey: 'Components:Components.CursorSettings.ClusterPrice',
        childs: [
            {
                id: 701,
                value: SettingsValue.Visible,
                l10nKey: 'Components:Components.CursorSettings.ClusterPriceSettings.Visible',
                parentId: 7,
            },
            {
                id: 702,
                value: SettingsValue.Hidden,
                l10nKey: 'Components:Components.CursorSettings.ClusterPriceSettings.Hidden',
                parentId: 7,
            },
            {
                id: 703,
                value: SettingsValue.OnHover,
                l10nKey: 'Components:Components.CursorSettings.ClusterPriceSettings.OnHover',
                parentId: 7,
            }
        ]
    }
]



