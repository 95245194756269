import {TFinancialOptionsAction, TFinancialOptionsState} from "../models";
import {EFinancialActions} from "../enums";

const initialFinancialState: TFinancialOptionsState = {
    terminalStockSymbol: 'IBM'
}

export const financialReducer = (state = initialFinancialState, action: TFinancialOptionsAction): TFinancialOptionsState => {
    switch (action.type) {
        case EFinancialActions.CHANGE_TERMINAL_INSTRUMENT:
            return {
                ...state,
                terminalStockSymbol: action.payload
            }
        default:
            return state;
    }
}
