import { rootReducer } from "./reducers";
import {EAppActions, EFinancialActions, EInstrumentManagerActions} from "./enums";
import { Candle } from "../CandlesClient/models/Candle";
import { CHART_TYPES_ID } from "../Components/consts";
import { TimeFrame } from "../Modules/Candles/constants";

export interface Dictionary<T> {
    [Key: string]: T;
}

export type RootState = ReturnType<typeof rootReducer>

export interface TAppState {
    lightTheme: boolean;
    modalPopupData: PopupData | null;
    hotKeyCodes: HotKeyCodes;
    selectedMenuItem: string | null;
    selectedChartFrame: CHART_TYPES_ID;
    candles: Candle[];
    candleStartIndex: number;
    mainChart: MainChart;
}

export interface MainChart {
    scaleIndex: number;
    customScale: boolean; //user change scale by mouse wheel (not selected in menu)
    selectedChartStyles: Dictionary<ChartStyle>;
    activeChartStyle: ChartStyle;
    timeCount: number;
    timeFrame: TimeFrame;
    settings: ChartSettings;
}

export interface HotKeyCodes {
    toggleCandleVisibility: string;
    toggleChartVisibility: string;
    toggleRightMenuVisibility: string;
}

export interface PopupData {
    title: PopupTitle;
    message: string;
}

export type TAppAction = {
    type: EAppActions
    payload?: number;
}


export type AddNewCandleAction = {
    type: EAppActions
    payload: Candle;
}

export type MenuItemAction = {
    type: EAppActions
    payload: string | null;
}

export type CandnleMenuItemAction = {
    type: EAppActions;
    payload: ChartStyle;
}

export type ChartScaleAction = {
    type: EAppActions;
    payload: number;
}

export type ChartTypeFrameAction = {
    type: EAppActions;
    payload: CHART_TYPES_ID;
}

export type ModalPopupAction = {
    type: EAppActions;
    payload: PopupData | null;
}

export type ChangeChartSettingAction = {
    type: EAppActions;
    payload: SettingsValue;
}

export interface TFinancialOptionsState {
    terminalStockSymbol: 'NQM' | 'IBM';
}

export type TFinancialOptionsAction = {
    type: keyof typeof EFinancialActions,
    payload: 'NQM' | 'IBM'
}

export interface TInstrumentManagerState {
    instrumentManagerIsOpen: boolean;
}

export type TInstrumentManagerActions = {
    type: EInstrumentManagerActions,
    payload: boolean
}

export enum SettingsValue {
    Zoom = 'Zoom',
    Scroll = 'Scroll',

    Cross = 'Cross',

    SizeDefault = 'SizeDefault',

    Dashed = 'Dashed',

    ColorDefault = 'ColorDefault',

    Medium = 'Medium',

    Visible = 'Visible',
    Hidden = 'Hidden',
    OnHover = 'OnHover',

    PriceDefault = 'PriceDefault',
    MidlePrice = 'MidlePrice',
    LastPrice = 'LastPrice'
}

export interface ChartSettings {
    wheel: SettingsValue;
    priceScale: SettingsValue;
    clusterPrice: SettingsValue;
}

export enum ChartStyle {
    japaneseBlackLight='japaneseBlackLight',
    japaneseBright='japaneseBright',
    japaneseBrightShadow='japaneseBrightShadow',
    japanesePoor='japanesePoor',
    japanesePoorTransparent='japanesePoorTransparent',
    japaneseShadow='japaneseShadow',
    japaneseSmooth='japaneseSmooth',
    japaneseSquare='japaneseSquare',
    japaneseStunner='japaneseStunner',
    japaneseStunnerTransparent='japaneseStunnerTransparent',

    clusterTable='clusterTable',
    clusterBlock='clusterBlock',

    lineBlock='lineBlock',
    lineGradientDown='lineGradientDown',
    lineGradientUp='lineGradientUp',
    lineReversal='lineReversal',
    lineSimple='lineSimple',
    lineSplit='lineSplit',
    lineVolatility='lineVolatility',

    snakeWhite='snakeWhite',
    snakeColorize='snakeColorize',
    snakeCircle='snakeCircle'
}

export enum PopupTitle {
    notification='Notification',
    error='Error',
    warning='Warning'
}