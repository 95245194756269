import React, {memo} from 'react';

import {Tag} from "../../../../Common/Components/Tag/Tag";
import { ETagSize } from '../../../../Common/Components/Tag/enums';

import "./ConnectionInfoCell.scss";

interface OwnProps {
    name: string;
    subName: string;
    exchangeName: string;
}

export const ConnectionInfoCell: React.FC<OwnProps> = memo(({name, subName, exchangeName}) => (
    <div className="ConnectionInfoCellContainer">
        <div className="nameInfo">
            <span className="name">{name}<Tag size={ETagSize.SM} label={exchangeName} /></span>
            <span className="fullName">{subName}</span>
        </div>

    </div>
));
