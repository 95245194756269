import React, { useEffect, useState } from 'react'
import { ClasterCandleData, LineChartData } from '../../data';
import "./TableBlockCluster.scss";

//@ts-ignore
Array.prototype.hasMin = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] < curr[attrib] ? prev : curr;
    })) || null;
}

//@ts-ignore
Array.prototype.hasMax = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] > curr[attrib] ? prev : curr;
    })) || null;
}

export interface Claster {
    ask: number;
    bid: number;
    inside: boolean;
}


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

const getClasterBorderPath = (data: Claster[], startX: number, endX: number, elementHeight: number): string => {
    let path = "M"
    let startPoitionY: number | null = null;
    let endPositionY: number | null = null;

    data.forEach((element, index) => {
        if (element.inside && startPoitionY == null) {
            startPoitionY = index * elementHeight;
        }

        if (!element.inside && startPoitionY != null) {
            endPositionY = index * elementHeight;
        }
    })

    path +=
        + startX + "," + startPoitionY + " "
        + endX + "," + startPoitionY + " "
        + endX + "," + endPositionY + " "
        + startX + "," + endPositionY + " ";

    return path;
}

const getClasterWrapperPath = (data: Claster[], startX: number, endX: number, elementHeight: number, all?: boolean): string => {
    let path = ""
    let startPoitionY: number | null = null;
    let endPositionY: number | null = null;

    data.forEach((element, index) => {
        if (element.inside && startPoitionY == null) {
            startPoitionY = index * elementHeight;
        }

        if (!element.inside && startPoitionY != null) {
            endPositionY = index * elementHeight;
        }
    })

    if (all) {
        startPoitionY = 0;
        endPositionY = data.length * elementHeight;
    }

    path +=
        + startX + "," + startPoitionY + " "
        + endX + "," + startPoitionY + " "
        + endX + "," + endPositionY + " "
        + startX + "," + endPositionY + " "
        + startX + "," + startPoitionY + " ";

    return path;
}

interface IProps {

}

export const TableBlockCluster: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);
    const [highestClaster, setHighestClaster] = useState(0);

    let lineChartPath = "M";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;

        lineChartPath += openX + "," + openY + " ";
    });


    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);
        // @ts-ignore
        const highestAsk = ClasterCandleData.hasMax("ask").ask;
        // @ts-ignore
        const highestBid = ClasterCandleData.hasMax("bid").bid;
        setHighestClaster(highestAsk > highestBid ? highestAsk : highestBid);

        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
             <svg className="block-table-claster-candle">
                    <linearGradient id="verticalClasterGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="rgba(47, 119, 29, 0.9)" />
                        <stop offset="100%" stopColor=" rgba(47, 119, 29, 0.2)" />
                    </linearGradient>
                    <polygon fill="url(#verticalClasterGradient)" points={getClasterWrapperPath(ClasterCandleData, 50, 40, 25)}></polygon>
                    <polygon fill="url(#verticalClasterGradient)" points={getClasterWrapperPath(ClasterCandleData, 150, 160, 25)}></polygon>

                    {ClasterCandleData.map((item, index) => {
                        if (canvasHeight === 0) {
                            return;
                        }

                        const xPadding = 50;
                        const y = index * 25;
                        const isGoingUp = item.ask < item.bid;
                        const redBg = isGoingUp ? "url(#ClasterCandleGradientRed)" : "#ef0000";
                        const greenBg = isGoingUp ? "#2E8719" : "url(#ClasterCandleGradientGreen)";

                        return (
                            <>
                                <rect className="claster" stroke="#FF0000" strokeWidth="2" fill={redBg} x={xPadding} y={y} width={50} />
                                <rect className="claster" stroke="#39AB1D" strokeWidth="2" fill={greenBg} x={xPadding + 50} y={y} width={50} />
                                <text className={`counter ask ${isGoingUp ? '' : 'colorize'}`} x={xPadding + 10} y={y + 17}>{item.ask}</text>
                                <text className={`counter bid ${isGoingUp ? 'colorize' : ''}`} x={xPadding + 85} y={y + 17}>{item.bid}</text>
                            </>
                        )
                    })}
                    <linearGradient id="ClasterCandleGradientRed">
                        <stop stopColor="rgba(171, 70, 70, 0.5) " offset="1%" />
                        <stop stopColor="rgba(171, 70, 70, 0) " offset="99%" />

                    </linearGradient>
                    <linearGradient id="ClasterCandleGradientGreen">
                        <stop stopColor="rgba(47, 119, 29, 0)" offset="1%" />
                        <stop stopColor=" rgba(47, 119, 29, 0.5)" offset="99%" />
                    </linearGradient>
                </svg>
        </>
    )
}

TableBlockCluster.displayName = 'SimpleLine'
