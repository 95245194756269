import React from 'react';
import './Avatar.scss'
import {NotificationSticker} from "../../../Common/Components/NotificationSticker/NotificationSticker";
import AvatarIcon from "../../../Common/Components/Icons/AvatarIcon.svg";

/**
 * Аватарка пользователя.
 * @prop {boolean} hasNotification Флаг наличия уведомления для пользователя.
 * @prop {string} [base64] Фото аватарки пользователя.
 */
interface IProps {
    hasNotification: boolean;
    base64?: string;
}

export const Avatar: React.FC<IProps> = (props: IProps) => {
    const {hasNotification} = props;

    const renderSticker = () => {
        return hasNotification && (
            <div className="sticker">
                <NotificationSticker />
            </div>
        );
    }
    return (
        <div className="avatar">
            <img src={AvatarIcon} alt="Аватар пользователя"/>
            {renderSticker()}
        </div>
    );
}

Avatar.displayName = 'Avatar';
