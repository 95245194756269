import { CandleSize } from "../../CandlesClient/models/Candle";

export const TIME_VALUE_Y = 15;
export const TIME_LABEL_WIDTH = 70; 

export const CANVAS_HEIGHT = 100000;
export const CANVAS_WIDTH = 100000;
export const PRICE_ELEMENTS_COUNT = 40;
export const PRICE_MIN_TICK = 1;
export const PRICE_FLOAT_MIN_VALUE = 0; // get from api
export const CANVAS_PADDING = 20;
export const CANDLE_TAIL_WIDTH = 2;
export const CANDLE_WIDTH = 14;
export const SPACE_BEETFWEEN_CANDLES = 4;
export const SPACE_BEETFWEEN_SNAKES = 2;
export const CANDLE_OFFSET = CANDLE_WIDTH + (SPACE_BEETFWEEN_CANDLES * 2);
export const SCALE_STEP = 0.2;
export const MIN_SCALE = 0.2; //cannot equals 0 or less !!!
export const MAX_SCALE = 10;
export const DEFAULT_SCALE = 1;
export const LINE_CHART_SCALE = 0.3;
export const CLUSTER_SCALE = 2;
export const CLUSTER_SINGLE_SEGMENT_SCALE = 2.5;
export const CLUSTER_MULTI_SEGMENT_SCALE = 7;
export const WHEEL_SCROLL_STEP = 100;
export const SNAKE_CONNECTOR_WIDTH = 6;
export const SNAKE_WIDTH = 6;
export const SNAKE_OFFSET = (SNAKE_CONNECTOR_WIDTH * 2) + SNAKE_WIDTH + (SPACE_BEETFWEEN_SNAKES * 2);
export const LINE_POINT_RADIUS = 2;
export const MIN_CLUSTER_HEIGHT = 10;
export const WINDOW_COUNT = 3; //real + 2 virtuals between
export const DEBOUNCE_DELAY = 500;
export const PRICE_SCALE_STEP = 10;

export const END_DATE = '2019-01-01 00:00:00';
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_LIMIT = '2017-08-17 05:00:28';

export const DEFAULT_CANDLE_SIZE: CandleSize = {
    candleWidth: CANDLE_WIDTH,
    tailWidth: CANDLE_TAIL_WIDTH,
    snakeWidth: SNAKE_WIDTH,
    connectorWidth: SNAKE_CONNECTOR_WIDTH,
    clusterHeight: 1,
    clusterSize: 1
}

export enum TimeFrame {
    seconds = 'seconds',
    minutes = 'minutes',
    hours = 'hours',
    days = 'days'
}

export enum SqlTimeFrame {
    seconds = '%Y-%m-%d %H:%M:%S',
    minutes = '%Y-%m-%d %H:%M',
    hours = '%Y-%m-%d %H',
    days = '%Y-%m-%d'
}

export enum SqlTimeSymbol {
    seconds = '%S',
    minutes = '%M',
    hours = '%H',
    days = '%d'
}

export enum Strftime {
    seconds = '%Y-%m-%d %H:%M:',
    minutes = '%Y-%m-%d %H:',
    hours = '%Y-%m-%d ',
    days = '%Y-%m-'
}

export enum ChartEvent {
    InitCandlesData = 'InitCandlesData',
    InitCandlesDataSuccess = 'InitCandlesDataSuccess',
    AddNewCandles = 'AddNewCandles',
    AddNewCandlesSuccess = 'AddNewCandles',
    HandleChartScrolling = 'HandleChartScrolling',
    HandleChartScrollingSuccess = 'HandleChartScrollingSuccess',
    HandleChartScale = 'HandleChartScale',
    HandleChartScaleSuccess = 'HandleChartScaleSuccess',
    HandleModeChange = 'HandleModeChange',
    HandleModeChangeSuccess = 'HandleModeChangeSuccess',
    HandleNewExtremums = 'HandleNewExtremums',
    HandleNewExtremumsSuccess = 'HandleNewExtremumsSuccess',
    HandleChartZoom = 'HandleChartZoom',
    HandleChartZoomSuccess = 'HandleChartZoomSuccess',
    UpdateClusters = 'UpdateClusters',
    UpdateClustersSuccess = 'UpdateClustersSuccess',
}

