import React, {useState} from "react";
import {Dropdown} from "primereact/dropdown";

import {Switcher} from "../../../Switcher";
import {NumberSelect} from "../../../Inputs/NumberSelect";

import './AccountBlock.scss'

interface OwnProps {

}

export const AccountBlock: React.FC<OwnProps> = ({}) => {
    const options = ["NASDAQ: futures", "Binance: stocks"]
    const [selectedAccount, setSelectedAccount] = useState(options[1])

    return (
        <div className="account-block">
            <div className="header-section">
                <div>
                <label htmlFor="account-select" className="control-label">Account</label>
                <Dropdown
                    id="account-select"
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.value)}
                    options={options}
                    editable
                    showOnFocus
                    placeholder="Select Account"
                />
                </div>
                <div>
                <label className="control-label">Balance: <b>999 999 999$</b></label>
            </div>
            </div>
            <div className="main-section">
                <div className="middle-block">
                    <Switcher options={["Volume", "% Depo"]} vertical defaultOption="Volume" />
                    <NumberSelect
                        options={[
                            {value: '0.123456789', label:'0.123456789'},
                        ]}
                        numberButtons
                        label="Trade volume"
                        unitLabel="BTC"
                    />
                    <div className="number-buttons">
                        <button className="blue-button">1</button>
                        <button className="blue-button">2</button>
                        <button className="blue-button">3</button>
                    </div>
                </div>
                <div className="bottom-block">
                <div>
                    <label className="control-label">Margin mode</label>
                    <Switcher options={["Cross", "Isolated"]} defaultOption="Cross" />
                </div>
                <div>
                    <label className="control-label">Leverage</label>
                    <NumberSelect
                        options={[
                            {value: 'x1000', label:'x1000'},
                        ]}
                        numberButtons
                        inline
                    />
                </div>
            </div>
            </div>
        </div>
    );
}
