import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {SearchInput} from '../../../Common/Components/SearchInput/SearchInput';
import {ExitIcon} from "../../../Common/Components/Icons/ExitIcon/ExitIcon";

import {Tag} from "../../../Common/Components/Tag/Tag";
import {ETagSize} from "../../../Common/Components/Tag/enums";
import {IInstrumentTag, IInstrumentTagGroup, IInstrumentTagSubGroup} from "../../../Models/models";
import {Button} from "../../../Common/Components/Button/Button";
import {ButtonWithIcon} from "../../../Common/Components/ButtonWithIcon/ButtonWithIcon";
import {calcFullInstrumentNameLowerCase} from "../../../Modules/Trading/utils";
import {ToggleButton} from "../../../Common/Components/ToggleButton/ToggleButton";

import {ConnectionInfoCell} from "./InstrumentInfoCell";
import {ConnectionPriceCell} from "./InstrumentPriceCell";

import "./TradingConnections.scss";

const toggleButtons = [
    {
        id: '1',
        label: 'Connected'
    },
    {
        id: '2',
        label: 'All connects'
    }
]

export const instrumentsTagsMock: IInstrumentTagGroup[] = [
    {
        title: 'Type instrument:',
        subSections: [
            {
                tags: [
                    {
                        label: 'Stock',
                        checked: false
                    },
                    {
                        label: 'Futures',
                        checked: false
                    },
                    {
                        label: 'Forex',
                        checked: false
                    },
                    {
                        label: 'Crypto',
                        checked: false
                    },
                    {
                        label: 'Index',
                        checked: false
                    },
                    {
                        label: 'Bond',
                        checked: false
                    },
                    {
                        label: 'Option',
                        checked: false
                    },

                ]
            }
        ]
    },
    {
        title: 'Country:',
        subSections: [
            {
                tags: [
                    {
                        label: 'USA',
                        checked: false
                    },
                    {
                        label: 'China',
                        checked: false
                    },
                    {
                        label: 'Japan',
                        checked: false
                    },
                    {
                        label: 'Mexico',
                        checked: false
                    },
                    {
                        label: 'Australia',
                        checked: false
                    },
                    {
                        label: 'Germany',
                        checked: false
                    },
                    {
                        label: 'United Kingdom',
                        checked: false
                    },
                    {
                        label: 'Cyprus',
                        checked: false
                    },

                ]
            }
        ]
    }
]
export const connectionsListMock = [
    {
        id: 1,
        name: 'BINANCE',
        subName: 'crypto - SPOT',
        exchangeName: 'Datafeed',
        accountBalance: '6 544 873.29',
        userConnectionName: 'My Binance SPOT',
    },
    {
        id: 2,
        name: 'BYBIT',
        subName: 'crypto - futures',
        exchangeName: 'Datafeed',
        accountBalance: '32 588.73',
        userConnectionName: 'Fedor Migel',

    }
]

const getTagsList = () => {
    const resultArray: any = [];
    instrumentsTagsMock.forEach(item => item.subSections.forEach(group => group.tags.forEach((tag, index) => {
        resultArray.push({
            id: `${index}${tag.label}`,
            label: tag.label,
            checked: tag.checked,
            groupTitle: item.title
        })
    })))

    return resultArray;
}

interface OwnProps {
    onClose: () => void;
}


export const TradingConnections: React.FC<OwnProps> = ({onClose}) => {
    const { t } = useTranslation();

    const [tagsList, setTagsList] = useState<any[]>(getTagsList())
    const [filterByName, setFilterByName] = useState('');
    const [filterByTag, setFilterByTag] = useState<string[]>([])
    const [tabValue, setTabValue] = useState(toggleButtons[0])
    const [newConnectOpen, setNewConnectOpen] = useState(false)
    const [selectedExchange, setSelectedExchange] = useState<number[]|undefined>()

    const onNewConnectClick = () => {
        setNewConnectOpen(true)

    }

    const handleTabSelect = (selectedTab: { id: string, label: string }) => setTabValue(selectedTab)


    const onSaveConnect = () => {
        setNewConnectOpen(false)
    }

    const handleTagClick = (label: string) => {
        return () => setTagsList(tagsList.map(obj => {
            if (obj.label === label) {
                return {...obj, checked: !obj.checked};
            } else {
                return obj;
            }
        }));
    }

    useEffect(() => {
        const checkedTags: string[] = [];
        tagsList.forEach(item => {
            if (item.checked) {
                checkedTags.push(item.label);
            }
        })
        setFilterByTag(checkedTags)
    }, [tagsList])

    const unSelectAllTagsInGroup = (groupTitle: string) => {
        return () => setTagsList(tagsList.map(obj => {
            if (obj.groupTitle === groupTitle) {
                return {...obj, checked: false};
            } else {
                return obj;
            }
        }));
    }

    const renderTags = (tags: IInstrumentTag[]) => {
        return tags.map(tag => {
            const checkedTag = tagsList.find(item => item.label === tag.label)?.checked
            return (
                <div key={tag.label} className="tag">
                    <Tag label={tag.label} size={ETagSize.MD} checked={checkedTag} onClick={handleTagClick(tag.label)} />
                </div>
            )
        })
    }

    const renderTagsSubGroups = (tagSubGroups: IInstrumentTagSubGroup[]) => {
        return tagSubGroups.map(subGroup => {
            return (
                <div className="tagsSubGroup" key={subGroup.tags[0].label}>
                    <div className="tags">
                        {renderTags(subGroup.tags)}
                    </div>
                </div>
            )
        })
    }

    const renderTagsGroups = () => {
        return instrumentsTagsMock.map(tagGroup => {
            const selectedGroupTags = tagsList.find(item => item.groupTitle === tagGroup.title && item.checked)

            return (
                <div className="tagsGroup" key={tagGroup.title}>
                    <div className="tagsGroupTitle">
                        <span>{tagGroup.title}</span>
                        <Tag onClick={unSelectAllTagsInGroup(tagGroup.title)} label={t('Components:Components.InstrumentsManager.tags.all')} size={ETagSize.MD} checked={!selectedGroupTags} />
                    </div>
                    {renderTagsSubGroups(tagGroup.subSections)}
                </div>
            )
        })
    }

    const renderHeader = () => {
        return (
            <div className="listHeader">
                <span>{t('TradingConnections:common.columns.ConnectedExchange')}</span>
                <span className="account-label">{t('TradingConnections:common.columns.Account')}</span>
            </div>
        )
    }

    const renderInstrumentList = () => {

        return connectionsListMock.map(item => {
            const isFilterByNameValidated = !filterByName.length
                || calcFullInstrumentNameLowerCase(item.name, item.subName).includes(filterByName.toLowerCase())
            const isFilterByTagValidated = !filterByTag.length || filterByTag.includes(item.exchangeName);

            if (isFilterByNameValidated) {
                if (isFilterByTagValidated) {
                    return (
                        <div className="listItem" key={item.id}>
                            <ConnectionInfoCell name={item.name} subName={item.subName} exchangeName={item.exchangeName} />
                            {selectedExchange ? (
                                <>
                                    <ConnectionPriceCell accountBalance={item.accountBalance} userConnectionName={item.userConnectionName} />
                                    <div className="buttons-container">
                                        <Button label={'CONNECT'} />
                                        <ButtonWithIcon icon={<i className="pi pi-cog" />} />
                                        <ButtonWithIcon icon={<i className="pi pi-trash" />} />
                                    </div>
                                </>
                                ) : (
                                    <div className="buttons-container">
                                        <Button label={'Add'} onClick={() => setSelectedExchange([item.id])} />
                                    </div>
                                )}
                        </div>
                    )
                }
            }
        })
    }

    const handleInputChange = useCallback((value: string) => {
        setFilterByName(value)
    }, [])

    const showInstrumentList = ((newConnectOpen && !selectedExchange) || (!newConnectOpen && selectedExchange))

    return (
        <div className="instrumentsManagerContainer trading-connections">
            <div className="content">
                <div className="tagsArea">
                    <ToggleButton value={tabValue} items={toggleButtons} onClick={handleTabSelect} />
                    {renderTagsGroups()}
                </div>
                <div className="list">
                    <div className="header">
                        <SearchInput text={filterByName} onChange={handleInputChange} />
                        <ExitIcon onClose={onClose} />
                    </div>


                    {connectionsListMock.length && !newConnectOpen && !selectedExchange && (
                    <div className="no-exchange">
                        <h2>{t('TradingConnections:NoExchangesConnected.Title')}</h2>
                        <Button label={t('TradingConnections:NoExchangesConnected.NewConnectButton')} onClick={() => onNewConnectClick()} />
                    </div>
                    )}
                    {newConnectOpen && selectedExchange && (
                        <>
                            <div className="form-fields">
                            <h2>{t('TradingConnections:NewConnection.Header')}</h2>
                            <div>
                                <span className="form-label">{t('TradingConnections:NewConnection.TradingType')}</span>
                                <input type="text" />
                            </div>
                            <div>
                                <span className="form-label">{t('TradingConnections:NewConnection.AccountName')}</span>
                                <span className="help-text">{t('TradingConnections:NewConnection.AccountNameHint')}</span>
                                <input type="text" />
                            </div>
                            <div>
                                <span className="form-label">{t('TradingConnections:NewConnection.APIkey')}</span>
                                <input type="text" />
                            </div>
                            <div>
                                <span className="form-label">{t('TradingConnections:NewConnection.Secret')}</span>
                                <input type="text" />
                            </div>
                                <div><Button label={t('TradingConnections:NewConnection.Save')} onClick={() => onSaveConnect()}/></div>
                    </div>

                        </>
                    )}
                    {showInstrumentList && (
                        <>
                            {renderHeader()}
                            <div className="listContent">
                                {renderInstrumentList()}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
