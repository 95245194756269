import React, {useCallback, useState} from 'react';
import {ESelectSize} from "./enums";
import {ISelectItem} from "./models";
import {SelectTarget} from "./SelectTarget/SelectTarget";
import {SelectDropdown} from "./SelectDropdown/SelectDropdown";
import cn from "classnames";

import "./Select.scss";
/**
 * Селектор с выпадающим меню.
 * @prop value Выбранная опция.
 * @prop items Список опций.
 * @prop size Размер выпадающего меню.
 * @prop [onClick] Обработчик нажатия на опцию.
 */
interface IButtonProp {
    value?: ISelectItem;
    items: ISelectItem[];
    size: ESelectSize;
    formatter?: (value: ISelectItem) => string;
    onSelect:  (value: ISelectItem) => void;
}

export const Select: React.FC<IButtonProp> = (props: IButtonProp) => {
    const {value, items, size, formatter, onSelect} = props;

    const selectedValue = value || items.find(i => i.selected) || {id: 0, value: 'select'};
    
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleTargetClick = useCallback(() => {
        setIsOpen(prev => !prev)
    }, [setIsOpen])

    const handleSelect = (value: ISelectItem) => {
        setIsOpen(false);
        onSelect(value)
    }

    const selectClassnames = cn({
        selectContainer: true,
        SM: size === ESelectSize.SM,
        MD: size === ESelectSize.MD,
        LG: size === ESelectSize.LG,
        open: isOpen
    })

    return (
        <div className={selectClassnames}>
            <SelectTarget value={selectedValue}  isOpen={isOpen} formatter={formatter} onClick={handleTargetClick} />
            <SelectDropdown isOpen={isOpen} value={selectedValue} items={items} formatter={formatter} onSelect={handleSelect} />
        </div>
    );
}

Select.displayName = 'Select';
