import React, { useEffect, useState } from "react";
import "./TechAnalysis.scss";

export interface Coordinate {
    id: string;
    x: number;
    y: number;
}

export const TechAnalysis: React.FC = () => {
    // const [selectedElement, setSelectedElement] = useState<Element | null>();
    const [selectedCoordinate, setSelectedCoordinate] = useState<Coordinate | undefined>({id: "-1", x: 0, y: 0});
    let selectedElement: SVGElement|null;
   //let selectedCoordinate;
    let draggableContainer: SVGElement|HTMLElement|null;
    let offset: Coordinate;
    const [pointsCoordinates, setPointsCoordinates] = useState<Coordinate[]>([
        { id: "0", x: 10, y: 10 },
        { id: "1", x: 200, y: 10 },
        { id: "2", x: 200, y: 200 },
        { id: "3", x: 10, y: 200 }
    ]);
    const [polygonPath, setPolygonPath] = useState('');

    const updatePolygonPath = (): void => {
        let path = ""
        const coordinatesCount = pointsCoordinates.length;
        pointsCoordinates.forEach((c,i) => {
            path += Math.ceil(c.x) + "," + Math.ceil(c.y);
            if(coordinatesCount-1> i) {
                path += " ";
            }
        });
        setPolygonPath(path);
    }

    const getMousePosition = (evt: MouseEvent): Coordinate => {
        var CTM = (draggableContainer as SVGGraphicsElement).getScreenCTM();
        //@ts-ignore
        if (evt.touches) { evt = evt.touches[0]; }
        return {
            id: "-1",
            x: (evt.clientX - CTM!.e) / CTM!.a,
            y: (evt.clientY - CTM!.f) / CTM!.d
        };
    }

    const startDrag = (event: MouseEvent): void => {
        if ((event.target  as HTMLElement).classList.contains('draggable')) {
            //setSelectedElement(event.target);
            selectedElement = event.target as SVGElement;
            offset = getMousePosition(event);
            let x = selectedElement.getAttributeNS(null, "cx");
            let y = selectedElement.getAttributeNS(null, "cy");
            if(x && y) {
                offset.x -= parseFloat(x.toString());
                offset.y -= parseFloat(y.toString());
            }
           
            const coordinate = pointsCoordinates.find(c=> c.id == (selectedElement as SVGElement).getAttributeNS(null, "id"));
            //setSelectedCoordinate(coordinate);
            //selectedCoordinate=coordinate;
            setSelectedCoordinate(coordinate)
            
        }
    }
    const drag = (event: MouseEvent): void => {
        if (selectedElement && selectedCoordinate) {
            event.preventDefault();
            var coord = getMousePosition(event);
            selectedElement.setAttributeNS(null, "cx", coord.x.toString());
            selectedElement.setAttributeNS(null, "cy", coord.y.toString());
            // selectedCoordinate.x = coord.x;
            // selectedCoordinate.y = coord.y;
            setSelectedCoordinate(coord);
            const coordinate = pointsCoordinates.find(c=> c.id == (event.target as SVGElement).getAttributeNS(null, "id"));
            if(coordinate == null) {
                return;
            }
            coordinate.x = coord.x;
            coordinate.y = coord.y;
        }
    }
    const endDrag = (e: MouseEvent): void => {
        selectedElement = null;
        updatePolygonPath();
        //setSelectedElement(null);
    }

    useEffect(() => {
        updatePolygonPath();
        draggableContainer = document.getElementById('draggableContainer');

        if (draggableContainer == null) {
            return;
        }

        draggableContainer.addEventListener('mousedown', startDrag);
        draggableContainer.addEventListener('mousemove', drag);
        draggableContainer.addEventListener('mouseup', endDrag);
        draggableContainer.addEventListener('mouseleave', endDrag);
    }, [])

    useEffect(() => {
       console.log(selectedCoordinate);
       updatePolygonPath();
    }, [selectedCoordinate?.x, selectedCoordinate?.y])
   
   

    const renderContent = () => {
        return (
            <div id="techAnalysisContainer">
                <svg id="draggableContainer" width="1000" height="800">
            
                {polygonPath && (<polygon  fill="#ffa500" points={polygonPath}></polygon>)}

                {/* <polygon  fill="#ffa500" points="200.200 100.100 10.10"></polygon> */}
                    {pointsCoordinates.map((point, index) => {


                        return (
                            <circle key={point.id} className="draggable point" id={point.id.toString()} cx={point.x} cy={point.y} r="4" fill="red" />
                        )
                    })}
                </svg>
            </div>

        )
    }


    return (
        <div className="container">{renderContent()}</div>
    )
}

