import React, {ReactNode} from 'react';

import "./ButtonWithIcon.scss";

/**
 * Компонент Кнопка с иконкой.
 * @prop icon Иконка.
 * @prop onClick Обработчик нажатия на кнопку.
 * @prop [disabled] Флаг неактивной кнопки
 */
interface IButtonProp {
    icon: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}

export const ButtonWithIcon: React.FC<IButtonProp> = (({icon, onClick}: IButtonProp) =>
    <div className="buttonWithIconContainer" onClick={onClick}>
        {icon}
    </div>
)

ButtonWithIcon.displayName = 'ButtonWithIcon';
