// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#techAnalysisContainer .draggable {
  cursor: move;
}`, "",{"version":3,"sources":["webpack://./src/Modules/TechAnalysis/TechAnalysis.scss"],"names":[],"mappings":"AACI;EACG,YAAA;AAAP","sourcesContent":["#techAnalysisContainer {\n    .draggable {\n       cursor: move; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
