import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Settings from "./img/Settings.svg";
import SettingsActive from "./img/SettingsActive.svg";
import StaticModeIcon from "./img/StaticModeIcon.png";
import MaintenanceIcon from '../../Common/Components/Icons/Maintenance.svg'
import "./MainChart.scss";
import { useDispatch } from "react-redux";
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from "worker-loader!../Candles/webWorker.ts";
import {
    CANVAS_HEIGHT,
    CANDLE_OFFSET,
    MIN_SCALE,
    SCALE_STEP,
    ChartEvent,
    CANVAS_PADDING,
    WHEEL_SCROLL_STEP,
    MAX_SCALE,
    DEFAULT_CANDLE_SIZE,
    CANVAS_WIDTH,
    END_DATE,
    DATE_FORMAT,
    WINDOW_COUNT,
    TIME_VALUE_Y,
    PRICE_VALUE_X,
    TIME_LABEL_WIDTH
} from "../Candles/constants";
import {
    ChangeModePayload,
    ChangeModeSuccessPayload,
    ChangeZoomPayload,
    ChangeZoomSuccessPayload,
    InitCandlesPayload,
    InitCandlesSuccessPayload,
    LineChartData,
    NewExtremumsPayload,
    NewExtremumsSuccessPayload,
    PriceValue,
    ScaleSuccessPayload,
    Scroll,
    ScrollingPayload,
    ScrollingSuccessPayload,
    UCandle,
    TimeValue,
    CandleSize,
    UpdateClustersPayload,
    UpdateClustersSuccessPayload,
    AddNewCandlesPayload,
    AddNewCandlesSuccessPayload
} from "../../CandlesClient/models/Candle";
import { JapaneeseCandleChart } from "./JapaneeseCandleChart/JapaneeseCandleChart";
import { LineChart } from "./LineChart/LineChart";
import { ClusterChart } from "./ClusterChart/ClusterChart";
import { ChartSettings, ChartSettingsData, SettingsValue } from "./settingsData";
import { Select } from "../../Common/Components/Select/Select";
import { ESelectSize } from "../../Common/Components/Select/enums";
import useComponentVisible from "../../Hooks/useComponentVisible";
import { getCandlesQuery } from "../../Helpers/app-helper";
import { useTypedSelector } from "../../Hooks/useTypedSelector";
import { ChartStyle, RootState } from "../../Store/models";
import { changeChartScale } from "../../Store/action-creators";
import { isClusterChartVisible, isJapaneeseChartVisible, isLineChartVisible, isSnakeChartVisible } from "../../Store/reducers/helper";
import { checkAvailableExtremums } from "../Candles/Helpers/extremums.helper";
import { SnakeCandleChart } from "./SnakeChart/SnakeChart";
import moment, { Moment } from "moment";
import { calculateY, debounce, getCandleSize, getScrolledCandlesCount } from "../Candles/helper";
import { getScaleCandlesDateRange, getShiftedCandlesDateRange, getSqlTimeFrame, getSqlTimeSymbol, getStartDate, getStrftime } from "../Candles/Helpers/date.helper";

const worker = new Worker();

export const MainChart: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const chartSettingsMenu = useComponentVisible(false);

    const [scrollModeActive, setScrollModeActive] = useState(false);
    const [staticModeActive, setStaticModeActive] = useState(false);

    const [viewPortWidth, setViewPortWidth] = useState(0);
    const [viewportHeight, setViewportHeight] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);

    const [startDate, setStartDate] = useState<Moment>(moment());
    const [endDate, setEndDate] = useState<Moment>(moment());
    const [spaceGap, setSpaceGap] = useState<number>(0);

    const [lowestPrice, setLowestPrice] = useState(0);
    const [highestPrice, setHighestPrice] = useState(0);
    const [lowestPoint, setLowestPoint] = useState(0);
    const [highestPoint, setHighestPoint] = useState(0);
    const [timeValues, setTimeValues] = useState<TimeValue[]>([]);
    const [priceValues, setPriceValues] = useState<PriceValue[]>([]);
    const [latestPrice, setLatestPrice] = useState(0);
    const [latestPricePosition, setLatestPricePosition] = useState(0);
    const [closePrice, setClosePrice] = useState(0);
    const [closePricePosition, setClosePricePosition] = useState(0);
    const [hoveredCandleTime, setHoveredCandleTime] = useState<string>('');
    const [hoveredCandlePosition, setHoveredCandlePosition] = useState(0);
    const [scaleIndex, setScaleIndex] = useState<number>(1);
    const [scrollLeft, setScrollLeft] = useState<number>(0);

    const [candlesOnViewPort, setCandlesOnViewPort] = useState(0);
    const [candles, setCandles] = useState<UCandle[]>([]);
    const [candleSize, setCandleSize] = useState<CandleSize>(DEFAULT_CANDLE_SIZE);
    const [lineChartData, setLineChartData] = useState<LineChartData>({});

    const chart = useRef<SVGSVGElement>({} as SVGSVGElement);
    const chartContainer = useRef<HTMLDivElement>({} as HTMLDivElement);
    const priceContainer = useRef<HTMLDivElement>({} as HTMLDivElement);
    const timeContainer = useRef<HTMLDivElement>({} as HTMLDivElement);

    const [viewPortDataDefined, setViewPortDataDefined] = useState<boolean>(false);
    const [scrollPosition, setScrollPosition] = useState<Scroll>({ top: 0, left: 0, x: 0, y: 0 });
    const [workerSubscribed, setWorkerSubscribed] = useState<boolean>(false);
    const [maintenance, setMaintenance] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [priceMouseDown, setPriceMouseDown] = useState<boolean>(false);
    const [timeMouseDown, setTimeMouseDown] = useState<boolean>(false);
    const [chartMouseDown, setChartMouseDown] = useState<boolean>(false);

    const [chartSettingsData, setChartSettingsData] = useState<ChartSettings[]>(ChartSettingsData);
    const [selectedWheelSetting, setSelectedWheelSetting] = useState<SettingsValue>(SettingsValue.Zoom);
    const [selectedPriceScaleSetting, setSelectedPriceScaleSetting] = useState<SettingsValue>(SettingsValue.PriceDefault);
    const [selectedClusterSetting, setSelectedClusterSetting] = useState<SettingsValue>(SettingsValue.Visible);

    const [cursorPositionX, setCursorPositionX] = useState<number>(0);
    const [cursorPositionY, setCursorPositionY] = useState<number>(0);

    const scale = useTypedSelector((state: RootState) => state.app.mainChart.scaleIndex);
    const chartStyle = useTypedSelector((state: RootState) => state.app.mainChart.activeChartStyle);
    const timeCount = useTypedSelector((state: RootState) => state.app.mainChart.timeCount);
    const timeFrame = useTypedSelector((state: RootState) => state.app.mainChart.timeFrame);
    const customScale = useTypedSelector((state: RootState) => state.app.mainChart.customScale);
    const selectedChartFrame = useTypedSelector((state: RootState) => state.app.selectedChartFrame);

    const headerTitle = "Binance BTCUSD " + selectedChartFrame;

    const settingsIcon = chartSettingsMenu.isComponentVisible ? SettingsActive : Settings;

    let scrolledCandlesCount = 0;

    //const candles = useTypedSelector((state: RootState) => state.app.candles).slice(-1*CANDLES_COUNT_PER_WINDOW);
    //const candles = Candles.slice(-150000);

    const defineViewPortData = (): number => {
        const leftMenuWidth = document.querySelector<HTMLElement>('.navigation-menu-container')?.offsetWidth || 0;
        const rightMenuWidth = document.querySelector<HTMLElement>('.charts-menu-container')?.offsetWidth || 0;
        const priceContainerWidth = document.querySelector<HTMLElement>('.price-container')?.offsetWidth || 0;
        const headerMenuHeight = document.querySelector<HTMLElement>('.header-menu-container')?.offsetHeight || 0;
        const timeScaleHeight = document.querySelector<HTMLElement>('.time-container')?.offsetHeight || 0;

        const containerWidth = window.innerWidth - (leftMenuWidth + rightMenuWidth);
        const viewPortWidth = containerWidth - priceContainerWidth;
        const viewPortHeight = window.innerHeight - (headerMenuHeight + timeScaleHeight);

        setViewportHeight(viewPortHeight);
        setContainerWidth(containerWidth);
        setViewPortWidth(viewPortWidth);
        setViewPortDataDefined(true);

        return viewPortWidth;
    }

    const mousewheelChartHandler = (e: any) => {
        if (selectedWheelSetting === SettingsValue.Scroll) {
            handleChartScroll(e);
        } else {
            handleChartScale(e);
        }
    }

    const mousewheelPriceHandler = (e: any) => {
        if (staticModeActive) {
            toggleStaticMode();
            return;
        }

        const direction = e.deltaY < 0;

        const payload: ChangeZoomPayload = {
            type: ChartEvent.HandleChartZoom,
            candles,
            scaleIndex,
            direction,
            selectedPriceScaleSetting,
            scrollTop: (chartContainer.current as HTMLElement).scrollTop,
            spaceGap,
            viewportHeight,
            lowestPrice,
            highestPrice,
            highestPoint: highestPoint,
            lowestPoint: lowestPoint,
            latestPrice,
            chartStyle
        }

        worker.postMessage(payload);
    }

    const handleChartScroll = (e: any) => {
        const direction = e.deltaY > 0 ? 1 : -1;
        let scrollLeft = (chartContainer.current as HTMLElement).scrollLeft + (WHEEL_SCROLL_STEP * direction);

        if (scrollLeft < 0) {
            scrollLeft = 0;
        }

        if (scrollLeft > CANVAS_WIDTH) {
            scrollLeft = CANVAS_WIDTH;
        }

        setScrollLeft(scrollLeft);

        if (staticModeActive) {
            const payload: NewExtremumsPayload = {
                type: ChartEvent.HandleNewExtremums,
                candles,
                scaleIndex,
                viewPortWidth,
                scrollLeft,
                spaceGap,
                latestPrice,
                viewportHeight,
                chartStyle
            }

            worker.postMessage(payload);
        } else {
            const payload: ScrollingPayload = {
                type: ChartEvent.HandleChartScrolling,
                candles,
                scaleIndex,
                viewportHeight,
                highestPrice: highestPrice,
                lowestPrice: lowestPrice,
                highestPoint: highestPoint,
                lowestPoint: lowestPoint,
                viewPortWidth,
                scrollLeft,
                spaceGap,
                scrollTop: (chartContainer.current as HTMLElement).scrollTop,
                latestPrice,
                priceValues,
                staticModeActive,
                chartStyle,
            }

            worker.postMessage(payload);
        }
    }

    const scrollToEnd = () => {
        const scrollLeft = CANVAS_WIDTH - viewPortWidth;
        setScrollLeft(scrollLeft);

        const payload: ScrollingPayload = {
            type: ChartEvent.HandleChartScrolling,
            candles,
            scaleIndex,
            highestPrice: highestPrice,
            lowestPrice: lowestPrice,
            highestPoint: highestPoint,
            lowestPoint: lowestPoint,
            scrollTop: (chartContainer.current as HTMLElement).scrollTop,
            spaceGap,
            viewportHeight,
            viewPortWidth,
            scrollLeft,
            latestPrice,
            staticModeActive,
            priceValues,
            chartStyle
        }

        worker.postMessage(payload);
    }

    const toggleSettingsMode = () => {
        chartSettingsMenu.setIsComponentVisible(prev => !prev);
    }

    const toggleStaticMode = () => {
        const payload: ChangeModePayload = {
            type: ChartEvent.HandleModeChange,
            candles,
            scaleIndex,
            viewPortWidth,
            scrollLeft,
            spaceGap,
            latestPrice,
            staticModeActive,
            viewportHeight,
            chartStyle
        }

        worker.postMessage(payload);
    }

    const handleChartMouseMove = (e: any) => {
        const count = Math.floor((e.nativeEvent.layerX - CANVAS_PADDING - spaceGap) / (CANDLE_OFFSET * scaleIndex));

        const hoveredCandle = candles[count];
        const candleTime = hoveredCandle?.timeClose || '';
        const hoveredCandlePosition = (hoveredCandle?.bodyyX || 0) - TIME_LABEL_WIDTH / 2;
        setHoveredCandleTime(candleTime);
        setHoveredCandlePosition(hoveredCandlePosition);

        setCursorPositionX(e.nativeEvent.layerX);
        setCursorPositionY(e.nativeEvent.layerY);
    }

    const handleChartScale = (e: any) => {
        let scaleValue;

        if (e.deltaY < 0 && scaleIndex < MAX_SCALE) {
            scaleValue = scaleIndex + SCALE_STEP;
        } else if (e.deltaY > 0 && scaleIndex > MIN_SCALE) {
            scaleValue = scaleIndex - SCALE_STEP;
        } else {
            return;
        }

        const nextScaleIndex = +scaleValue.toFixed(2);

        dispatch(changeChartScale(nextScaleIndex));
    }

    const priceMouseDownHandler = (e: any) => {
        if (priceContainer.current == null) {
            return;
        }

        setScrollPosition({ ...scrollPosition, y: e.clientY });
        setPriceMouseDown(true);
    }

    const timeMouseDownHandler = (e: any) => {
        if (timeContainer.current == null) {
            return;
        }

        setScrollPosition({ ...scrollPosition, y: e.clientY });
        setTimeMouseDown(true);
    }

    const canvasMouseMoveHandler = (e: any) => {
        if (chartMouseDown) {
            chartMouseMoveHandler(e);
        } else if (priceMouseDown) {
            priceMouseMoveHandler(e);
        } else if (timeMouseDown) {
            timeMouseMoveHandler(e);
        }
    }

    const timeMouseMoveHandler = (e: any) => {
        if (!timeMouseDown) {
            return;
        }

        const scrolledPixels = scrollPosition.y - e.clientY;
        const direction = scrolledPixels > 0 ? 1 : -1;

        if (scrolledPixels * direction > 25) {
            setScrollPosition({ ...scrollPosition, y: e.clientY });

            let scaleValue;

            if (direction < 0 && scaleIndex < MAX_SCALE) {
                scaleValue = scaleIndex + SCALE_STEP;
            } else if (direction > 0 && scaleIndex > MIN_SCALE) {
                scaleValue = scaleIndex - SCALE_STEP;
            } else {
                return;
            }

            const nextScaleIndex = +scaleValue.toFixed(2);

            dispatch(changeChartScale(nextScaleIndex));
        }
    }

    const priceMouseMoveHandler = (e: any) => {
        if (!priceMouseDown) {
            return;
        }

        if (staticModeActive) {
            toggleStaticMode();
            return;
        }

        const scrolledPixels = scrollPosition.y - e.clientY;
        const direction = scrolledPixels > 0 ? 1 : -1;

        if (scrolledPixels * direction > 25) {
            setScrollPosition({ ...scrollPosition, y: e.clientY });

            const payload: ChangeZoomPayload = {
                type: ChartEvent.HandleChartZoom,
                candles,
                scaleIndex,
                viewportHeight,
                direction: direction > 0,
                spaceGap,
                scrollTop: (chartContainer.current as HTMLElement).scrollTop,
                highestPoint,
                lowestPoint,
                lowestPrice,
                selectedPriceScaleSetting,
                highestPrice,
                latestPrice,
                chartStyle
            }

            worker.postMessage(payload);
        }
    }

    const priceMouseUpHandler = () => {
        setPriceMouseDown(false);
    }

    const timeMouseUpHandler = () => {
        setTimeMouseDown(false);
    }

    const chartMouseDownHandler = (e: any) => { // MouseEvent
        if (chartContainer.current == null) {
            return;
        }

        setChartMouseDown(true);

        const allowVerticalScroll = !staticModeActive;
        const position: Scroll = {
            ...scrollPosition,
            left: (chartContainer.current as HTMLElement).scrollLeft,
            top: (chartContainer.current as HTMLElement).scrollTop,
            x: e.clientX,
            y: allowVerticalScroll ? e.clientY : scrollPosition.y,
        }

        setScrollPosition(position);
    }

    const canvasMouseUpHandler = (e: any) => {
        if (chartMouseDown) {
            chartMouseUpHandler(e);
        } else if (priceMouseDown) {
            priceMouseUpHandler();
        } else if (timeMouseDown) {
            timeMouseUpHandler();
        }
    }

    const updateClosePosition = (count?: number): void => {
        const scrolledCount = count || Math.floor((scrollLeft - CANVAS_PADDING - spaceGap) / (CANDLE_OFFSET * scaleIndex));
        const closePrice = candles[scrolledCount + candlesOnViewPort]?.close || 0;
        const closePosition = calculateY(closePrice, highestPoint, lowestPoint);

        setClosePrice(closePrice);
        setClosePricePosition(closePosition);
    }

    const chartMouseMoveHandler = (e: any) => { // MouseEvent
        if (!chartMouseDown) {
            return;
        }

        const allowVerticalScroll = !staticModeActive;

        const dx = scrollPosition.left - (e.clientX - scrollPosition.x);
        const dy = allowVerticalScroll ? scrollPosition.top - (e.clientY - scrollPosition.y) : scrollPosition.top;

        (chartContainer.current as HTMLElement).scrollLeft = dx;
        (chartContainer.current as HTMLElement).scrollTop = dy;
        (priceContainer.current as HTMLElement).scrollTop = dy;
        (timeContainer.current as HTMLElement).scrollLeft = dx;

        let scrolledCandles = Math.floor((dx - CANVAS_PADDING - spaceGap) / (CANDLE_OFFSET * scaleIndex));

        if (scrolledCandles < 0) {
            scrolledCandles = 0;
        }

        updateClosePosition(scrolledCandles);

        if (staticModeActive && dx > 0 && dx < CANVAS_WIDTH) {
            checkNewExtremums(scrolledCandles, scrolledCandles > scrolledCandlesCount)

        }
    };

    const chartMouseUpHandler = (e: any) => { //MouseEvent
        if (!chartMouseDown) {
            return;
        }

        setChartMouseDown(false);

        if (chartContainer.current == null) {
            return;
        }

        const allowVerticalScroll = !staticModeActive;

        const dy = allowVerticalScroll ? scrollPosition.top - (e.clientY - scrollPosition.y) : scrollPosition.top;

        let scrolledPixels = dy - scrollPosition.top;

        if (scrolledPixels < 0) {
            scrolledPixels = scrolledPixels * -1;
        }

        const scrollValue = (chartContainer.current as HTMLElement).scrollLeft;
        const direction: boolean = scrollValue - scrollLeft > 0;
        const scrolledCandlesCount = getScrolledCandlesCount(scaleIndex, scrollLeft, scrollValue);
        setScrollLeft(scrollValue);
        getShiftedCandles(scrolledCandlesCount, direction);

        if (staticModeActive) {
            return;
        }

        const payload: ScrollingPayload = {
            type: ChartEvent.HandleChartScrolling,
            candles,
            scaleIndex,
            highestPrice: highestPrice,
            lowestPrice: lowestPrice,
            highestPoint: highestPoint,
            lowestPoint: lowestPoint,
            scrollTop: (chartContainer.current as HTMLElement).scrollTop,
            viewPortWidth,
            viewportHeight,
            scrollLeft: scrollValue,
            spaceGap,
            latestPrice,
            priceValues,
            staticModeActive,
            chartStyle
        }

        worker.postMessage(payload);
    };

    const checkNewExtremums = (scrolledCandles: number, direction: boolean): void => {
        if (scrolledCandles === scrolledCandlesCount) {
            return;
        }

        scrolledCandlesCount = scrolledCandles;
        let startIndex = scrolledCandles;
        let endIndex = Math.floor(scrolledCandles + candlesOnViewPort);

        if (startIndex < 0) {
            startIndex = 0;
        }

        let candleIn = direction ? candles[endIndex + 1] : candles[startIndex];
        let candleOut = direction ? candles[startIndex - 1] : candles[endIndex];

        const hasNewExtremums = checkAvailableExtremums(candleIn, candleOut, highestPrice, lowestPrice);

        if (hasNewExtremums) {
            const payload: NewExtremumsPayload = {
                type: ChartEvent.HandleNewExtremums,
                candles,
                scaleIndex,
                viewPortWidth,
                scrollLeft: (chartContainer.current as HTMLElement).scrollLeft,
                spaceGap,
                latestPrice,
                viewportHeight,
                chartStyle
            }

            worker.postMessage(payload);
        }
    }

    //value ChartSettings;
    const handleSelectSettings = (setting: any) => {
        switch (setting.value) {
            case SettingsValue.Zoom:
            case SettingsValue.Scroll:
                setSelectedWheelSetting(setting.value);
                break;
            // case SettingsValue.Cross:

            // case SettingsValue.SizeDefault:

            // case SettingsValue.Dashed:

            // case SettingsValue.ColorDefault:

            // case SettingsValue.Medium:

            case SettingsValue.Visible:
            case SettingsValue.Hidden:
            case SettingsValue.OnHover:
                setSelectedClusterSetting(setting.value);
                break;

            case SettingsValue.PriceDefault:
            case SettingsValue.MidlePrice:
            case SettingsValue.LastPrice:
                setSelectedPriceScaleSetting(setting.value);
                break;
        }
    }

    const getSelectedSetting = (chartSetting: ChartSettings): ChartSettings => {
        return chartSetting.childs?.find(c => c.value === selectedClusterSetting || c.value === selectedPriceScaleSetting || c.value === selectedWheelSetting) || { id: 0 };
    }

    //value ChartSettings;
    const getSettingTitle = (value: any): string => {
        return value.title || t(value.l10nKey);
    }

    const addNewCandles = (data: AddNewCandlesSuccessPayload) => {
        setCandles(data.candles);
        setSpaceGap(data.spaceGap);
        setHighestPrice(data.highestPrice);
        setLowestPrice(data.lowestPrice);
        setTimeValues(data.timeValues);
        setCandleSize(data.candleSize);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setInitalCandlesData = (data: InitCandlesSuccessPayload) => {
        setCandles(data.candles);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setLowestPrice(data.lowestPrice);
        setHighestPrice(data.highestPrice);
        setLowestPoint(data.lowestPoint);
        setHighestPoint(data.highestPoint);
        setLatestPrice(data.latestPrice);
        setLatestPricePosition(data.latestPricePosition);
        setCandleSize(data.candleSize);
        setSpaceGap(data.spaceGap);
        setScrollLeft(data.scrollLeft);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }

        (priceContainer.current as HTMLElement).scrollTop = data.scrollTop;
        (chartContainer.current as HTMLElement).scrollTop = data.scrollTop;
    }

    const setScaleResults = (data: ScaleSuccessPayload) => {
        setCandles(data.candles);
        setTimeValues(data.timeValues);
        setSpaceGap(data.spaceGap);
        setCandleSize(data.candleSize);
        setScaleIndex(data.scaleIndex);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setScrollingResults = (data: ScrollingSuccessPayload) => {
        setCandles(data.candles);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setLowestPoint(data.lowestPoint);
        setHighestPoint(data.highestPoint);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }

        if (data.scrollTop != null) {
            (priceContainer.current as HTMLElement).scrollTop = data.scrollTop;
            (chartContainer.current as HTMLElement).scrollTop = data.scrollTop;
        }


        if (!staticModeActive) {
            return;
        }


        setLowestPrice(data.lowestPrice);
        setHighestPrice(data.highestPrice);
        setLatestPricePosition(data.latestPricePosition);

    }

    const setModeChangeResults = (data: ChangeModeSuccessPayload) => {
        setCandles(data.candles);
        setLowestPrice(data.lowestPrice);
        setHighestPrice(data.highestPrice);
        setLowestPoint(data.lowestPoint);
        setHighestPoint(data.highestPoint);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setLatestPricePosition(data.latestPricePosition);
        setStaticModeActive(data.staticModeActive);
        setCandleSize(data.candleSize);
        (priceContainer.current as HTMLElement).scrollTop = data.scrollTop;
        (chartContainer.current as HTMLElement).scrollTop = data.scrollTop;

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setZoomChangeResults = (data: ChangeZoomSuccessPayload) => {
        setCandles(data.candles);
        setLowestPoint(data.lowestPoint);
        setHighestPoint(data.highestPoint);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setLatestPricePosition(data.latestPricePosition);
        setCandleSize(data.candleSize);

        (priceContainer.current as HTMLElement).scrollTop = data.scrollTop;
        (chartContainer.current as HTMLElement).scrollTop = data.scrollTop;

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const setNewExtremumsResults = (data: NewExtremumsSuccessPayload) => {
        setCandles(data.candles);
        setLowestPrice(data.lowestPrice);
        setHighestPrice(data.highestPrice);
        setLowestPoint(data.lowestPoint);
        setHighestPoint(data.highestPoint);
        setPriceValues(data.priceValues);
        setTimeValues(data.timeValues);
        setLatestPricePosition(data.latestPricePosition);
        setCandleSize(data.candleSize);

        if (data.lineChartData != null) {
            setLineChartData(data.lineChartData);
        }
    }

    const updateClusterTiles = (data: UpdateClustersSuccessPayload) => {
        setCandles(data.candles);
    }

    const fetchCandles = (dateFrom: Moment, dateTo: Moment, direction?: boolean): void => {
        const timeSymbol = getSqlTimeSymbol(timeFrame);
        const sqlTimeFrame = getSqlTimeFrame(timeFrame);
        const strftime = getStrftime(timeFrame);
        const query = getCandlesQuery(scaleIndex);

        setLoading(true);

        fetch(query
            .replaceAll('{{price}}', candleSize.clusterSize.toString())
            .replaceAll('{{timeCount}}', timeCount.toString())
            .replaceAll('{{timeSymbol}}', timeSymbol)
            .replaceAll('{{timeFrame}}', sqlTimeFrame)
            .replace('{{strftime}}', strftime)
            .replace('{{startDate}}', dateFrom.format(DATE_FORMAT))
            .replace('{{endDate}}', dateTo.format(DATE_FORMAT))
        )
            .then(response => response.json())
            .then(json => {
                setLoading(false);
                if (json == null) {
                    json = [];
                }

                console.log(json.rows);

                const payload: AddNewCandlesPayload = {
                    type: ChartEvent.AddNewCandles,
                    rows: json.rows,
                    scaleIndex,
                    candles,
                    direction,
                    spaceGap,
                    highestPrice,
                    lowestPrice,
                    highestPoint,
                    lowestPoint,
                    chartStyle,
                    viewportHeight
                }

                worker.postMessage(payload);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    const initCandles = (): void => {
        if (chart.current?.scrollHeight !== CANVAS_HEIGHT || customScale) {
            return;
        }

        const count = Math.floor(viewPortWidth / (CANDLE_OFFSET * scaleIndex));

        setCandlesOnViewPort(count);

        if (count == 0) {
            return;
        }

        const dateTo = moment(END_DATE, DATE_FORMAT);
        const dateFrom = getStartDate(count, dateTo, timeFrame, timeCount);
        const timeSymbol = getSqlTimeSymbol(timeFrame);
        const sqlTimeFrame = getSqlTimeFrame(timeFrame);
        const strftime = getStrftime(timeFrame);
        const query = getCandlesQuery(scaleIndex);
        setStartDate(dateFrom);
        setEndDate(dateTo);

        setLoading(true);

        fetch(query
            .replaceAll('{{price}}', candleSize.clusterSize.toString())
            .replaceAll('{{timeCount}}', timeCount.toString())
            .replaceAll('{{timeSymbol}}', timeSymbol)
            .replaceAll('{{timeFrame}}', sqlTimeFrame)
            .replace('{{strftime}}', strftime)
            .replace('{{startDate}}', dateFrom.format(DATE_FORMAT))
            .replace('{{endDate}}', dateTo.format(DATE_FORMAT))
        )
            .then(response => response.json())
            .then(json => {
                setLoading(false);

                let rows: any[] = [];

                if (json == null || json.rows == null) {
                    setMaintenance(true);
                    return;
                }

                rows = json.rows;

                const payload: InitCandlesPayload = {
                    type: ChartEvent.InitCandlesData,
                    rows: rows,
                    scaleIndex,
                    viewPortWidth,
                    viewportHeight,
                    chartStyle
                }

                worker.postMessage(payload);
            })
            .catch(error => {
                console.error(error);
                setLoading(true);
            });
    }

    const getScaledCandles = (candlesCount: number): void => {
        if (candlesCount === 0) {
            return;
        }

        const { dateFrom, dateTo, sqlDateFrom, sqlDateTo } = getScaleCandlesDateRange(candles.length, candlesCount, startDate, endDate, timeFrame);

        setStartDate(dateFrom);
        setEndDate(dateTo);

        fetchCandles(sqlDateFrom, sqlDateTo);
    }

    const getShiftedCandles = (candlesCount: number, direction: boolean) => {
        if (candlesCount === 0) {
            return;
        }

        const { dateFrom, dateTo, sqlDateFrom, sqlDateTo } = getShiftedCandlesDateRange(direction, candlesCount, startDate, endDate, timeFrame, timeCount);

        setStartDate(dateFrom);
        setEndDate(dateTo);

        fetchCandles(sqlDateFrom, sqlDateTo, direction);
    }

    useEffect(() => {
        if (viewPortWidth == 0 || !customScale) {
            return;
        }

        const count = Math.ceil(viewPortWidth / (CANDLE_OFFSET * scaleIndex));

        setCandlesOnViewPort(count);

        debounce(() => { getScaledCandles(count * WINDOW_COUNT) });
    }, [scaleIndex]);

    useEffect(() => {
        initCandles();
    }, [chart.current, timeCount, timeFrame, scaleIndex]);

    useEffect(() => {
        if (viewPortDataDefined) {
            return;
        }

        defineViewPortData();
    }, []);

    window.onresize = () => {

        debounce(() => {
            const viewPortWidth = defineViewPortData();

            const count = Math.ceil(viewPortWidth / (CANDLE_OFFSET * scaleIndex));
            setCandlesOnViewPort(count);

            getScaledCandles(count * WINDOW_COUNT);
        });
    };


    // useEffect(() => {
    //     if (!isClusterChartVisible(chartStyle)) {
    //         return;
    //     }

    //     setLoading(true);

    //     fetch(API_ENDPOINTS.CLUSTERS
    //         .replaceAll('{{price}}', candleSize.clusterSize.toString())
    //         .replace('{{startDate}}', startDate.format(DATE_FORMAT))
    //         .replace('{{endDate}}', endDate.format(DATE_FORMAT))
    //     )
    //         .then(response => response.json())
    //         .then(json => {
    //    setLoading(false);
    //             if (json == null) {
    //                 json = [];
    //             }

    //             const payload: UpdateClustersPayload = {
    //                 type: ChartEvent.UpdateClusters,
    //                 rows: json.rows,
    //                 scaleIndex,
    //                 spaceGap,
    //                 highestPoint,
    //                 lowestPoint
    //             }

    //             worker.postMessage(payload);
    //         })
    //         .catch(error =>  {
    //            console.error(error);
    //            setLoading(true);
    //        });

    // }, [candleSize.clusterSize]);

    useEffect(() => {
        (chartContainer.current as HTMLElement).scrollLeft = scrollLeft;
        (timeContainer.current as HTMLElement).scrollLeft = scrollLeft;
        updateClosePosition();
    }, [scrollLeft]);

    useEffect(() => {
        if (scale === scaleIndex) {
            return;
        }

        setScaleIndex(scale);
    }, [scale])

    useEffect(() => {
        const candleSize = getCandleSize(scaleIndex, chartStyle, highestPoint, lowestPoint);
        setCandleSize(candleSize);
    }, [chartStyle])

    useEffect(() => {
        if (workerSubscribed) {
            return;
        }

        setWorkerSubscribed(true);

        worker.onmessage = (event: any) => {
            const data = event.data;
            console.log('SUCCESS EVENT: ', data.type);

            if (data.type === ChartEvent.InitCandlesDataSuccess) {
                setInitalCandlesData(data);
            }

            if (data.type === ChartEvent.AddNewCandlesSuccess) {
                addNewCandles(data)
            }

            else if (data.type === ChartEvent.HandleChartScaleSuccess) {
                setScaleResults(data);
            }

            else if (data.type === ChartEvent.HandleChartScrollingSuccess) {
                setScrollingResults(data)
            }

            else if (data.type === ChartEvent.HandleModeChangeSuccess) {
                setModeChangeResults(data)
            }

            else if (data.type === ChartEvent.HandleNewExtremumsSuccess) {
                setNewExtremumsResults(data)
            }

            else if (data.type === ChartEvent.HandleChartZoomSuccess) {
                setZoomChangeResults(data)
            }

            else if (data.type === ChartEvent.UpdateClustersSuccess) {
                updateClusterTiles(data)
            }
        }
    }, [workerSubscribed, setScrollingResults, setZoomChangeResults]);

    const renderContent = () => {
        return (
            <svg id='mainChart'
                className={`${chartStyle} cluster-chart`}
                width={CANVAS_WIDTH}
                height={CANVAS_HEIGHT}
                ref={chart}
                onMouseMove={handleChartMouseMove}>
                {[
                    [cursorPositionX, 0, cursorPositionX, '100%'],
                    [0, cursorPositionY, '100%', cursorPositionY]
                ].map(([x1, y1, x2, y2], index) => <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="cornflowerblue" key={index} strokeDasharray="4"></line>)}

                {isJapaneeseChartVisible(chartStyle) &&
                    <JapaneeseCandleChart
                        candles={candles}
                        candleSize={candleSize}></JapaneeseCandleChart>
                }
                {isSnakeChartVisible(chartStyle) &&
                    <SnakeCandleChart
                        candleSize={candleSize}
                        candles={candles}></SnakeCandleChart>
                }
                {isLineChartVisible(chartStyle) &&
                    <LineChart lineChartData={lineChartData}></LineChart>
                }
                {isClusterChartVisible(chartStyle) &&
                    <ClusterChart candles={candles}
                        scaleIndex={scaleIndex}
                        candleSize={candleSize}></ClusterChart>
                }
            </svg>

        )
    }

    const renderTimeScale = () => {
        return (
            <svg className="time-wrapper" width={CANVAS_WIDTH} >
                {timeValues.map((time, index) => {
                    return (
                        <text key={index}
                            x={time.positionX}
                            y={TIME_VALUE_Y}
                            className="time-value">
                            {time.value}
                        </text>
                    )
                })}

                <svg x={hoveredCandlePosition} width="100" height="20">
                    <rect x="0" y="0" width="100" height="20" fill="#49B6FF" rx="3px" />
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="time-value hovered">{hoveredCandleTime}</text>
                </svg>
            </svg>
        )
    }

    const renderPriceScale = () => {
        return (
            <svg className="price-wrapper" style={{ height: CANVAS_HEIGHT + 'px' }}>
                <defs>
                    <filter x="0" y="0" width="1" height="1" id="solid">
                        <feFlood floodColor="red" result="bg" />
                        <feMerge>
                            <feMergeNode in="bg" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>

                {priceValues.map((price, index) => {
                    return (
                        <text key={index}
                            y={price.positionY}
                            x={PRICE_VALUE_X}
                            className="price-value">
                            {price.value}
                        </text>
                    )
                })}
                <svg y={closePricePosition} className="price-container" width="70" height="20">
                    <rect className="price-indicator" x="0" y="0" />
                    <text x="50%" y="45%" dominantBaseline="central" textAnchor="middle" className="price-value">{closePrice}</text>
                </svg>
                <svg y={latestPricePosition} className="price-container" width="70" height="20">
                    <rect className="price-indicator latest" x="0" y="0" />
                    <text x="50%" y="45%" dominantBaseline="central" textAnchor="middle" className="price-value">{latestPrice}</text>
                </svg>
            </svg>
        )
    }

    const renderMaintenance = () => {
        return (
            <>
                {maintenance &&
                    <div className="maintenance-container">
                        <div className="maintenance-message">Temporarily Down for Maintenance</div>
                        <img src={MaintenanceIcon} alt="maintenance" />
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {maintenance && renderMaintenance()}
            {!maintenance &&
                <>
                    <div className={"main-container " + (loading ? 'loading' : '')}
                        style={{ width: containerWidth + 'px' }}
                        onMouseMove={canvasMouseMoveHandler}
                        onMouseUp={canvasMouseUpHandler}
                        onMouseLeave={canvasMouseUpHandler}>
                        <div className='loader'></div>
                        <div className="top-bar">{headerTitle}</div>
                        <div className={"static-mode-togler " + (staticModeActive ? 'active' : '')} onClick={toggleStaticMode}>
                            <img src={StaticModeIcon} alt="staticMode" />
                        </div>
                        <div id="timeContainer"
                            className="time-container"
                            ref={timeContainer}
                            onWheel={mousewheelChartHandler}
                            onMouseDown={timeMouseDownHandler}>
                            {renderTimeScale()}
                        </div>
                        <div className="price-container"
                            ref={priceContainer}
                            style={{ height: viewportHeight + 'px' }}
                            onWheel={mousewheelPriceHandler}
                            onMouseDown={priceMouseDownHandler}
                            id="priceContainer">
                            {renderPriceScale()}
                        </div>
                        <div className={`main-chart-container ${selectedClusterSetting}`}
                            ref={chartContainer}
                            id="mainChartContainer"
                            style={{ width: viewPortWidth + 'px', height: viewportHeight + 'px' }}
                            onWheel={mousewheelChartHandler}
                            onMouseDown={chartMouseDownHandler}>
                            {renderContent()}
                        </div>
                    </div>

                    <div className='chart-settings-togler' onClick={toggleSettingsMode}>
                        <img src={settingsIcon} alt="settings" />
                    </div>

                    {chartSettingsMenu.isComponentVisible && <div className="chart-controls" ref={chartSettingsMenu.componentRef}>
                        <div className="row-container">
                            <span className="description title">{t('Components:Components.CursorSettings.CursorSettings')}</span>
                        </div>
                        {
                            chartSettingsData.map((chartSetting) => {
                                return (
                                    <div className="row-container" key={chartSetting.id}>
                                        {chartSetting.l10nKey && <span className="description">{t(chartSetting.l10nKey)}</span>}
                                        <Select
                                            items={chartSetting.childs || []}
                                            value={getSelectedSetting(chartSetting)}
                                            size={ESelectSize.SM}
                                            formatter={getSettingTitle}
                                            onSelect={handleSelectSettings} />
                                    </div>
                                )
                            })
                        }

                        {/* <img src={settingsIcon} alt="settings" onClick={toggleSettingsMode} /> */}
                        {/* <Switch
                            isChecked={scrollModeActive}
                            onClick={() => setScrollModeActive(!scrollModeActive)}
                            titleOn={scrollLabel}
                            titleOff={zoomLabel} /> */}
                        {/* <div className="row-container">
                            <img src={VerticalScroll} alt="scroll" />
                            <Switch
                                isChecked={staticModeActive}
                                onClick={toggleStaticMode}
                                titleOn={staticLabel}
                                titleOff={floatLabel} />
                            </div>
                            <img className="chart-to-start" src={ChartToStart} alt="scroll" onClick={scrollToEnd} /> 
                        */}
                    </div>
                    }
                </>
            }
        </>
    )
}

