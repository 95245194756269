import React from 'react';
import {useTranslation} from "react-i18next";

import "./SoonSticker.scss";

/* Наклейка "Скоро станет доступно".*/
export const SoonSticker: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div className="soon-sticker-container-container">
            <span className="label">{t('Common:Common.Components.SoonSticker.label')}</span>
        </div>
    );
}

SoonSticker.displayName = 'SoonSticker';
