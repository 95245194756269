// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-claster-candle {
  width: 200px;
  height: 200px;
  margin-right: 200px;
}
.table-claster-candle .claster {
  height: 24px;
  rx: 4;
  cx: 4;
}
.table-claster-candle .counter {
  font-weight: 500;
  fill: white;
  font-size: 14px;
  transform: none;
}
.table-claster-candle .counter.ask {
  fill: #F10D0C;
}
.table-claster-candle .counter.bid {
  text-anchor: end;
  fill: #39AB1D;
}`, "",{"version":3,"sources":["webpack://./src/Components/Charts/ClusterCharts/TableCluster/TableCluster.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;EACA,KAAA;EACA,KAAA;AACR;AAEI;EACI,gBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AAAR;AAEQ;EACI,aAAA;AAAZ;AAGQ;EACI,gBAAA;EACA,aAAA;AADZ","sourcesContent":[".table-claster-candle {\n    width: 200px;\n    height: 200px;\n    margin-right: 200px;\n    \n    .claster {\n        height: 24px;\n        rx: 4;\n        cx: 4;\n    }\n\n    .counter {\n        font-weight: 500;\n        fill:white;\n        font-size: 14px;\n        transform: none;\n\n        &.ask {\n            fill:#F10D0C;\n        }\n\n        &.bid {\n            text-anchor: end;\n            fill: #39AB1D;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
