import { combineReducers } from "redux";
import {appReducer} from "./appReducers";
import {instrumentManagerReducer} from "./instrumentManagerReducers";
import {financialReducer} from "./financialReducer";

export const rootReducer = combineReducers({
    app: appReducer,
    instrumentManager: instrumentManagerReducer,
    financialOptions: financialReducer,
})
