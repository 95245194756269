import React from "react";

import './AskBidGroup.scss';

interface OwnProps {
    buy?: boolean
    sell?: boolean
    price: number
    currency?: string
}

export const AskBidGroup: React.FC<OwnProps> = ({
                                                    buy,
                                                    sell,
                                                    price,
                                                    currency}) => {

    const isBuy = buy && !sell;
    const topLabel = isBuy ? "BUY MARKET" : "SELL MARKET";

    return (
        <div className={"ask-bid-group " + (isBuy ? 'buy' : 'sell')}>
            <button className="big-button">
                <span className="top-label">{topLabel}</span>
                <span className="price">{price}</span>
                {currency && (<span className="currency">{currency}</span>)}
            </button>
            <div className="small-buttons">
                <button className="ask-button">ASK</button>
                <div className="hr"></div>
                <button className="bid-button">BID</button>
            </div>
        </div>
    )
}