import React, { useEffect, useState } from 'react'
import { ClasterCandleData, LineChartData } from '../../data';
import "./SolidProfileChart.scss";

//@ts-ignore
Array.prototype.hasMin = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] < curr[attrib] ? prev : curr;
    })) || null;
}

//@ts-ignore
Array.prototype.hasMax = function (attrib) {
    return (this.length && this.reduce(function (prev, curr) {
        return prev[attrib] > curr[attrib] ? prev : curr;
    })) || null;
}

export interface Claster {
    ask: number;
    bid: number;
    inside: boolean;
}

const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;

const getClasterWrapperPath = (data: Claster[], startX: number, endX: number, elementHeight: number, all?: boolean): string => {
    let path = ""
    let startPoitionY: number | null = null;
    let endPositionY: number | null = null;

    data.forEach((element, index) => {
        if (element.inside && startPoitionY == null) {
            startPoitionY = index * elementHeight;
        }

        if (!element.inside && startPoitionY != null) {
            endPositionY = index * elementHeight;
        }
    })

    if (all) {
        startPoitionY = 0;
        endPositionY = data.length * elementHeight;
    }

    path +=
        + startX + "," + startPoitionY + " "
        + endX + "," + startPoitionY + " "
        + endX + "," + endPositionY + " "
        + startX + "," + endPositionY + " "
        + startX + "," + startPoitionY + " ";

    return path;
}

const getHighestSumClaster = (data: Claster[]): number => {
    let highest = 0;

    data.forEach((element) => {
        let sum = element.ask + element.bid;

        if (sum > highest) {
            highest = sum;
        }
    })

    return highest;
}

interface IProps {
}

export const SolidProfileChart: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);
    const [highestSumClaster, setHighestSumClaster] = useState(0);

    let lineChartPath = "M";

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const openX = i * CHART_OFFSET;

        lineChartPath += openX + "," + openY + " ";
    });


    useEffect(() => {
        setCanvasHeight(240 - CANVAS_PADDING * 2)
        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);

        const highestSum = getHighestSumClaster(ClasterCandleData);
        setHighestSumClaster(highestSum);
    }, [])

    return (
        <>

            <svg className="profile-chart">
                <polygon fill="#4E4E4E" points={getClasterWrapperPath(ClasterCandleData, 0, 2, 25, true)}></polygon>
                <polygon fill="#FF0000" points={getClasterWrapperPath(ClasterCandleData, 2, 65, 25)}></polygon>
                {ClasterCandleData.map((item, index) => {
                    if (canvasHeight === 0) {
                        return;
                    }

                    const y = index * 25;
                    const width = 100 / highestSumClaster * (item.ask + item.bid);
                    const opacity = width / 100;
                    const isGoingUp = item.ask < item.bid;
                    const color = isGoingUp ? "#1E4F8C" : "#FF0000";

                    return (
                        <>
                            <text className="counter" x="2" y={y + 17}>{item.ask} x {item.bid}</text>
                            <rect className="claster" style={{ opacity: opacity }} fill={color} x={65} y={y} width={width} />
                        </>
                    )
                })}
            </svg>
        </>
    )
}

SolidProfileChart.displayName = 'ClassicProfileChart'
