import React, { useEffect, useState } from 'react'
import { japaneseCandleData, LineChartData, SnakeChartData } from '../../data';
import "./StunnerTransparentCandle.scss";


const CANVAS_PADDING = 20;
const CANDLE_WIDTH = 6
const CANDLE_CONNECTOR_WIDTH = 16;
const SPACE_BEETFWEEN_CANDLES = 4;
const CANDLE_OFFSET = CANDLE_WIDTH + SPACE_BEETFWEEN_CANDLES
const CHART_OFFSET = ((CANDLE_CONNECTOR_WIDTH - CANDLE_WIDTH) * 2) + CANDLE_OFFSET;
const LINE_CHART_WIDTH = 400;

interface IProps {

}

export const StunnerTransparentCandle: React.FC<IProps> = ({ }: IProps) => {
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [lowest, setLowest] = useState(0);
    const [highest, setHighest] = useState(0);

    let lineChartPath = "M";
    let lineChartUpGradientPath = "";
    let lineChartBelowGradientPath = "";
    const borderLinePositionY = 70;

    const calculateY = (value: number) => {
        return (value - lowest) * canvasHeight / (highest - lowest)
    }

    LineChartData.forEach((e, i) => {
        const openY = calculateY(e.open);
        const closeY = calculateY(e.close);
        const highY = calculateY(e.high);
        const lowY = calculateY(e.low);
        const openX = i * CHART_OFFSET;
        const closeX = i * CHART_OFFSET;
        const isLastElement = i === LineChartData.length - 1;

        lineChartPath += openX + "," + openY + " ";

        if (openY < borderLinePositionY) {
            lineChartBelowGradientPath += openX + "," + openY + " ";
            lineChartUpGradientPath += openX + "," + borderLinePositionY + " ";
        } else {
            lineChartUpGradientPath += openX + "," + openY + " ";
            lineChartBelowGradientPath += openX + "," + borderLinePositionY + " ";
        }

        if (isLastElement) {
            lineChartBelowGradientPath += openX + "," + borderLinePositionY + " ";
            lineChartBelowGradientPath += 0 + "," + borderLinePositionY + " ";

            lineChartUpGradientPath += openX + "," + borderLinePositionY + " ";
            lineChartUpGradientPath += 0 + "," + borderLinePositionY + " ";
        }
    });

    useEffect(() => {

        //setWidth(snakeChartData.length * (CHART_OFFSET) + CANVAS_PADDING)

        // @ts-ignore
        setLowest(LineChartData.hasMin("low").low);
        // @ts-ignore
        setHighest(LineChartData.hasMax("high").high);


        setCanvasHeight(240 - CANVAS_PADDING * 2)
    }, [])

    return (
        <>
            <svg className="candle-chart">
                    {japaneseCandleData.map((item, index) => {
                        if (canvasHeight === 0) {
                            return;
                        }

                        const lowY = calculateY(item.low)
                        const highY = calculateY(item.high)
                        const openY = calculateY(item.open)
                        const closeY = calculateY(item.close)
                        const isGoingUp = openY < closeY;
                        const bgColor = isGoingUp ? 'green' : 'red';
                        const candleHeight = isGoingUp ? closeY - openY : openY - closeY;
                        const candleY = isGoingUp ? openY + CANVAS_PADDING : closeY + CANVAS_PADDING;
                        const highConnectorHeight = isGoingUp ? highY - closeY : highY - openY;
                        const lowConnectorHeight = isGoingUp ? openY - lowY : closeY - lowY;
                        const highConnectorY = isGoingUp ? closeY + CANVAS_PADDING : openY + CANVAS_PADDING;
                        const lowConnectorY = lowY + CANVAS_PADDING;

                        return (
                            <>
                                <rect className={`element red`}
                                    x={CANVAS_PADDING + index * CHART_OFFSET}
                                    y={lowConnectorY}
                                    height={lowConnectorHeight} />
                                <rect className={`element green`}
                                    x={CANVAS_PADDING + index * CHART_OFFSET}
                                    y={highConnectorY}
                                    height={highConnectorHeight} />

                                <rect className={`element candle empty ${bgColor}`}
                                    x={CANVAS_PADDING + index * CHART_OFFSET - 6}
                                    y={candleY}
                                    height={candleHeight} />
                            </>
                        )
                    })}
                </svg>
        </>
    )
}

StunnerTransparentCandle.displayName = 'StunnerTransparentCandle'