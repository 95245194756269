import i18n, { Resource } from "i18next";
import {initReactI18next} from "react-i18next";
import Components from './ru/Components.json'
import Modules from './ru/Modules.json'
import Common from './ru/Common.json'
import TradingConnections from './ru/TradingConnections.json'
import UserManagement from './ru/UserManagement.json'

const resources: Resource = {
    ru: {
        Components,
        Modules,
        Common,
        TradingConnections,
        UserManagement,
    }
}

i18n.use(initReactI18next).init({
    lng: 'ru',
    resources
})

export default i18n;
