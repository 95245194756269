// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-switcher {
  display: inline-flex;
}
.button-switcher button.blue-button, .button-switcher button.golden-button {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.button-switcher.horizontal:not(.wrap) button.blue-button:first-child, .button-switcher.horizontal:not(.wrap) button.golden-button:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.button-switcher.horizontal:not(.wrap) button.blue-button:last-child, .button-switcher.horizontal:not(.wrap) button.golden-button:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.button-switcher.vertical {
  flex-direction: column;
}
.button-switcher.vertical:not(.wrap) button.blue-button:first-child, .button-switcher.vertical:not(.wrap) button.golden-button:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.button-switcher.vertical:not(.wrap) button.blue-button:last-child, .button-switcher.vertical:not(.wrap) button.golden-button:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.button-switcher.wrap {
  flex-wrap: wrap;
}
.button-switcher.wrap > button {
  flex: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Switcher/Switcher.scss","webpack://./src/Common/Styles/metrics.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;AAGE;EACE,8BAAA;EACA,2BAAA;EACA,+BAAA;EACA,4BAAA;AADJ;AAOQ;EACE,8BCXU;EDYV,2BCZU;ADOpB;AAQQ;EACE,+BChBU;EDiBV,4BCjBU;ADWpB;AAYE;EACE,sBAAA;AAVJ;AAcQ;EACE,2BC7BU;ED8BV,4BC9BU;ADkBpB;AAeQ;EACE,8BClCU;EDmCV,+BCnCU;ADsBpB;AAmBE;EACE,eAAA;AAjBJ;AAmBI;EACE,UAAA;AAjBN","sourcesContent":["@import '../../Common/Styles/metrics';\n\n.button-switcher {\n  display: inline-flex;\n\n  button.blue-button, button.golden-button {\n    border-bottom-left-radius: 0px;\n    border-top-left-radius: 0px;\n    border-bottom-right-radius: 0px;\n    border-top-right-radius: 0px;\n  }\n\n  &.horizontal {\n    &:not(.wrap) {\n      button.blue-button, button.golden-button {\n        &:first-child {\n          border-bottom-left-radius: $btn-border-radius;\n          border-top-left-radius: $btn-border-radius;\n        }\n\n        &:last-child {\n          border-bottom-right-radius: $btn-border-radius;\n          border-top-right-radius: $btn-border-radius;\n        }\n      }\n    }\n  }\n\n  &.vertical {\n    flex-direction: column;\n\n    &:not(.wrap) {\n      button.blue-button, button.golden-button {\n        &:first-child {\n          border-top-left-radius: $btn-border-radius;\n          border-top-right-radius: $btn-border-radius;\n        }\n\n        &:last-child {\n          border-bottom-left-radius: $btn-border-radius;\n          border-bottom-right-radius: $btn-border-radius;\n        }\n      }\n    }\n  }\n\n  &.wrap {\n    flex-wrap: wrap;\n\n    & > button {\n      flex: auto;\n    }\n  }\n}","// common component metrics\n$navigation-menu-width: 110px;\n$header-menu-height: 40px;\n$cluster-bar-width: 37px;\n\n$btn-border-radius: 4px;\n$btn-border-radius-big: 10px;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
