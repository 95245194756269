import { ChooseButton } from '../../../Common/Components/ChooseButton/ChooseButton';
import React from 'react'

import './ChartGroup.scss'

/**
 * .
 *
 * @prop chartTypeData .
 */
interface IProps {
    chartTypeData: any;
    selectItem: Function;
}

export const ChartGroup: React.FC<IProps> = ({chartTypeData, selectItem}:IProps) => {
    const renderSubGroup = (item:{ title : string, optionTitle: string, cssClass?: string, subOptions: any[]}) => {
        return (
            <div className="chart-group-sub-options">
                <span>{item.optionTitle}:</span>
                <div className="chart-group-sub-options-buttons-group">
                    {item.subOptions.map((item: {title: string, cssClass?: string}) => <ChooseButton cssClass={item.cssClass} buttonText={item.title} onClick={() => selectItem(item)} />)}
                </div>
            </div>
        )
    }
    return (
        <div className="chart-group">
            {chartTypeData.options.map((item: any) => renderSubGroup(item))}
        </div>
    )
}

ChartGroup.displayName = 'ChartGroup'
