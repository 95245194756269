import moment, { Moment } from "moment";
import { CandleDirection, CandleSize, ClusterSegment, ClusterViewType, DateRange, Extremums, PriceValue, TimeValue, UCandle, UClusterElement, newCandlesData } from "../../CandlesClient/models/Candle";
import { convertPriceValue } from "../../Helpers/app-helper";
import { ChartStyle } from "../../Store/models";
import {
    CANVAS_PADDING,
    CANDLE_OFFSET,
    CANDLE_WIDTH,
    CANDLE_TAIL_WIDTH,
    CLUSTER_MULTI_SEGMENT_SCALE,
    CLUSTER_SINGLE_SEGMENT_SCALE,
    CANVAS_HEIGHT,
    PRICE_ELEMENTS_COUNT,
    SNAKE_CONNECTOR_WIDTH,
    SNAKE_WIDTH,
    SNAKE_OFFSET,
    MIN_CLUSTER_HEIGHT,
    WINDOW_COUNT,
    TimeFrame,
    CLUSTER_SCALE,
    PRICE_FLOAT_MIN_VALUE,
    PRICE_MIN_TICK
} from "./constants";

export const ResponseDataMapper = {
    cluster: {
        timeClose: 0,
        high: 2,
        low: 1,
        open: 3,
        close: 4,
        clusterData: 5
    },
    candle: {
        timeClose: 0,
        high: 2,
        low: 3,
        open: 4,
        close: 5,
        clusterData: null
    }
}

export const debounce = (() => {
    let calls: any = {};

    return (callback: Function, delayMS: number = 500, id: string = "duplicated event") => {
        if (calls[id]) {
            clearTimeout(calls[id]);
        }

        calls[id] = setTimeout(callback, delayMS);
    };
})();

export const throttle = (callback: Function, delayMS: number = 100) => {
    let flag = true;

    return () => {
        if (flag) {
            callback();
            flag = false;
            setTimeout(() => flag = true, delayMS);
        }
    }
}


const getHighestSum = (candle: UCandle): number => {
    let highest = 0;


    if (candle.clusterData == null) {
        return 0;
    }

    candle.clusterData.forEach(data => {
        const sum = data[3] + data[4];

        if (sum > highest) {
            highest = sum;
        }
    });

    return highest;
}

const getClusterElements = (candle: UCandle, candleIndex: number, highest: number, lowest: number, scaleIndex: number, clusterSize: number, spaceGap: number): UClusterElement[] => {
    const highestSum = getHighestSum(candle);
    let clusters: UClusterElement[] = [];

    let highestAsk = 0;
    let highestBid = 0;

    if (candle.clusterData == null) {
        return [];
    }

    candle.clusterData.forEach(data => {
        const priceValue = data.price_level;
        const bid = data.bid_volume;
        const ask = data.ask_volume;
        const isGoingUp = bid > ask;
        const indicatorWidth = ask + bid == 0 ? 0 : 100 / highestSum * (ask + bid);
        const opacityCoef = indicatorWidth / 100;
        const counterPositionX = calculateX(candleIndex, scaleIndex, CANDLE_OFFSET, spaceGap);
        const counterPositionY = calculateY(priceValue, highest, lowest);

        if (ask > highestAsk) {
            highestAsk = ask;
        }

        if (bid > highestBid) {
            highestBid = bid;
        }


        clusters.push({
            ask: ask,
            bid: bid,
            value: convertPriceValue(ask + bid),
            isGoingUp: isGoingUp,
            color: isGoingUp ? "#1E4F8C" : "#FF0000", //ToDo: move to constants
            indicatorWidth: indicatorWidth,
            opacityCoef: opacityCoef,
            counterPositionX: counterPositionX,
            counterPositionY: counterPositionY,
            indicatorPositionX: 0,
            indicatorPositionY: 0,
            priceValue: priceValue,
            insideBody: (priceValue >= candle.open && priceValue <= candle.close) || (priceValue <= candle.open && priceValue >= candle.close)
        });
    });

    //return clusters;

    clusters = fillEmptyClusterParts(clusters, candle, candleIndex, highest, lowest, scaleIndex, clusterSize, spaceGap);

    return getClusterSegments(clusters, scaleIndex, highestAsk, highestBid, highestSum);
}

const getClusterSegments = (clusters: UClusterElement[], scaleIndex: number, highestAsk: number, highestBid: number, highestSum: number): UClusterElement[] => {
    if (scaleIndex >= CLUSTER_MULTI_SEGMENT_SCALE) {
        const askSegments = [];
        const bidSegments = [];
        let askIndex = 0;
        let bidIndex = 0;

        clusters.forEach((cluster, index) => {
            const aksBid = cluster.ask + cluster.bid;
            // if (cluster.ask > 0) {
            //     cluster.AskSegments = [{
            //         color: cluster.ask > 1 ? "#FDE725" : "#114040", //ToDo: move to constants
            //         width:  cluster.ask > 1 ? 50 : 3, //set adaptive with
            //         opacity: 1,
            //         height: 10,
            //         positionX:  cluster.ask > 1 ? cluster.counterPositionX - 57 : cluster.counterPositionX - 10  
            //     }]
            // }

            // if (cluster.bid > 0) {
            //     cluster.BidSegments = [{
            //         color: cluster.bid > 1 ? "#FDE725" : "#114040", //ToDo: move to constants
            //         width:  cluster.bid > 1 ? 50 : 3, //set adaptive with
            //         opacity: 1,
            //         height: 10,
            //         positionX:  cluster.counterPositionX + 25
            //     }]
            // }

            if (aksBid > 0) {
                if (aksBid < 5) {
                    cluster.AskSegments = getSmallSegments(aksBid, cluster.counterPositionX + 18);
                } else {
                    const c = Math.floor(Math.random() * 5) + 1;

                    cluster.AskSegments = getSmallSegments(c, cluster.counterPositionX + 18);
                    cluster.AskSegments.push({
                        color: "#3690FF", //ToDo: move to constants
                        width: ((CANDLE_OFFSET * scaleIndex - 45) / 100) * (aksBid / highestSum * 100), //set adaptive with
                        opacity: 1,
                        positionX: cluster.counterPositionX + 18 + c * 4
                    });
                }
            }

        });

        return clusters;
    }

    if (scaleIndex >= CLUSTER_SINGLE_SEGMENT_SCALE) {
        clusters.forEach((cluster, index) => {
            const aksBid = cluster.ask + cluster.bid;
            cluster.Segment = {
                color: aksBid < (highestSum / 2) ? "#3690FF" : "#FDE725", //ToDo: move to constants
                width: CANDLE_OFFSET * scaleIndex - 30, //set adaptive with
                opacity: (100 / highestSum * (aksBid)) / 100 || 0.1,
                positionX: cluster.counterPositionX + 10
            }
        });


        return clusters;
    }

    return clusters;
}

const getSmallSegments = (askBid: number, x: number) => {
    const segments: ClusterSegment[] = [];

    for (let index = 0; index < askBid; index++) {
        segments.push(
            {
                color: "#114040", //ToDo: move to constants
                width: 3, //set adaptive with
                opacity: 1,
                positionX: x + index * 4
            }
        )
    }

    return segments;
}

const fillEmptyClusterParts =
    (
        clusters: UClusterElement[],
        candle: UCandle,
        candleIndex: number,
        highest: number,
        lowest: number,
        scaleIndex: number,
        clusterSize: number,
        spaceGap: number
    ): UClusterElement[] => {
        const result: UClusterElement[] = [];

        let price = clusters[0].priceValue;

        while (price < candle.high) {
            let cluster = clusters.find(c => c.priceValue === price);

            if (cluster == null) {
                cluster = {
                    ask: 0,
                    bid: 0,
                    value: '0',
                    isGoingUp: candle.isGoingUp,
                    color: candle.isGoingUp ? "#1E4F8C" : "#FF0000",
                    indicatorWidth: 0,
                    opacityCoef: 0,
                    counterPositionX: calculateX(candleIndex, scaleIndex, CANDLE_OFFSET, spaceGap),
                    counterPositionY: calculateY(price, highest, lowest),
                    indicatorPositionX: 0,
                    indicatorPositionY: 0,
                    priceValue: price,
                    insideBody: (price >= candle.open && price <= candle.close) || (price <= candle.open && price >= candle.close)
                };
            }

            result.push(cluster);
            price += clusterSize;
        }

        return result;
    }

export const calculateY = (value: number, highest: number, lowest: number): number => {
    if (value == 0) {
        return 0;
    }

    return +((highest - value) * CANVAS_HEIGHT / (highest - lowest)).toFixed(1);
}

export const calculateX = (index: number, scaleIndex: number, elementWidth: number, spaceGap: number): number => {
    return +(CANVAS_PADDING + index * (elementWidth * scaleIndex)).toFixed(1) + spaceGap;
}

export const getCandleWidth = (scaleIndex: number): number => {
    return +(CANDLE_WIDTH * scaleIndex).toFixed(2);
}

export const getTailWidth = (scaleIndex: number, chartStyle?: ChartStyle): number => {
    const width = chartStyle === ChartStyle.japaneseSmooth ||
        chartStyle === ChartStyle.japaneseSquare ||
        chartStyle === ChartStyle.japaneseBrightShadow ||
        chartStyle === ChartStyle.japaneseBright ||
        chartStyle === ChartStyle.japaneseBlackLight ? CANDLE_WIDTH : CANDLE_TAIL_WIDTH; //for that styles tail width must be euqal candle width by design

    return +(width * scaleIndex).toFixed(2);
}

export const getScrolledCanlesCount = (scrollLeft: number, scaleIndex: number) => {
    return scrollLeft < CANVAS_PADDING ? 0 : Math.floor((scrollLeft - CANVAS_PADDING) / (CANDLE_OFFSET * scaleIndex));
}

export const getCandleSize = (scaleIndex: number, chartStyle: ChartStyle, highestPoint: number, lowestPoint: number): CandleSize => {
    const clusterSize = getClusterSize(highestPoint, lowestPoint, 1);

    return {
        candleWidth: getCandleWidth(scaleIndex),
        tailWidth: getTailWidth(scaleIndex, chartStyle),
        snakeWidth: SNAKE_WIDTH * scaleIndex,
        connectorWidth: SNAKE_CONNECTOR_WIDTH * scaleIndex,
        clusterHeight: clusterSize / ((highestPoint - lowestPoint) / CANVAS_HEIGHT),
        clusterSize
    }
}

export const getClusterSize = (highestPoint: number, lowestPoint: number, size: number): number => {
    const height = size / ((highestPoint - lowestPoint) / CANVAS_HEIGHT);

    if (height <= MIN_CLUSTER_HEIGHT) {
        return getClusterSize(highestPoint, lowestPoint, size * 2);
    }

    return size;
}

export const calculateCandleData = (candles: UCandle[], highest: number, lowest: number, scaleIndex: number, candleSize: CandleSize, spaceGap: number): UCandle[] => {
    candles.forEach((c, i) => {
        c.lowY = calculateY(c.low, highest, lowest);
        c.highY = calculateY(c.high, highest, lowest);
        c.openY = calculateY(c.open, highest, lowest);
        c.closeY = calculateY(c.close, highest, lowest);
        c.isGoingUp = c.openY > c.closeY;
        c.direction = c.isGoingUp ? CandleDirection.goingUp : CandleDirection.goingDown;
        c.candleHeight = c.isGoingUp ? c.openY - c.closeY : c.closeY - c.openY;
        c.candleY = c.isGoingUp ? c.closeY : c.openY;
        c.bodyyX = calculateX(i, scaleIndex, CANDLE_OFFSET, spaceGap);
        c.candleX = c.bodyyX - (candleSize.candleWidth - candleSize.tailWidth) / 2;
        c.topTailHeight = c.isGoingUp ? c.closeY - c.highY : c.openY - c.highY;
        c.bottomTailHeight = c.isGoingUp ? c.lowY - c.openY : c.lowY - c.closeY;
        c.topTailY = c.highY;
        c.bottomTailY = c.isGoingUp ? c.openY : c.closeY;
        c.openConnectorX = calculateX(i, scaleIndex, SNAKE_OFFSET, spaceGap);
        c.snakeX = c.openConnectorX + SNAKE_CONNECTOR_WIDTH;
        c.closeConnectorX = c.snakeX + candleSize.snakeWidth;
        c.snakeHeight = c.lowY - c.highY; // svg chart is 'reverted' by Y scale
        c.snakeY = c.highY;

        if (c.bottomTailHeight < 0 || c.topTailHeight < 0 || c.candleHeight < 0) {
            console.error(i, c);
        }
    });

    return candles;
}

export const calculateClusterData = (candles: UCandle[], scaleIndex: number, highest: number, lowest: number, spaceGap: number): UCandle[] => {
    const clusterSize = getClusterSize(highest, lowest, 1);

    candles.forEach((candle, index) => {
        candle.clusterElements = getClusterElements(candle, index, highest, lowest, scaleIndex, clusterSize, spaceGap);
    });

    return candles;
}

export const getClusterViewType = (scaleIndex: number): ClusterViewType => {
    if (scaleIndex >= CLUSTER_MULTI_SEGMENT_SCALE) {
        return ClusterViewType.multi;
    }

    if (scaleIndex >= CLUSTER_SINGLE_SEGMENT_SCALE) {
        return ClusterViewType.single;
    }

    return ClusterViewType.default;
}

const getShiftedStartPrice = (values: PriceValue[], bottomLimit: number, stepValue: number): number => {
    let startValue = values[0]?.value || 0;

    if (startValue > bottomLimit) {
        while (startValue > bottomLimit) {
            startValue -= stepValue;
        }
    } else {
        while (startValue < bottomLimit) {
            startValue += stepValue;
        }
    }

    return +startValue.toFixed(1);
}


//method returns prices for right panel\scale on the chart
export const getPriceValues = (lowestPoint: number, highestPoint: number, canvasHeight: number, scrollTop: number, values?: PriceValue[]): PriceValue[] => {
    if (lowestPoint == 0 && highestPoint == 0) {
        return [];
    }
    const priceValues: PriceValue[] = [];
    let iterator = 0;

    const pixelsPerpoint = CANVAS_HEIGHT / (highestPoint - lowestPoint);
    const gap = canvasHeight / pixelsPerpoint;
    const topLimit = highestPoint - (scrollTop / pixelsPerpoint) + gap;
    const bottomLimit = highestPoint - (scrollTop / pixelsPerpoint) - (2 * gap);

    let stepValue = values == null ? (topLimit - bottomLimit) / PRICE_ELEMENTS_COUNT : values[1]?.value - values[0]?.value;
    const startValue = values == null ? bottomLimit : getShiftedStartPrice(values, bottomLimit, stepValue);

    if (stepValue < PRICE_MIN_TICK) {
        stepValue = PRICE_MIN_TICK;
    }

    do {
        const value = +(startValue + iterator * stepValue).toFixed(PRICE_FLOAT_MIN_VALUE);

        priceValues.push(
            {
                value: value,
                positionY: calculateY(value, highestPoint, lowestPoint)
            });
        iterator++;
    } while (iterator < PRICE_ELEMENTS_COUNT);

    return priceValues;
}

export const getTimeValues = (candles: UCandle[], scaleIndex: number, spaceGap: number): TimeValue[] => {
    const timeValues: TimeValue[] = [];
    const skipFrequency = 2;

    candles?.forEach((candle, candleIndex) => {
        //const date = new Date(candle.timeClose);

        if (candle.timeClose == null) {
            return;
        }

        if (candleIndex * scaleIndex % skipFrequency != 0) { //skip time values for UI
            return;
        }

        timeValues.push({ positionX: calculateX(candleIndex, scaleIndex, CANDLE_OFFSET, spaceGap), value: candle.timeClose.toString().slice(-5) }); //.slice(-5)
    })

    return timeValues;
}

export const calculateCandles = (
    candles: UCandle[],
    scaleIndex: number,
    highest: number,
    lowest: number,
    spaceGap: number,
    candleSize: CandleSize): UCandle[] => {
    return calculateCandleData(candles, highest, lowest, scaleIndex, candleSize, spaceGap);

}

export const getScrolledCandlesCount = (scaleIndex: number, scrollLeft: number, nextScrollLeft: number): number => {
    if (scrollLeft === nextScrollLeft) {
        return 0;
    }

    let dif = nextScrollLeft - scrollLeft;

    if (dif < 0) {
        dif = dif * -1;
    }

    const candleWidth = CANDLE_OFFSET * scaleIndex;

    return Math.ceil(dif / candleWidth);
}

export const addNewUICandles = (
    candles: UCandle[],
    candlesToAdd: UCandle[],
    spaceGap: number,
    scaleIndex: number,
    direction?: boolean
): newCandlesData => {
    const newCandlesCount = candlesToAdd.length;
    const coeficient = direction ? -1 : 1;
    const newSpaceGap = spaceGap - CANDLE_OFFSET * scaleIndex * newCandlesCount * coeficient;

    let resultCandles = direction ? candles.concat(candlesToAdd) : candlesToAdd.concat(candles);

    const startCutIndex = direction ? newCandlesCount : 0;
    const endCutIndex = direction ? resultCandles.length : resultCandles.length - newCandlesCount;

    resultCandles = resultCandles.splice(startCutIndex, endCutIndex);

    console.log('COUNT: ', newCandlesCount, ' Start index: ', startCutIndex, ' End Index: ', endCutIndex);

    return { newCandles: resultCandles, newSpaceGap: newSpaceGap };
}

export const getParsedResponseCandles = (rows: any[], scaleIndex: number): UCandle[] => {
    const candles: UCandle[] = [];
    const mapper = scaleIndex >= CLUSTER_SCALE ? ResponseDataMapper.cluster : ResponseDataMapper.candle;

    rows.forEach(row => {
        const candle: UCandle = {
            high: row[mapper.high],
            low: row[mapper.low],
            open: row[mapper.open],
            close: row[mapper.close],
            timeClose: row[mapper.timeClose]
        }

        if (mapper.clusterData != null) {
            candle.clusterData = JSON.parse(row[mapper.clusterData])
        }

        candles.push(candle);
    });

    return candles;
}
