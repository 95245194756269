// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker-container {
  position: relative;
}
.color-picker-container:before {
  content: "#";
  display: block;
  position: absolute;
  z-index: 1;
  left: 43px;
  color: rgba(255, 255, 255, 0.5);
  top: 8px;
  font-size: 10px;
}
.color-picker-container .color-value {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  color: #FFF;
  width: 61px;
  height: 26px;
  padding: 7px 8px;
  font-size: 10px;
  padding-left: 13px;
}
.color-picker-container .p-colorpicker.p-component.p-colorpicker-overlay {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  height: 26px;
  width: 26px;
  padding: 5px;
  box-sizing: border-box;
  vertical-align: middle;
}
.color-picker-container .p-colorpicker.p-component.p-colorpicker-overlay input.p-colorpicker-preview.p-inputtext.input-cp {
  height: 16px;
  padding: 0;
  width: 16px;
  border-radius: 0;
  border: none;
  vertical-align: super;
  display: inline-block;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Inputs/ColorPicker/ColorPicker.scss"],"names":[],"mappings":"AAAA;EAEE,kBAAA;AAAF;AACE;EACE,YAAA;EACA,cAAA;EACA,kBAAA;EACA,UAAA;EACA,UAAA;EACA,+BAAA;EACA,QAAA;EACA,eAAA;AACJ;AAEE;EACE,kBAAA;EACA,0CAAA;EACA,oCAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAGE;EACE,oCAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,sBAAA;AADJ;AAGI;EACE,YAAA;EACA,UAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,qBAAA;EACA,qBAAA;EACA,iBAAA;AADN","sourcesContent":[".color-picker-container {\n\n  position: relative;\n  &:before {\n    content: \"#\";\n    display: block;\n    position: absolute;\n    z-index: 1;\n    left: 43px;\n    color: rgba(255, 255, 255, 0.5);\n    top: 8px;\n    font-size: 10px;\n  }\n\n  .color-value {\n    border-radius: 4px;\n    border: 1px solid rgba(255, 255, 255, 0.20);\n    background: rgba(255, 255, 255, 0.10);\n    color: #FFF;\n    width: 61px;\n    height: 26px;\n    padding: 7px 8px;\n    font-size: 10px;\n    padding-left: 13px;\n  }\n\n  .p-colorpicker.p-component.p-colorpicker-overlay {\n    background: rgba(255, 255, 255, 0.10);\n    border-radius: 4px;\n    margin-right: 10px;\n    height: 26px;\n    width: 26px;\n    padding: 5px;\n    box-sizing: border-box;\n    vertical-align: middle;\n\n    input.p-colorpicker-preview.p-inputtext.input-cp {\n      height: 16px;\n      padding: 0;\n      width: 16px;\n      border-radius: 0;\n      border: none;\n      vertical-align: super;\n      display: inline-block;\n      line-height: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
