// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mainChart.snakeWhite .connector,
#mainChart.snakeWhite .candle {
  fill: white;
  filter: none;
}
#mainChart.snakeCircle .connector.goingDown,
#mainChart.snakeCircle .candle.goingDown, #mainChart.snakeColorize .connector.goingDown,
#mainChart.snakeColorize .candle.goingDown {
  fill: #B01C1C;
  filter: none;
}
#mainChart.snakeCircle .connector.goingUp,
#mainChart.snakeCircle .candle.goingUp, #mainChart.snakeColorize .connector.goingUp,
#mainChart.snakeColorize .candle.goingUp {
  fill: #5C8800;
  filter: none;
}
#mainChart.snakeCircle .connector.open.goingUp, #mainChart.snakeColorize .connector.open.goingUp {
  fill: url(#GreenOpenConnectorGradient);
}
#mainChart.snakeCircle .connector.close.goingUp, #mainChart.snakeColorize .connector.close.goingUp {
  fill: url(#GreenCloseConnectorGradient);
}
#mainChart.snakeCircle .connector.open.goingDown, #mainChart.snakeColorize .connector.open.goingDown {
  fill: url(#RedOpenConnectorGradient);
}
#mainChart.snakeCircle .connector.close.goingDown, #mainChart.snakeColorize .connector.close.goingDown {
  fill: url(#RedCloseConnectorGradient);
}
#mainChart .connector {
  height: 6px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Demo/SnakeChart/SnakeChart.scss"],"names":[],"mappings":"AAKQ;;EAEI,WAAA;EACA,YAAA;AAJZ;AAYY;;;EACI,aAAA;EACA,YAAA;AARhB;AAWY;;;EACI,aAAA;EACA,YAAA;AAPhB;AAWQ;EACI,sCAAA;AATZ;AAYQ;EACI,uCAAA;AAVZ;AAaQ;EACI,oCAAA;AAXZ;AAcQ;EACI,qCAAA;AAZZ;AAgBI;EACI,WAAA;AAdR","sourcesContent":["@import \"../../../Common/Styles/colors.scss\";\n@import \"../../../Common/Styles/metrics\";\n\n#mainChart {\n    &.snakeWhite {\n        .connector, \n        .candle {\n            fill: white;\n            filter: none;\n        }\n    }\n    \n    &.snakeCircle,\n    &.snakeColorize {\n        .connector, \n        .candle {\n            &.goingDown {\n                fill: #B01C1C;\n                filter: none;\n            }\n    \n            &.goingUp {\n                fill: #5C8800;\n                filter: none;\n            }\n        }\n\n        .connector.open.goingUp {\n            fill: url(#GreenOpenConnectorGradient);\n        }\n\n        .connector.close.goingUp {\n            fill: url(#GreenCloseConnectorGradient);\n        }\n\n        .connector.open.goingDown {\n            fill: url(#RedOpenConnectorGradient);\n        }\n\n        .connector.close.goingDown {\n            fill: url(#RedCloseConnectorGradient);\n        }\n    }\n    \n    .connector {\n        height: 6px;\n    }\n\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
