import React from 'react'
import "./TechnicalAnalysisMenu.scss";
import CursorBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/CursorBtn.svg";
import HorizontalLineBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/HorizontalLineBtn.svg";
import VerticalLineBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/VerticalLineBtn.svg";
import TriangleLineBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleLineBtn.svg";
import HorizontalAreaBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/HorizontalAreaBtn.svg";
import VerticalAreaBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/VerticalAreaBtn.svg";
import SquareAreaBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/SquareAreaBtn.svg";
import CursivePencilBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/CursivePencilBtn.svg";
import TriangleFigureBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleFigureBtn.svg";
import RectangleFigureBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RectangleFigureBtn.svg";
import SquareFigureBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/SquareFigureBtn.svg";
import CircleFigureBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/CircleFigureBtn.svg";
import LineTrendBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/LineTrendBtn.svg";
import TriangleTrendBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleTrendBtn.svg";
import RectangleTrendBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RectangleTrendBtn.svg";
import LineFibonacciBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/LineFibonacciBtn.svg";
import TriangleFibonacciBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleFibonacciBtn.svg";
import RectangleFibonacciBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RectangleFibonacciBtn.svg";
import RouletteBtn from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RouletteBtn.svg";

import CursorBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/CursorBtnActive.svg";
import HorizontalLineBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/HorizontalLineBtnActive.svg";
import VerticalLineBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/VerticalLineBtnActive.svg";
import TriangleLineBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleLineBtnActive.svg";
import HorizontalAreaBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/HorizontalAreaBtnActive.svg";
import VerticalAreaBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/VerticalAreaBtnActive.svg";
import SquareAreaBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/SquareAreaBtnActive.svg";
import CursivePencilBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/CursivePencilBtnActive.svg";
import TriangleFigureBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleFigureBtnActive.svg";
import RectangleFigureBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RectangleFigureBtnActive.svg";
import SquareFigureBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/SquareFigureBtnActive.svg";
import CircleFigureBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/CircleFigureBtnActive.svg";
import LineTrendBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/LineTrendBtnActive.svg";
import TriangleTrendBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleTrendBtnActive.svg";
import RectangleTrendBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RectangleTrendBtnActive.svg";
import LineFibonacciBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/LineFibonacciBtnActive.svg";
import TriangleFibonacciBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/TriangleFibonacciBtnActive.svg";
import RectangleFibonacciBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RectangleFibonacciBtnActive.svg";
import RouletteBtnActive from "../../Common/Components/Icons/ButtonIcons/TechAnalysisMenu/RouletteBtnActive.svg";
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { selectTechAnalysMenuItem } from '../../Store/action-creators';
import { useTypedSelector } from '../../Hooks/useTypedSelector';
import { RootState } from '../../Store/models';

interface IProps {

}

enum TachAnalisysMenuItem {
    Cursor = 'Cursor',
    HorizontalLine = 'HorizontalLine',
    VerticalLine = 'VerticalLine',
    TriangleLine = 'TriangleLine',
    HorizontalArea = 'HorizontalArea',
    SquareArea = 'SquareArea',
    VerticalArea = 'VerticalArea',
    CursivePencil = 'CursivePencil',
    TriangleFigure = 'TriangleFigure',
    RectangleFigure = 'RectangleFigure',
    SquareFigure = 'SquareFigure',
    CircleFigure = 'CircleFigure',
    LineTrend = 'LineTrend',
    TriangleTrend = 'TriangleTrend',
    RectangleTrend = 'RectangleTrend',
    LineFibonacci = 'LineFibonacci',
    TriangleFibonacci = 'TriangleFibonacci',
    RectangleFibonacci = 'RectangleFibonacci',
    Roulette = 'Roulette'
}

export const TechnicalAnalysisMenu: React.FC<IProps> = ({ }: IProps) => {
    const dispatch = useDispatch();
    const selectedMenuItem = useTypedSelector((state: RootState) => state.app.selectedMenuItem);

    const CursorIcon = selectedMenuItem === TachAnalisysMenuItem.Cursor ? CursorBtnActive : CursorBtn;
    const HorizontalLineIcon = selectedMenuItem === TachAnalisysMenuItem.HorizontalLine ? HorizontalLineBtnActive : HorizontalLineBtn;
    const VerticalLineIcon = selectedMenuItem === TachAnalisysMenuItem.VerticalLine ? VerticalLineBtnActive : VerticalLineBtn;
    const TriangleLineIcon = selectedMenuItem === TachAnalisysMenuItem.TriangleLine ? TriangleLineBtnActive : TriangleLineBtn;
    const HorizontalAreaIcon = selectedMenuItem === TachAnalisysMenuItem.HorizontalArea ? HorizontalAreaBtnActive : HorizontalAreaBtn;
    const SquareAreaIcon = selectedMenuItem === TachAnalisysMenuItem.SquareArea ? SquareAreaBtnActive : SquareAreaBtn;
    const VerticalAreaIcon = selectedMenuItem === TachAnalisysMenuItem.VerticalArea ? VerticalAreaBtnActive : VerticalAreaBtn;
    const CursivePencilIcon = selectedMenuItem === TachAnalisysMenuItem.CursivePencil ? CursivePencilBtnActive : CursivePencilBtn;
    const TriangleFigureIcon = selectedMenuItem === TachAnalisysMenuItem.TriangleFigure ? TriangleFigureBtnActive : TriangleFigureBtn;
    const RectangleFigureIcon = selectedMenuItem === TachAnalisysMenuItem.RectangleFigure ? RectangleFigureBtnActive : RectangleFigureBtn;
    const SquareFigureIcon = selectedMenuItem === TachAnalisysMenuItem.SquareFigure ? SquareFigureBtnActive : SquareFigureBtn;
    const CircleFigureIcon = selectedMenuItem === TachAnalisysMenuItem.CircleFigure ? CircleFigureBtnActive : CircleFigureBtn;
    const LineTrendIcon = selectedMenuItem === TachAnalisysMenuItem.LineTrend ? LineTrendBtnActive : LineTrendBtn;
    const TriangleTrendIcon = selectedMenuItem === TachAnalisysMenuItem.TriangleTrend ? TriangleTrendBtnActive : TriangleTrendBtn;
    const RectangleTrendIcon = selectedMenuItem === TachAnalisysMenuItem.RectangleTrend ? RectangleTrendBtnActive : RectangleTrendBtn;
    const LineFibonacciIcon = selectedMenuItem === TachAnalisysMenuItem.LineFibonacci ? LineFibonacciBtnActive : LineFibonacciBtn;
    const TriangleFibonacciIcon = selectedMenuItem === TachAnalisysMenuItem.TriangleFibonacci ? TriangleFibonacciBtnActive : TriangleFibonacciBtn;
    const RectangleFibonacciIcon = selectedMenuItem === TachAnalisysMenuItem.RectangleFibonacci ? RectangleFibonacciBtnActive : RectangleFibonacciBtn;
    const RouletteIcon = selectedMenuItem === TachAnalisysMenuItem.Roulette ? RouletteBtnActive : RouletteBtn;


    const menuItemClickHandler = (menuItem: string) => {
        dispatch(selectTechAnalysMenuItem(menuItem));
    }

    return (
        <div className="tech-analysis-menu-container">
            <div className='group'>
                <img className='type'
                    src={CursorIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.Cursor) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Lines')}</span>
                <img className='type'
                    src={HorizontalLineIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.HorizontalLine) }} />
                <img className='type'
                    src={VerticalLineIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.VerticalLine) }} />
                <img className='type'
                    src={TriangleLineIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.TriangleLine) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Areas')}</span>
                <img className='type'
                    src={HorizontalAreaIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.HorizontalArea) }} />
                <img className='type'
                    src={VerticalAreaIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.VerticalArea) }} />
                <img className='type'
                    src={SquareAreaIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.SquareArea) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Pencil')}</span>
                <img className='type'
                    src={CursivePencilIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.CursivePencil) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Figure')}</span>
                <img className='type'
                    src={TriangleFigureIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.TriangleFigure) }} />
                <img className='type'
                    src={RectangleFigureIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.RectangleFigure) }} />
                <img className='type'
                    src={SquareFigureIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.SquareFigure) }} />
                <img className='type'
                    src={CircleFigureIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.CircleFigure) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Trend')}</span>
                <img className='type'
                    src={LineTrendIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.LineTrend) }} />
                <img className='type'
                    src={TriangleTrendIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.TriangleTrend) }} />
                <img className='type'
                    src={RectangleTrendIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.RectangleTrend) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Fibonacci')}</span>
                <img className='type'
                    src={LineFibonacciIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.LineFibonacci) }} />
                <img className='type'
                    src={TriangleFibonacciIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.TriangleFibonacci) }} />
                <img className='type'
                    src={RectangleFibonacciIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.RectangleFibonacci) }} />
            </div>
            <div className='group'>
                <span className='description'>{t('Components:Components.TechnicalAnalysis.Roulette')}</span>
                <img className='type'
                    src={RouletteIcon}
                    onClick={() => { menuItemClickHandler(TachAnalisysMenuItem.Roulette) }} />
            </div>
        </div>
    )
}

TechnicalAnalysisMenu.displayName = 'ChartMenu'
